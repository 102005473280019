import { isEmpty } from "lodash";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
const Header = () => {
  const location = useLocation();
  let path = window.location.href.split("/");
  const parmas = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isEmpty(parmas.token)) {
      localStorage.setItem("token", parmas.token);
      navigate(`/${path[3]}/${path[4]}`);
      window.location.reload();
    }
  }, [parmas.token]);
  return (
    <>
      <div className="my_account_sidebar">
        <div className="sidebar">
          <div className="sidebar-main">
            <div className="sub_path">
              <div className="path">
                <a href="#path-pop" className="path-back">
                  ...
                </a>
                <ul>
                  <li className="path-last">
                    <Link to="#">My Account</Link>
                  </li>
                </ul>
              </div>
            </div>

            <ul className="menu-list list-unstyled">
              <li>
                <Link
                  id="sub_menu_detail active"
                  to={
                    location?.pathname?.split("/")[2] == "mobile"
                      ? `/my-profile/mobile`
                      : "/my-profile"
                  }
                >
                  My Profile
                </Link>
              </li>
              <li>
                <Link
                  id="sub_menu_summary"
                  to={
                    location?.pathname?.split("/")[2] == "mobile"
                      ? "/balance/mobile"
                      : "/balance"
                  }
                >
                  Balance Overview
                </Link>
              </li>
              {location?.pathname?.split("/")[2] == "mobile" && (
                <li>
                  <Link
                    id="sub_menu_summary"
                    to={
                      location?.pathname?.split("/")[2] == "mobile"
                        ? "/transactionHistory/mobile"
                        : "/transactionHistory"
                    }
                  >
                    Transaction History
                  </Link>
                </li>
              )}
              <li>
                <Link
                  id="sub_menu_accountCashStatement_log"
                  to={
                    location?.pathname?.split("/")[2] == "mobile"
                      ? `/account-statement/mobile`
                      : "/account-statement"
                  }
                  className="select"
                >
                  Account Statement
                </Link>
              </li>
              <li>
                <Link
                  id="sub_menu_my_bets"
                  to={
                    location?.pathname?.split("/")[2] == "mobile"
                      ? "/mybets/mobile"
                      : "/mybets"
                  }
                >
                  My Bets
                </Link>
              </li>
              <li>
                <Link
                  id="sub_menu_activity_log"
                  to={
                    location?.pathname?.split("/")[2] == "mobile"
                      ? "/activity-logs/mobile"
                      : "/activity-logs"
                  }
                >
                  Activity Log
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

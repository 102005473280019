import React, { useState, useContext, useRef, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { apiGet ,apiPost} from "../utils/apiFetch";
import apiPath from "../utils/apiPath";

function Home() {
  let { user, handleShow, launchCasino, homeLoader,doLoginAndLaunchJetxCasino } = useContext(AuthContext);
  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
  };
  const [data, setData] = useState([]);

  const getData = async () => {
    const { status, data } = await apiPost(apiPath.getBanner, {
      type: "home_top",
    });
    if (status == 200) {
      if (data?.success) {
        setData(data?.results?.banners);
      }
    }
  };
 
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div className="main">
        <Container>
          <Row className="gx-2">
            <Col md={12} className="my-2">
            <Slider {...settings}>
                    {data?.length > 0 &&
                      data?.map((item) => {
                        return (
                      <div>
                        <LazyLoadImage
                        src={process.env.REACT_APP_API_BASE_URL + item?.banner_path}
                          
                          style={{ width: "100%" }}
                          alt=""
                        />
                      </div>
                    );
                    })}
            </Slider>
            </Col>
            <Col md={6} className="mb-2">
              {" "}
              <a href="#" className="position-relative d-block games_main">
                <Link to="/inplay">
                  <img src="assets/images/home/banner_sports.webp" />
                </Link>

                <div className="title">
                  <h2>Sports </h2>
                  <span>Play Now</span>
                </div>

                <dl id="onLiveBoard" className="on_live">
                  <dt>
                    <p className="live_icon">
                      <span></span> LIVE
                    </p>
                  </dt>

                  <dd id="onLiveCount_CRICKET">
                    <p>Cricket</p>
                    <span id="count">3</span>
                  </dd>

                  <dd id="onLiveCount_SOCCER">
                    <p>Soccer</p>
                    <span id="count">8</span>
                  </dd>

                  <dd id="onLiveCount_TENNIS">
                    <p>Tennis</p>
                    <span id="count">24</span>
                  </dd>

                  {/* <dd id="onLiveCount_E_SOCCER">
                    <p>E-Soccer</p>
                    <span id="count">2</span>
                  </dd>

                  <dd id="onLiveCount_KABADDI">
                    <p>Kabaddi</p>
                    <span id="count">0</span>
                  </dd> */}
                </dl>
              </a>
            </Col>
            
            
            <Col md={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "SEXYBCRT",
                      gameType: "",
                      casinoType: "",
                    });
                  } else {
                    handleShow();
                  }
                }}
              >
                <img src="assets/images/home/aesexy.webp" />
                <div className="title">
                  <h2>AE Sexy</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col md={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "EVOLUTION",
                      gameType: "LIVE",
                      casinoType: "",
                    });
                  } else {
                    handleShow();
                  }
                }}
              >
                <img src="assets/images/home/evo.webp" />
                <div className="title">
                  <h2>Evolution</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col md={12} lg={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-060",
                    });
                  } else {
                    handleShow();
                  }
                }}
              >
                <img src="assets/images/home/LUDO.webp" />
                <div className="title">
                  <h2>Ludo Bet </h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col md={12} lg={3} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "1",
                      gameType: "",
                      casinoType: "",
                    });
                  } else {
                    handleShow();
                  }
                }}
              >
                <img src="assets/images/home/banner_casino-half.webp" />
                <div className="title">
                  <h2>Live Casino</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "VENUS",
                      gameType: "LIVE",
                      casinoType: "VN-LIVE-001",
                    });
                  } else {
                    handleShow();
                  }
                }}
              >
                <img src="assets/images/home/venus-casino.webp" />
                <div className="title">
                  <h2>Venus </h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={6} xs={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "E1SPORT",
                      gameType: "ESPORTS",
                      casinoType: "E1-ESPORTS-001",
                    });
                  } else {
                    handleShow();
                  }
                }}
              >
                <img src="assets/images/home/esport.webp" />
                <div className="title">
                  <h2>E1 Sports</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={6} xs={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "",
                      casinoType: "",
                    });
                  } else {
                    handleShow();
                  }
                }}
              >
                <img src="assets/images/home/kingmaker.webp" />
                <div className="title">
                  <h2>Kingmaker</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={6} xs={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "JILI",
                      gameType: "",
                      casinoType: "",
                    });
                  } else {
                    handleShow();
                  }
                }}
              >
                <img src="assets/images/home/jili.webp" />
                <div className="title">
                  <h2>Jili</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() => {
                  if (!isEmpty(user)) {
                    doLoginAndLaunchJetxCasino({
                      GameCategory: "JetX",
                      game_name: "JetX",
                      gameId: "13",
                    });
                  } else {
                    handleShow();
                  }
                }}
              >
                <img src="assets/images/home/banner_Aviator-jetx.webp" />
                <div className="title">
                  <h2>Jetx</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
          

            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() => {
                  if (!isEmpty(user)) {
                    doLoginAndLaunchJetxCasino({
                      GameCategory: "XGames",
                      game_name: "JetX3",
                      gameId: "14",
                    });
                  } else {
                    handleShow();
                  }
                }}
              >
                <img src="assets/images/home/banner_jetx.webp" />
                <div className="title">
                  <h2>Jetx3</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
           
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() => {
                  if (!isEmpty(user)) {
                    doLoginAndLaunchJetxCasino({
                      GameCategory: "XGames",
                      game_name: "CricketX",
                      gameId: "16",
                    });
                  } else {
                    handleShow();
                  }
                }}
              >
                <img src="assets/images/home/banner_cricketx.webp" />
                <div className="title">
                  <h2>Cricketx</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() => {
                  if (!isEmpty(user)) {
                    doLoginAndLaunchJetxCasino({
                      GameCategory: "Slots",
                      game_name: "Samurai",
                      gameId: "24",
                    });
                  } else {
                    handleShow();
                  }
                }}
              >
                <img src="assets/images/home/smart-samurai.webp" />
                <div className="title">
                  <h2>Samurai</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-015",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/sicbokm.webp" />
                <div className="title">
                  <h2>Kingmaker</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                href="#"
                className="position-relative d-block games_main"
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-028",
                    });
                  } else {
                    handleShow();
                  }
                }}
              >
                <img src="assets/images/home/7up7down.webp" />
                <div className="title">
                  <h2>7up7down</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-036",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/CoinToss.webp" />
                <div className="title">
                  <h2>Coin Toss</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-021",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/numberMatka.webp" />
                <div className="title">
                  <h2>Number Matka</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
               onClick={() => {
                if (!isEmpty(user)) {

                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-022",
                  });
                } else {
                  handleShow();
                }
              }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/cardMatka.webp" />
                <div className="title">
                  <h2>Crad Matka</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
               onClick={() => {
                if (!isEmpty(user)) {

                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-032",
                  });
                } else {
                  handleShow();
                }
              }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/andarbahar.webp" />
                <div className="title">
                  <h2>Andar Bahar</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-038",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/Blackjack.webp" />
                <div className="title">
                  <h2>Blackjack</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-038",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/ezugi.webp" />
                <div className="title">
                  <h2>Ezugi</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                 onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "PP",
                      gameType: "",
                      casinoType: "",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/pp.webp" />
                <div className="title">
                  <h2>Pragmatic Play</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
               onClick={() => {
                if (!isEmpty(user)) {

                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-037",
                  });
                } else {
                  handleShow();
                }
              }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/cardhillo.webp" />
                <div className="title">
                  <h2>Card Hilo</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                 onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-042",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/mineswiper.webp" />
                <div className="title">
                  <h2>Minesweeper</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "JILI",
                      gameType: "TABLE",
                      casinoType: "JILI-TABLE-016",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/teenpatti20.jpeg" />
                <div className="title">
                  <h2>TeenPatti</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "JILI",
                      gameType: "TABLE",
                      casinoType: "JILI-TABLE-005",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/numberking.webp" />
                <div className="title">
                  <h2>NumberKing</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "JILI",
                      gameType: "TABLE",
                      casinoType: "JILI-TABLE-007",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src="assets/images/home/big-small.webp" />
                <div className="title">
                  <h2>Big Small</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>

            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "JILI",
                      gameType: "TABLE",
                      casinoType: "JILI-TABLE-010",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/teenpatti.webp"} />
                <div className="title">
                  <h2>Teen Patti Joker</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                 onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "JILI",
                      gameType: "TABLE",
                      casinoType: "JILI-TABLE-011",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/7updown.webp"} />
                <div className="title">
                  <h2>7up7down</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "JILI",
                      gameType: "TABLE",
                      casinoType: "JILI-TABLE-012",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/dragon-tiger.webp"} />
                <div className="title">
                  <h2>Dragon Tiger</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
               onClick={() => {
                if (!isEmpty(user)) {

                  launchCasino({
                    platForm: "JILI",
                    gameType: "TABLE",
                    casinoType: "JILI-TABLE-013",
                  });
                } else {
                  handleShow();
                }
              }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/callbreak.webp"} />
                <div className="title">
                  <h2>Call Break Quick</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "JILI",
                      gameType: "TABLE",
                      casinoType: "JILI-TABLE-017",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/sicbo.png"} />
                <div className="title">
                  <h2>Sic bo</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
               onClick={() => {
                if (!isEmpty(user)) {

                  launchCasino({
                    platForm: "JILI",
                    gameType: "TABLE",
                    casinoType: "JILI-TABLE-014",
                  });
                } else {
                  handleShow();
                }
              }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/baccaret.webp"} />
                <div className="title">
                  <h2>Bacarrat</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-039",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/32card-half.webp"} />
                <div className="title">
                  <h2>32 Cards</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={3} xs={3} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "KINGMAKER",
                      gameType: "TABLE",
                      casinoType: "KM-TABLE-027",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/europianroullete.webp"} />
                <div className="title">
                  <h2>European Roulette</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={6} xs={6} className="mb-2">
              {" "}
              <Link
               onClick={() => {
                if (!isEmpty(user)) {

                  launchCasino({
                    platForm: "KINGMAKER",
                    gameType: "TABLE",
                    casinoType: "KM-TABLE-048",
                  });
                } else {
                  handleShow();
                }
              }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/horse-race.webp"} />
                <div className="title">
                  <h2>Horse Racing</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            <Col lg={6} xs={6} className="mb-2">
              {" "}
              <Link
                onClick={() => {
                  if (!isEmpty(user)) {
  
                    launchCasino({
                      platForm: "SV388",
                      gameType: "LIVE",
                      casinoType: "SV-LIVE-001",
                    });
                  } else {
                    handleShow();
                  }
                }}
                href="#"
                className="position-relative d-block games_main"
              >
                <img src={"assets/images/home/cockfight.webp"} />
                <div className="title">
                  <h2>CockFight</h2>
                  <span>Play Now</span>
                </div>
              </Link>
            </Col>
            
          </Row>
        </Container>
       
      </div>
    </div>
  );
}

export default Home;

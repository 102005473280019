import dayjs from "dayjs";
import moment from "moment";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
var calendar = require("dayjs/plugin/calendar");
dayjs.extend(calendar);
dayjs.extend(utc);
dayjs.extend(tz);

const obj = {
  currencyFormat: (number = "") => {
    return new Intl.NumberFormat("en-IN", {}).format(number);
  },
  dateFormat: (date, timeZone) => {
    var convertedDate = new Date(date).toLocaleString(undefined, {
      timeZone: timeZone ? timeZone : "Asia/Kolkata",
    });

    return convertedDate.toString();
  },
  msgDateFormat: (date, timeZone) => {
    var convertedDate = new Date(date).toLocaleDateString(undefined, {
      timeZone: timeZone ? timeZone : "Asia/Kolkata",
    });

    return convertedDate.toString();
  },
  matchDateTime: (date, timeZone = "Asia/Kolkata") => {
    return dayjs(date).tz(timeZone).calendar(null, {
      sameDay: "h:mm A", // The same day ( Today at 2:30 AM )
      nextDay: "[Tomorrow]", // The next day ( Tomorrow at 2:30 AM )
      nextWeek: "dddd [at] h:mm A", // The next week ( Sunday at 2:30 AM )
      lastDay: "[Yesterday at] h:mm A", // The day before ( Yesterday at 2:30 AM )
      lastWeek: "[Last] dddd [at] h:mm A", // Last week ( Last Monday at 2:30 AM )
      sameElse: "DD/MM/YYYY", // Everything else ( 17/10/2011 )
    });
  },
  marketStatus: (s) => {
    var status = "";
    switch (s) {
      case 1:
        status = "Open";
        break;
      case 2:
        status = "In Active";
        break;
      case 3:
        status = "Suspend";
        break;
      case 4:
        status = "Closed";
        break;
      case 9:
        status = "Ball Start";
        break;
      default:
        status = "";
    }
    return status;
  },
  getSportType: (t) => {
    var type = "";
    switch (t) {
      case 1:
        type = "soccer";
        break;
      case 2:
        type = "tennis";
        break;
      case 4:
        type = "cricket";
        break;

      default:
        type = "";
    }
    return type;
  },
  isInputNumber: (event) => {
    var char = String.fromCharCode(event.which);
    if (!/[0-9]/.test(char)) {
      event.preventDefault();
    }
  },
  truncateDecimals :(num, digits)  =>{
    var numS = num.toString(),
        decPos = numS.indexOf('.'),
        substrLength = decPos == -1 ? numS.length : 1 + decPos + digits,
        trimmedResult = numS.substr(0, substrLength),
        finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

    return parseFloat(finalResult);
    
}
};

export default obj;

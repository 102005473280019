import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import AuthContext from "../context/AuthContext";
import bgImage from "../assets/images/home/bg-login.webp";
import loginSVG from "../assets/images/home/login.svg";

const Login = (props) => {
  let { loginUser, user } = useContext(AuthContext);

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset: reset2,
  } = useForm({});
  const [getValidateCode, setValidateCode] = useState("");

  const onSubmit2 = (data) => {
    if (parseInt(data.validateCode) === parseInt(getValidateCode)) {
      loginUser(data);
      reset2();
      props.handleClose();
    }
  };

  const canvasRef = useRef(null);
  const changeCode = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.font = "bold 120px sans-serif";
    const code = Math.floor(1000 + Math.random() * 9000);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillText(code, 0, 130);
    setValidateCode(code);
  };

  useEffect(() => {
    if (props.show) {
      setTimeout(() => {
        changeCode();
      }, 1000);
    }
  }, [props.show]);
  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        centered
        className="login_modal_wrap"
      >
        <div className="login_modal">
          <div className="row">
            <div className="col-sm-6 d-sm-block d-none">
              <img src={bgImage} alt="No_Image" />
            </div>
            <div className="col-sm-6">
              <div className="login_main">
                <div>
                  <a
                    href="#"
                    className="login_close"
                    onClick={props.handleClose}
                  >
                    <i className="fas fa-times"></i>
                  </a>
                  <h5 className="mb-3">Please login to continue</h5>
                  <Form onSubmit={handleSubmit2(onSubmit2)}>
                    <Form.Group className="mb-2" controlId="formBasicEmail2">
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        className={errors2.username ? "is-invalid" : ""}
                        {...register2("username", {
                          required: "Please enter Username",
                        })}
                      />
                      {errors2.username && errors2.username.message && (
                        <label className="invalid-feedback text-left">
                          {errors2.username.message}
                        </label>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-2" controlId="formBasicPassword">
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        className={errors2.password ? "is-invalid" : ""}
                        {...register2("password", {
                          required: "Please enter password",
                        })}
                      />
                      {errors2.password && errors2.password.message && (
                        <label className="invalid-feedback text-left">
                          {errors2.password.message}
                        </label>
                      )}
                    </Form.Group>
                    <Form.Group
                      className="mb-2 login_verify"
                      controlId="formBasicValidation"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Validate Code"
                        maxLength="4"
                        className={errors2.validateCode ? "is-invalid" : ""}
                        {...register2("validateCode", {
                          required: "Please enter validate code",
                          validate: {
                            validate: (value) =>
                              parseInt(value) === parseInt(getValidateCode) ||
                              "Invalid validate code",
                          },
                        })}
                      />
                      <canvas
                        ref={canvasRef}
                        onClick={changeCode}
                        className="inputcanvas"
                      />

                      {errors2.validateCode && errors2.validateCode.message && (
                        <label className="invalid-feedback text-left">
                          {errors2.validateCode.message}
                        </label>
                      )}
                    </Form.Group>
                    <div>
                      <Button type="submit" className="login_btn">
                        Login{" "}
                        <img
                          className="icon-login ms-1"
                          src={loginSVG}
                          alt=""
                        />
                      </Button>

                      {/* <a
                      href="#"
                      id="loginBtn"
                      className="login_btn"
                      onClick={handleShow}
                    >
                      Login
                      <img
                        className="icon-login ms-1"
                        src="assets/images/home/login.svg"
                      />
                    </a> */}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Login;

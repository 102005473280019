import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  startTransition,
} from "react";
import { Table, Accordion, Tabs, Tab } from "react-bootstrap";
import MatchSidebar from "../components/MatchSidebar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { apiGet, apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import transparentGif from "../../src/assets/images/transparent.gif";
import loading40 from "../../src/assets/images/loading40.gif";
import pin1 from "../../src/assets/images/pin1.svg";
import refresh from "../../src/assets/images/refresh.svg";
import moment from "moment/moment";
import io from "socket.io-client";
import { toast } from "wc-toast";
import AuthContext from "../context/AuthContext";
import Login from "../components/Login";
import axios from "axios";
import ReactPlayer from "react-player";
import secureLocalStorage from "react-secure-storage";
import obj, { categoryArray, priceData } from "../utils/constants";
import helpers from "../utils/helpers";
import Loader from "../components/Loader";
import LoaderGif from "../assets/gif/loader.gif";
import FancyTabs from "./MatchOdds/FancyTabs";
import { popoverPremium, popoverFancy } from "./MatchOdds/ComponentsMatchOdds";
import BetSlipContext from "../context/BetSlipContext";
import PreminumFancyTable from "./MatchOdds/PreminumFancyTable";
import OpenBets from "./MatchOdds/OpenBets";
import { inRange, isEmpty, isNumber } from "lodash";
import BetFair from "./MatchOdds/BetFair";
const isInputNumber = helpers.isInputNumber;
const MatchOdd = () => {
  const showToggle = () => {
    document.getElementById("body-parent").classList.toggle("showbet");
  };
  let { activeOneClick, activeOneClickAmount, handelAddMultiMarket } =
    useContext(BetSlipContext);
  const [mute, setMute] = useState(true);
  const params = useParams();
  const location = useLocation();
  var eventId = params.eventId;
  let sportType = params.sportType;
  const [preminumFancy, setPremiumFancy] = useState([]);
  let {
    user,
    loginUserDetail,
    loginUserDetailData,
    stakeData,
    getCoins,
    setSocketId,
  } = useContext(AuthContext);
  useEffect(() => {
    if (!isEmpty(user)) {
      loginUserDetail();
    }
  }, [user]);
  const [isTv, setIsTv] = useState(true);
  const navigate = useNavigate();
  //********** ALL STATE ******************************//
  const [isMultimarket, setIsMultiMarket] = useState(false);
  const [minAmountCon, setMinAmountCond] = useState(false);
  const [selections, setSelections] = useState([]);
  const [back_odds, setBackOdds] = useState([]);
  const [lay_odds, setLayOdds] = useState([]);
  const [backBetSlipArray, setBetBackSlipArray] = useState([]);
  const [layBetSlipArray, setLayBackSlipArray] = useState([]);
  const [priceList, setPriceList] = useState([
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
    { id: 4, value: 200 },
    { id: 5, value: 1000 },
    { id: 6, value: 5000 },
  ]);
  useEffect(() => {
    if (stakeData.length) {
      setPriceList(stakeData);
    }
  }, [user, stakeData.length]);
  const [backBetSlipValue, setBackBetSlipValue] = useState("");
  const [layBetSlipValue, setLayBetSlipValue] = useState("");
  const [streamingUrl, setStreamingUrl] = useState("");
  const [bookmakerBackOdds, setBookmakerBackOdds] = useState([]);
  const [bookmakerLayOdds, setBookmakerLayOdds] = useState([]);
  const [bookmakerSelections, setBookmakerSelections] = useState([]);
  const [details, setDetails] = useState({});
  const [allBetSlipData, setAllBetslipData] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [bookmakerBetSlipObj, setBookMakerBetSlipObj] = useState({});
  const [acceptAnyOddsCheckbox, setAcceptAnyOddsCheckbox] = useState(0);
  const [disableBetFairPlaceBetBtn, setDisableBetFairPlaceBetBtn] =
    useState(true);
  const [loaderPlaceBetfair, setLoaderPlaceBetFair] = useState(false);
  const [betLoader, setBetLoader] = useState(false);
  const [loaderPlaceBookmakerBet, setLoaderBookmakerBet] = useState(false);
  const [tempSelection, setTempSelection] = useState([]);
  const [betFairLiabalitySelection, setLiabilitySelection] = useState([]);
  const [fancyList, setFancyList] = useState([]);
  const [showFancy, setShowFancy] = useState("fancy");
  const [fancyBetSlipObj, setFancyBetSlipObj] = useState({});
  const [acceptAnyOddsFancyCheckbox, setAcceptAnyOddsFancyCheckbox] =
    useState(0);
  const [loaderFancyBet, setLoaderFancyBet] = useState(false);
  const [showBetFairLoader, setShowBetFairLoader] = useState(true);
  const [tournamentData, setTournamentData] = useState("");
  const [matches, setMatches] = useState([]);
  const [matchesWithId, setMatchesWithId] = useState("");
  const [getSeriesId, setSeriesId] = useState("");
  const [getSeriesName, setSeriesName] = useState("");
  const [getMatchName, setMatchName] = useState("");
  const [getMatchId, setMatchId] = useState(
    params.eventId ? params.eventId : ""
  );
  const [getMarketId, setMarketId] = useState(
    params.marketId ? params.marketId : ""
  );
  const [getAllSports, setAllSports] = useState([]);
  const [key, setKey] = useState("All");
  const [totalMatched, setTotalMatched] = useState(0);
  const [fancyKey, setFancyKey] = useState("All");
  const [fancyCategory, setFancyCategory] = useState([]);
  const [fancyCategoryTabArray, setFancyCategoryTabArray] = useState([]);
  const [
    acceptAnyOddsPreminumFancyCheckbox,
    setAcceptAnyOddsPreminumFancyCheckbox,
  ] = useState(0);
  const [currentBets, setCurrentBets] = useState({});
  const [myBetsSelect, setMyBetSelect] = useState("odds");
  const [accordianToogle, setAccordianToogle] = useState("bets");
  const [premiumFancyPosition, setPremiumFancyPosition] = useState([]);
  const onChangeKey = (id) => {
    setKey(id);
    if (id == "All") {
      setFancyCategoryTabArray(
        fancyList.filter((res) => {
          return res.odds?.rt?.length > 0;
        })
      );
    } else if (id == "More") {
      let arr = fancyList.filter(
        (res) =>
          res.categoryType !== 1 &&
          res.categoryType !== 2 &&
          res.categoryType !== 3 &&
          res.categoryType !== 4 &&
          res.categoryType !== 5 &&
          res.categoryType !== 6 &&
          res.categoryType !== 7 &&
          res.categoryType !== 8 &&
          res.categoryType !== 9 &&
          res.categoryType !== 10 &&
          res.categoryType !== 11 &&
          res.categoryType !== 14 &&
          res.odds?.rt?.length > 0
      );
      setFancyCategoryTabArray(arr);
    } else {
      let temp = fancyList.filter((res) => {
        return res.categoryType == id && res.odds?.rt?.length > 0;
      });
      setFancyCategoryTabArray(temp);
    }
  };
  useEffect(() => {
    if (activeOneClick) {
      setAccordian("");
      setPreminumFancyAmount("");
      setSelectPreminumFancy({});
    }
  }, [activeOneClick]);
  const [amountRangeBetFair, setAmountRangeBetFair] = useState({});
  const [accordian, setAccordian] = useState("");
  const [selectPreminumFancy, setSelectPreminumFancy] = useState({});
  const [preminumfancyAmount, setPreminumFancyAmount] = useState("");
  const [betFairMs, setBetFairMs] = useState(1);
  const [socketObj, setSocketObj] = useState(null);
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [fancyPosition, setFancyPosition] = useState([]);
  const [marketNew, setMarketNew] = useState([]);
  const [marketIdNew, setMarketIdNew] = useState([]);
  const [amountRangeFancy, setAmountRangeFancy] = useState({});
  const [amountRangeSportBook, setAmountRangeSportBook] = useState({});
  const [amountRangeBookmaker, setAmountRangeBookmaker] = useState({});
  const [timeLeft, setTimeLeft] = useState(5);
  const getSocket = () => {
    if (details?.eventId && checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${
            details?.socketUrl
              ? details?.socketUrl
              : process.env.REACT_APP_API_BASE_URL1
          }?token=${randomId}&userType=front&eventId=${details?.eventId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${
            details?.socketUrl
              ? details?.socketUrl
              : process.env.REACT_APP_API_BASE_URL1
          }?user_id=${randomId}&eventId=${details?.eventId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObj(newSocket);
      setSocketId(newSocket);
      if (!isEmpty(user)) {
        newSocket.on("listenDiamondFancy", listenDiamondFancy);
        newSocket.on("listenBetFairOdds", listenBetfairOdds);
        newSocket.on("listenBookmakerOdds", listenBookmakerData);
        newSocket.on("listenPremiumFancy", listenSportBook);
        newSocket.emit("setPremiumFancy", {
          user_id: user?.user?._id ? user?.user?._id : randomId,
          eventId: params.eventId,
        });
      } else {
        newSocket.on("listenDiamondFancy", listenDiamondFancy);
        newSocket.on("listenBetFairOdds", listenBetfairOdds);
        newSocket.on("listenPremiumFancy", listenSportBook);
        newSocket.on("listenBookmakerOdds", listenBookmakerData);
      }
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.disconnect();
    }
  };

  useEffect(() => {
    if (!isEmpty(details)) {
      let profileData =
        details?.matchSetting?.length > 0
          ? { ...details, arrayCheck: "details" }
          : { ...loginUserDetailData, arrayCheck: "profile" };

      let objBetFair = profileData?.matchSetting?.find(
        (res) =>
          res?.type == "betFaire" &&
          (loginUserDetailData?.arrayCheck == "details"
            ? true
            : res?.sportType == details?.gameType)
      );
      if (!isEmpty(objBetFair)) {
        if (inRange(totalMatched, 5000, 15000)) {
          setAmountRangeBetFair({
            ...objBetFair,
            min: process.env.REACT_APP_AMOUNT_RANGE_MIN
              ? Number(process.env.REACT_APP_AMOUNT_RANGE_MIN)
              : Number(objBetFair?.minAmount),
            max: 370,
            oddsLimit: objBetFair?.oddsLimit ? objBetFair?.oddsLimit : 0,
          });
        } else if (inRange(totalMatched, 0, 5000)) {
          setAmountRangeBetFair({
            ...objBetFair,
            min: process.env.REACT_APP_AMOUNT_RANGE_MIN
              ? Number(process.env.REACT_APP_AMOUNT_RANGE_MIN)
              : Number(objBetFair?.minAmount),
            max: 100,
            oddsLimit: objBetFair?.oddsLimit ? objBetFair?.oddsLimit : 0,
          });
        } else {
          setAmountRangeBetFair({
            ...objBetFair,
            min: process.env.REACT_APP_AMOUNT_RANGE_MIN
              ? Number(process.env.REACT_APP_AMOUNT_RANGE_MIN)
              : Number(objBetFair?.minAmount),
            max: process.env.REACT_APP_AMOUNT_RANGE_MAX
              ? Number(process.env.REACT_APP_AMOUNT_RANGE_MAX)
              : Number(objBetFair?.maxAmount),
            oddsLimit: objBetFair?.oddsLimit ? objBetFair?.oddsLimit : 0,
          });
        }
      }

      let objFancy = profileData?.matchSetting?.find(
        (res) =>
          res?.type == "fancy" &&
          (profileData?.arrayCheck == "details"
            ? true
            : res?.sportType == details?.gameType)
      );
      if (!isEmpty(objFancy)) {
        setAmountRangeFancy({
          ...objFancy,
          min: process.env.REACT_APP_AMOUNT_RANGE_MIN
            ? Number(process.env.REACT_APP_AMOUNT_RANGE_MIN)
            : Number(objFancy?.minAmount),
          max: process.env.REACT_APP_AMOUNT_RANGE_MAX
            ? Number(process.env.REACT_APP_AMOUNT_RANGE_MAX)
            : Number(objFancy?.maxAmount),
          oddsLimit: objFancy?.oddsLimit ? objFancy?.oddsLimit : 0,
        });
      }

      let objSportBook = profileData?.matchSetting?.find(
        (res) =>
          res?.type == "sportBook" &&
          (profileData?.arrayCheck == "details"
            ? true
            : res?.sportType == details?.gameType)
      );
      if (!isEmpty(objSportBook)) {
        setAmountRangeSportBook({
          ...objSportBook,
          min: Number(objSportBook?.minAmount),
          max: Number(objSportBook?.maxAmount),
          oddsLimit: objSportBook?.oddsLimit ? objSportBook?.oddsLimit : 0,
        });
      }

      let objBookmaker = profileData?.matchSetting?.find(
        (res) =>
          res?.type == "bookmaker" &&
          (profileData?.arrayCheck == "details"
            ? true
            : res?.sportType == details?.gameType)
      );
      if (!isEmpty(obj)) {
        setAmountRangeBookmaker({
          ...objBookmaker,
          min: process.env.REACT_APP_AMOUNT_RANGE_MIN
            ? Number(process.env.REACT_APP_AMOUNT_RANGE_MIN)
            : Number(objBookmaker?.minAmount),
          max: process.env.REACT_APP_AMOUNT_RANGE_MAX
            ? Number(process.env.REACT_APP_AMOUNT_RANGE_MAX)
            : Number(objBookmaker?.maxAmount),
          oddsLimit: objBookmaker?.oddsLimit ? objBookmaker?.oddsLimit : 0,
        });
      }
    }
  }, [details, loginUserDetailData, totalMatched]);

  const listenSportBook = (message) => {
    const socket_data = message;
    if (socket_data.results && socket_data.eventId == params.eventId) {
      if (!isEmpty(user)) {
        setPremiumFancyPosition((prev) => {
          setPremiumFancy((current) => {
            let updatedArray = socket_data?.results;
            return updatedArray?.map((res) => {
              let obj = prev?.filter((item) => {
                return item?.fancyName == res?.marketName;
              });
              if (obj?.length > 0 && obj[0]?.fancyName == res?.marketName) {
                return {
                  ...res,
                  sportsBookSelection: res?.sportsBookSelection?.map((elem) => {
                    let temp = obj?.find(
                      (item) => elem?.id == item?.fancySelectionId
                    );
                    return {
                      ...elem,
                      position: temp?.position,
                    };
                  }),
                };
              } else {
                return res;
              }
            });
          });
          return prev;
        });
      }
    }
  };

  const listenBetfairOdds = (event) => {
    if (event?.results[0]?.mi == params?.marketId) {
      const socket_data =
        (event &&
          event?.results?.find((item) => item.eventId == params?.eventId)) ||
        [];
      if (
        socket_data &&
        socket_data?.eventId &&
        params?.eventId == socket_data?.eventId
      ) {
        if (socket_data.ms) {
          setBetFairMs(socket_data.ms);
        }
        if (socket_data.rt?.length) {
          const back_odds = socket_data.rt.filter((rt) => rt.ib) || [];
          const lay_odds = socket_data.rt.filter((rt) => !rt.ib) || [];
          setBackOdds(back_odds);
          setLayOdds(lay_odds);
          setTotalMatched(socket_data?.totalMatched);
        }
      }
    } else {
     
      setMarketNew((res) => {
        let socketData = event?.results[0];
        return res?.map((item) => {
          if (item?.marketId == socketData?.mi && socketData?.rt?.length > 0) {
            return {
              ...item,
              back_odds: socketData?.rt?.filter((rt) => rt.ib) || [],
              lay_odds: socketData?.rt?.filter((rt) => !rt.ib) || [],
              totalMatched: socketData?.totalMatched,
              ms: socketData?.ms,
              mi: socketData?.mi,
            };
          } else {
            return item;
          }
        });
      });
    }
  };

  const listenDiamondFancy = (message) => {
    if (message?.eventId === params?.eventId) {
      if (message?.results?.length > 0) {
        let temp = categoryArray?.filter((f) =>
          message?.results?.some((item) => item?.categoryType === f.type)
        );
        setFancyCategory(
          temp.length > 0
            ? [
                { type: "All", name: "All" },
                ...temp,
                { type: "More", name: "More" },
              ]
            : [{ type: "All", name: "All" }]
        );
        setFancyPosition((prev) => {
          setFancyList(
            message?.results?.map((res) => {
              let obj = prev?.find(
                (item) => item.selectionId == res.selectionId
              );
              return {
                ...res,
                odds: res,
                fancyName: res.name,
                position: !isEmpty(obj) ? obj?.position : "",
                newPosition:
                  Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
              };
            })
          );
          setFancyCategoryTabArray(
            message?.results?.map((res) => {
              let obj = prev?.find(
                (item) =>
                  item.selectionId == res.selectionId &&
                  item.fancyName == res.name
              );
              return {
                ...res,
                odds: res,
                fancyName: res.name,
                position: !isEmpty(obj) ? obj?.position : "",
                newPosition:
                  Math.abs(obj?.newPosition) > 0 ? obj?.newPosition : "",
              };
            })
          );
          return prev;
        });
      }
    }
  };

  const listenBookmakerData = (message) => {
    if (message.eventId === params?.eventId) {
      setBookmakerSelections((prev) => {
        return prev?.map((res) => {
          let temp = message?.results?.rt.find((item) => {
            return item?.name === res?.RunnerName;
          });
          if (temp?.name === res?.RunnerName) {
            return {
              ...res,
              ...temp,
            };
          } else {
            return { ...res, ...temp };
          }
        });
      });
      const back_odds = message?.results?.rt.filter((rt) => rt.ib) || [];
      const lay_odds = message?.results?.rt.filter((rt) => !rt.ib) || [];
      setBookmakerLayOdds(lay_odds);
      setBookmakerBackOdds(back_odds);
    }
  };

  useEffect(() => {
    if (checkRefresh && details?.eventId && !isEmpty(user)) {
      getSocket();
    }
  }, [checkRefresh, details, user]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  const setSelectPreminumFancyFunction = (item, res) => {
    if (activeOneClick) {
      handelPlacePreminumFancyBet({
        ...item,
        newRunner: res,
        // odds: res.odds,
        // selectionId: res.id,
        // runnerName: res.selectionName,
        // apiSiteSelectionId: res.apiSiteSelectionId,
      });
    } else {
      let mainArr = {
        ...item,
        newRunner: res,
        // odds: res.odds,
        // selectionId: res.id,
        // runnerName: res.selectionName,
        // apiSiteSelectionId: res.apiSiteSelectionId,
      };
      setSelectPreminumFancy(mainArr);

      if (loginUserDetailData?.defaultStake) {
        setPreminumFancyAmount(loginUserDetailData?.defaultStake);
        handelPreminumFancyPositions(
          loginUserDetailData?.defaultStake,
          mainArr
        );
      }
    }
  };
  const handelCancelUnmatched = async (id = "") => {
    const { status, data: response_users } = await apiGet(
      `${apiPath.cancelUnmatchedBets}?matchBetId=${id}`
    );
    try {
      if (status === 200) {
        if (response_users.success) {
          getCurrentBets();
          toast.success(response_users.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const getPremiumFancyBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPremiumFancyPosition +
        `?eventId=${params.eventId}&type=${details.gameType}`
    );
    if (status === 200) {
      if (response_users.success) {
        setPremiumFancyPosition(response_users?.results);
        setPremiumFancy((current) => {
          return current?.map((res) => {
            let obj = response_users?.results?.filter((item) => {
              return item?.fancyName == res?.marketName;
            });
            if (obj?.length > 0 && obj[0]?.fancyName == res?.marketName) {
              return {
                ...res,
                sportsBookSelection: res?.sportsBookSelection?.map((elem) => {
                  let temp = obj?.find(
                    (item) => elem?.id == item?.fancySelectionId
                  );
                  return {
                    ...elem,
                    position: temp?.position,
                  };
                }),
              };
            } else {
              return res;
            }
          });
        });
      }
    }
  };
  const getFancyBetPosition = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.fancyBetPosition + "?type=" + sportType + "&eventId=" + eventId
    );
    try {
      if (status === 200) {
        if (response_users.success) {
          setFancyPosition(response_users.results);
          // secureLocalStorage.setItem(
          //   "fancy",
          //   response_users?.results?.map((res) => {
          //     return { selectionId: res?.selectionId, position: res?.position };
          //   }) || []
          // );
          updatedFancyPosition(
            response_users?.results?.map((res) => {
              return { selectionId: res?.selectionId, position: res?.position };
            })
          );
        }
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const updatedFancyPosition = (object) => {
    setFancyPosition(object);
    setFancyList((prev) => {
      return prev?.map((res) => {
        let obj = object?.find((item) => item?.selectionId == res?.selectionId);
        return {
          ...res,
          odds: res,
          position: !isEmpty(obj) ? obj?.position : "",
        };
      });
    });
    setFancyCategoryTabArray((prev) => {
      return prev?.map((res) => {
        let obj = object?.find((item) => item?.selectionId == res?.selectionId);
        return {
          ...res,
          odds: res,
          position: !isEmpty(obj) ? obj?.position : "",
        };
      });
    });
  };
  const getCurrentBets = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.getBetsData + "?eventId=" + params.eventId
    );
    if (status === 200) {
      if (response_users.success) {
        setCurrentBets(response_users.results);
      }
    }
  };
  const handlePreminumAmount = (e, obj) => {
    setPreminumFancyAmount(e);
    handelPreminumFancyPositions(e, obj);
  };
  const handelPreminumFancyPositions = (price, slipObj) => {
    let backProfit = (slipObj?.newRunner?.odds - 1) * price;
    let backLoss = price;
    setPremiumFancy((current) => {
      return current?.map((rl) =>
        rl.id == slipObj.id
          ? {
              ...rl,
              sportsBookSelection: rl?.sportsBookSelection?.map((res) => {
                return res.marketId === slipObj?.newRunner?.marketId
                  ? {
                      ...res,
                      position:
                        res.position !== 0 && !isNaN(Math.abs(res.position))
                          ? res.position
                          : 0,
                      newPosition:
                        res.position !== 0 && !isNaN(Math.abs(res.position))
                          ? Number(backProfit) + Number(res.position)
                          : Number(backProfit),
                    }
                  : {
                      ...res,
                      position:
                        res.position !== 0 && !isNaN(Math.abs(res.position))
                          ? res.position
                          : 0,
                      newPosition:
                        res.position !== 0 && !isNaN(Math.abs(res.position))
                          ? Number(backLoss) - Number(res.position)
                          : -Number(backLoss),
                    };
              }),
            }
          : rl
      );
    });
  };
  const renderPreminumFancySlip = (item) => {
    return (
      <div className="super-canvas">
        <Table>
          <tbody>
            <tr>
              <td className="quick-batting-wrapper p-0">
                <div className="d-flex align-items-center batting-flex">
                  <div className="d-flex max-batting align-items-center">
                    {/* <d1>
                    <span>
                      <a href="#">Max</a>
                    </span>
                  </d1> */}
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        onChange={(e) =>
                          setAcceptAnyOddsPreminumFancyCheckbox(
                            e.target.checked ? 1 : 0
                          )
                        }
                        checked={
                          acceptAnyOddsPreminumFancyCheckbox ||
                          loginUserDetailData?.sportsBook
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                      >
                        Accept Any Odds
                      </label>
                    </div>
                  </div>

                  <dd className="col-btn ms-2 w-auto ">
                    <a
                      href="#"
                      onClick={() => {
                        setSelectPreminumFancy({});
                        setPreminumFancyAmount("");
                      }}
                    >
                      Cancel
                    </a>
                  </dd>

                  <dd className="quick-bet-confirm ms-2">
                    <strong>{item?.newRunner?.odds}</strong>
                    <span>odds</span>
                  </dd>

                  <dd className="up_down_rate mx-3 quick-input">
                    <input
                      type="text"
                      title={!preminumfancyAmount ? "Enter amount" : ""}
                      onKeyPress={(e) => {
                        isInputNumber(e);
                        if (e.target.value.length > 6) {
                          e.preventDefault();
                        }
                      }}
                      max={1500000}
                      value={preminumfancyAmount}
                      onChange={(e) =>
                        handlePreminumAmount(e.target.value, item)
                      }
                    />
                  </dd>

                  <dd className="col-send">
                    <button
                      className="bookmaker-place-bet-btn btn"
                      style={
                        loaderFancyBet ||
                        details?.sport_setting?.status != "active"
                          ? {
                              cursor: "not-allowed",
                              color: "#fff",
                              background: "gray",
                            }
                          : {
                              cursor: "pointer",
                            }
                      }
                      onClick={() =>
                        handelPlacePreminumFancyBet(selectPreminumFancy)
                      }
                      disabled={
                        loaderFancyBet ||
                        details?.sport_setting?.status != "active"
                      }
                    >
                      Place Bets
                      {loaderFancyBet && (
                        <span style={{ marginLeft: "10px" }}>
                          <Loader
                            Style={{
                              borderTop: "7px solid #FFB80C",
                              borderBottom: "7px solid #FFB80C",
                              width: "10px",
                              height: "10px",
                            }}
                          />
                        </span>
                      )}
                    </button>
                  </dd>
                </div>
                <div className="preset_value p-0 m-0">
                  <ul>
                    {priceData?.map((res, index) => {
                      return (
                        <li key={index + 1}>
                          <a
                            href="#"
                            onClick={() =>
                              handlePreminumAmount(res.value, item)
                            }
                          >
                            {res.value}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };
  const getTournamentData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.seriesList + "?gameType=" + sportType
    );
    if (status === 200) {
      if (response_users.success) {
        setTournamentData(response_users.results);
      }
    }
  };
  const handelAll = async () => {
    const { status, data: response_users } = await apiGet(apiPath.sportsList);
    if (status === 200) {
      if (response_users.success) {
        setAllSports(response_users.results);
      }
    }
  };
  const getMatches = async (id) => {
    id && setSeriesId(id);
    const { status, data: response_users } = await apiGet(
      `${
        !id
          ? apiPath.matchList + "?gameType=" + sportType
          : apiPath.matchList + "?gameType=" + sportType + "&seriesId=" + id
      }`
    );
    if (status === 200) {
      if (response_users.success) {
        id
          ? setMatchesWithId(response_users.results)
          : setMatches(response_users.results);
      }
    }
  };
  const handelClickOddDetails = (matchId, marketId) => {
    navigate("/markets/" + sportType + "/" + matchId + "/" + marketId);
  };

  const refereshHandle = () => {
    getMatchDetails();
  };

  const getMatchDetails = async () => {
    let temp = !isEmpty(user)
      ? `?eventId=${params?.eventId}&userId=${user ? user?.user?._id : ""}`
      : `?eventId=${params?.eventId}`;
    const { status, data: response_users } = await apiGet(
      apiPath.matchDetail + temp
    );
    if (status === 200) {
      if (response_users.success) {
        const result = {
          centralizedId: response_users?.results?.centralizedId || null,
          bookmakerCentralizedId:
            response_users?.results?.bookmakerCentralizedId || null,
        };

        response_users?.results?.jsonData?.map((it) => {
          it.position = 0;
        });

        response_users?.results?.jsonBookmakerData?.map((data) => {
          data.position = 0;
        });

        response_users?.results?.fancyList?.map((data) => {
          data.position = 0;
        });

        let temp = categoryArray.filter((f) =>
          response_users?.results?.fancyList.some(
            (item) => item.categoryType === f.type
          )
        );
        setFancyCategory([
          { type: "All", name: "All" },
          ...temp,
          { type: "More", name: "More" },
        ]);
        setDetails(response_users?.results);
        setSeriesId(response_users?.results?.seriesId);
        setMatchName(response_users?.results?.eventName);
        setSeriesName(response_users?.results?.tournament?.seriesName);
        if (
          response_users?.results?.hasFancy &&
          response_users?.results?.provider == "diamond"
        ) {
          setShowFancy("fancy");
        } else {
          if (
            response_users?.results?.gameType == "cricket" &&
            !response_users?.results?.eventName.includes(" SRL ")
          ) {
            if (
              response_users?.results?.fancyList?.length == 0 &&
              !isEmpty(user)
            ) {
              if (response_users?.results.fancy == "off") {
                setShowFancy("premium");
              } else if (response_users?.results.premiumFancy == "off") {
                setShowFancy("fancy");
              } else {
                !response_users?.results?.eventName.includes(" T10 ")
                  ? setShowFancy("premium")
                  : setShowFancy("fancy");
              }
            } else {
              if (response_users?.results.fancy == "off") {
                setShowFancy("premium");
              } else if (response_users?.results.premiumFancy == "off") {
                setShowFancy("fancy");
              } else {
                setShowFancy("fancy");
              }
            }
          } else {
            setShowFancy("premium");
          }
        }

        if (response_users?.results?.marketList?.length > 1) {
          let latest = response_users?.results?.marketList?.filter((res) => {
            return res?.market !== "Match Odds" && res?.jsonData?.length > 0;
          });
          if (latest?.length > 0) {
            setMarketNew(latest || []);
            let updated = latest?.map((res) => {
              return res?.marketId;
            });
            if (updated?.length > 0) {
              setMarketIdNew(updated);
            }
          }
          setSelections(
            response_users?.results?.marketList?.filter((res) => {
              return res?.marketId == params?.marketId;
            })[0]?.jsonData || []
          );
        } else {
          setSelections(response_users?.results?.jsonData || []);
        }
        setLiabilitySelection(
          JSON.parse(JSON.stringify(response_users?.results?.jsonData)) || []
        );
        listenBetfairOdds({ results: response_users?.results?.matchOdss });
        setBookmakerSelections(
          response_users?.results?.jsonBookmakerData || []
        );
      }
      if (response_users?.results?.multi_market) {
        setIsMultiMarket(true);
      }
    }
  };

  const getMatchOddsMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds +
        `?marketId=${id?.join(",")}&multi=true&eventId=${params?.eventId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setMarketNew((prev) => {
          return prev?.map((res) => {
            let temp = socket_data?.find((temp) => {
              return temp?.mi == res?.marketId;
            });
            if (temp?.mi == res?.marketId) {
              return {
                ...res,
                odds: {
                  mi: temp?.mi,
                  ms: temp?.ms,
                  totalMatched: temp?.totalMatched,
                  ts: temp?.ts,
                  rt: temp?.rt,
                },
              };
            } else {
              return res;
            }
          });
        });
      }
    }
  };

  const getBetPositionMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition +
        `?eventId=${params?.eventId}&type=${
          details?.gameType
        }&marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users?.results?.betFair?.length > 0) {
          updatePositionMulti(response_users?.results?.betFair);
        }
      }
    }
  };

  const updatePositionMulti = (object) => {
    setMarketNew((current) =>
      object?.length > 0
        ? current?.map((res) => {
            return {
              ...res,
              jsonData: res?.jsonData?.map((newObj) => {
                let obj = object?.find(
                  (item) => item?.selectionId == newObj?.SelectionId
                );
                if (!isEmpty(obj)) {
                  return {
                    ...newObj,
                    position:
                      obj?.selectionId == newObj?.SelectionId
                        ? obj?.position
                        : 0,
                    newPosition: 0,
                  };
                } else {
                  return {
                    ...newObj,
                    position: 0,
                    newPosition: 0,
                  };
                }
              }),
            };
          })
        : current
    );
  };

  useEffect(() => {
    getMatches();
    getTournamentData();
  }, []);

  useEffect(() => {
    getMatchDetails();
    getFancyData();
    getMatchData();
  }, [params.eventId, params.marketId]);

  useEffect(() => {
    setTimeout(() => {
      setShowBetFairLoader(false);
    }, 2000);
  }, [!back_odds.length || !lay_odds.length]);

  useEffect(() => {
    if (!isEmpty(user)) {
      getCurrentBets();
    }
    if (isEmpty(user)) {
      let timer = setInterval(() => {
        getMatchDetails();
        getFancyData();
        getMatchData();
      }, 60000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [user]);

  useEffect(() => {
    if (marketIdNew?.length > 0) {
      // let interval = setInterval(() => {
      //   getMatchOddsMulti(marketIdNew);
      // }, 2000);
      // return () => {
      //   clearInterval(interval);
      // };
      setTimeout(() => {
        getMatchOddsMulti(marketIdNew);
      },2000)
    }
  }, [marketIdNew]);

  useEffect(() => {
    if (marketIdNew?.length > 0) {
      setTimeout(() => {
        getBetPositionMulti(marketIdNew);
      }, 3000);
    }
  }, [marketIdNew]);

  const getMatchData = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${!isEmpty(id) ? id : params.marketId}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        if (
          socket_data &&
          socket_data?.eventId &&
          params?.eventId == socket_data?.eventId
        ) {
          if (socket_data.ms) {
            setBetFairMs(socket_data.ms);
          }
          if (socket_data.rt?.length) {
            const back_odds = socket_data?.rt?.filter((rt) => rt.ib) || [];
            const lay_odds = socket_data?.rt?.filter((rt) => !rt.ib) || [];
            setBackOdds(back_odds);
            setLayOdds(lay_odds);
            setTotalMatched(socket_data?.totalMatched);
          }
          if (!isEmpty(id)) {
            setCheckRefresh(true);
          }
        }
      }
    }
  };

  const getFancyData = async () => {
    const { status, data: message } = await apiGet(
      apiPath.getFancyOdds + `?eventId=${params.eventId}`
    );
    if (status === 200) {
      if (message.success) {
        if (message?.results?.length > 0) {
          let temp = categoryArray?.filter((f) =>
            message?.results?.some((item) => item?.categoryType === f.type)
          );
          setFancyCategory(
            temp.length > 0
              ? [
                  { type: "All", name: "All" },
                  ...temp,
                  { type: "More", name: "More" },
                ]
              : [{ type: "All", name: "All" }]
          );
          setFancyPosition((prev) => {
            if (prev?.length > 0) {
              setFancyList(
                message?.results?.map((res) => {
                  let obj = prev?.find(
                    (item) =>
                      item.selectionId == res.selectionId &&
                      item.fancyName == res.name
                  );
                  return {
                    ...res,
                    odds: res,
                    fancyName: res.name,
                    position: !isEmpty(obj) ? obj?.position : "",
                  };
                })
              );
              setFancyCategoryTabArray(
                message?.results?.map((res) => {
                  let obj = prev?.find(
                    (item) =>
                      item.selectionId == res.selectionId &&
                      item.fancyName == res.name
                  );
                  return {
                    ...res,
                    odds: res,
                    fancyName: res.name,
                    position: !isEmpty(obj) ? obj?.position : "",
                  };
                })
              );
            } else {
              setFancyList(
                message?.results?.map((res) => {
                  return { ...res, odds: res };
                })
              );
              setFancyCategoryTabArray(
                message?.results?.map((res) => {
                  return { ...res, odds: res };
                })
              );
            }
            return prev;
          });
        }
      }
    }
  };

  const addMarket = (eventId) => {
    handelAddMultiMarket(eventId);
    setIsMultiMarket(!isMultimarket);
  };
  useEffect(() => {
    let channelID = details?.channel ? details?.channel : "";
    setStreamingUrl(
      "https://mediasrv789-ss247-23-prod-sa-ulivestreaming.com/" +
        channelID +
        "/index.m3u8"
    );
  }, [details]);

  const getBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition + `?eventId=${eventId}&type=${params.sportType}`
    );
    if (status === 200) {
      //BET FAIR
      if (response_users.success) {
        if (response_users?.results?.betFair?.length > 0) {
          secureLocalStorage.setItem(
            "betFair",
            response_users?.results?.betFair
          );
        }
        if (response_users?.results?.bookmaker?.length > 0) {
          secureLocalStorage.setItem(
            "bookmaker",
            response_users?.results?.bookmaker
          );
        }
        if (response_users?.results?.tie?.length > 0) {
          secureLocalStorage.setItem("tie", response_users?.results?.tie);
        }
        if (response_users?.results?.toss?.length > 0) {
          secureLocalStorage.setItem("toss", response_users?.results?.toss);
        }
        updatePosition(response_users?.results);
      }
    }
  };

  const updatePosition = (object) => {
    setTempSelection(object?.betFair);
    setSelections((current) => {
      return object?.betFair?.length > 0
        ? current?.map((res) => {
            let obj = object?.betFair?.find(
              (item) => item?.selectionId == res?.SelectionId
            );
            if (!isEmpty(obj)) {
              return {
                ...res,
                position:
                  obj?.selectionId == res?.SelectionId ? obj?.position : 0,
                newPosition: 0,
              };
            } else {
              return {
                ...res,
                position: 0,
                newPosition: 0,
              };
            }
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          });
    });
    setBookmakerSelections((current) => {
      return object?.bookmaker?.length > 0
        ? current?.map((res) => {
            let obj = object?.bookmaker?.find(
              (item) => item?.selectionId == res?.selectionID
            );
            return {
              ...res,
              position: obj?.position ? obj?.position : 0,
              newPosition: 0,
            };
          })
        : current?.map((res) => {
            return {
              ...res,
              position: 0,
              newPosition: 0,
            };
          });
    });
  };

  const updatePositionAfterBetPlace = (type, marketName) => {
    if (type == "betFair") {
      secureLocalStorage?.removeItem("betFair");
      secureLocalStorage.setItem(
        "betFair",
        selections?.map((res) => {
          return {
            selectionId: res?.SelectionId,
            position: res?.newPosition,
          };
        })
      );
      setSelections(
        selections?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "bookmaker") {
      secureLocalStorage?.removeItem("bookmaker");
      secureLocalStorage.setItem(
        "bookmaker",
        bookmakerSelections?.map((res) => {
          return {
            selectionId: res?.selectionID,
            position: res?.newPosition,
          };
        })
      );
      setBookmakerSelections(
        bookmakerSelections?.map((res) => {
          return {
            ...res,
            position: res?.newPosition,
            newPosition: 0,
          };
        })
      );
    } else if (type == "multi") {
      let newly = marketNew?.map((res) => {
        if (res?.market == marketName) {
          return {
            ...res,
            jsonData: res?.jsonData?.map((newObj) => {
              return {
                ...newObj,
                position: newObj?.newPosition,
                newPosition: 0,
              };
            }),
          };
        } else {
          return res;
        }
      });
      setMarketNew(newly);
      secureLocalStorage?.removeItem("betFairMulti");
      secureLocalStorage.setItem(
        "betFairMulti",
        newly
          ?.map((res) => {
            return res?.jsonData;
          })
          ?.flat()
          ?.map((res) => {
            return {
              selectionId: res?.SelectionId,
              position: isNumber(res?.position) ? res?.position : 0,
            };
          })
      );
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (!isEmpty(user)) {
        if (!isEmpty(details)) {
          if (
            !(
              secureLocalStorage.getItem("betFair") ||
              secureLocalStorage.getItem("bookmaker")
            )
          ) {
            getBetPosition();
          } else {
            let obj = {
              betFair:
                secureLocalStorage.getItem("betFair")?.length > 0
                  ? secureLocalStorage.getItem("betFair")
                  : [],
              bookmaker:
                secureLocalStorage.getItem("bookmaker")?.length > 0
                  ? secureLocalStorage.getItem("bookmaker")
                  : [],
              tie:
                secureLocalStorage.getItem("tie")?.length > 0
                  ? secureLocalStorage.getItem("tie")
                  : [],
              toss:
                secureLocalStorage.getItem("toss")?.length > 0
                  ? secureLocalStorage.getItem("toss")
                  : [],
            };
            updatePosition(obj);
          }
          getFancyBetPosition();
          getPremiumFancyBetPosition();
        }
      }
    }, 2000);
  }, [details, user]);

  useEffect(() => {
    setAllBetslipData([...backBetSlipArray, ...layBetSlipArray]);
    handelSetLiability([...backBetSlipArray, ...layBetSlipArray]);
    disablePlaceBetFair([...backBetSlipArray, ...layBetSlipArray]);
  }, [backBetSlipArray, backBetSlipValue, layBetSlipArray, layBetSlipValue]);

  const amountRange = (details, marketName) => {
    if (details?.gameType == "soccer") {
      if (marketName !== "Match Odds") {
        let obj = details?.matchSetting?.find((res) => {
          return res?.type == "over_under";
        });
        if (!isEmpty(obj)) {
          return obj;
        }
      } else {
        let obj = details?.matchSetting?.find((res) => res?.type == "betFaire");
        if (!isEmpty(obj)) {
          return obj;
        }
      }
    } else {
      let result = details?.matchSetting?.find((res) => {
        return res?.type == marketName;
      });
      if (!isEmpty(result)) {
        let obj = result;
        if (!isEmpty(obj)) {
          return obj;
        }
      } else {
        let obj = details?.matchSetting?.find((res) => res?.type == "betFaire");
        if (!isEmpty(obj)) {
          return obj;
        }
      }
    }
  };
  const handelShowBetSlip = (
    filter_odds,
    selection,
    betType,
    marketName,
    marketId
  ) => {
    let AmountObj = {};
    if (details?.matchSetting && details?.matchSetting.length) {
      AmountObj = amountRange(details, marketName);
    }
    const filteredData = {
      ...filter_odds,
      price: priceList,
      selectionId: selection.SelectionId,
      teamName: selection.RunnerName,
      betType: betType,
      marketName: marketName,
      marketId: marketId,
      rangeObject: AmountObj,
    };
    if (filter_odds?.ib == true) {
      setBetBackSlipArray([filteredData]);
      setLayBackSlipArray([]);
    } else {
      setBetBackSlipArray([]);
      setLayBackSlipArray([filteredData]);
    }
    showToggle();
  };
  const handelBetFairPositions = (slipArr, index) => {
    let newselection = selections.map((data) => {
      let positionTemp = tempSelection.find(
        (el) => el.selectionId == data.SelectionId
      )?.position;

      data.position = positionTemp ? positionTemp : 0;

      return data;
    });
    setSelections(newselection);
    slipArr.map((data, ind) => {
      if (data?.activePrice >= 0) {
        if (data.ib) {
          let backProfit = (data.rt - 1) * data.activePrice;
          let backLoss = data.activePrice;
          if (Math.abs(selections[0]?.position) > 0) {
            setSelections(
              selections?.map((rl) => {
                if (rl.SelectionId == data.ri) {
                  return {
                    ...rl,
                    newPosition: Number(rl.position) + backProfit,
                  };
                } else {
                  return {
                    ...rl,
                    newPosition: Number(rl.position) - backLoss,
                  };
                }
              })
            );
            if (index == ind) {
              const temp = backBetSlipArray;
              temp?.map((item, idxx) => {
                if (index == idxx) {
                  item.profitLiability = parseInt(backProfit.toFixed(2));
                }
              });
              setBetBackSlipArray((prevState) => prevState);
            }
          } else {
            setSelections(
              selections?.map((rl) => {
                if (rl.SelectionId == data.ri) {
                  return {
                    ...rl,
                    newPosition: Number(backProfit),
                  };
                } else {
                  return {
                    ...rl,
                    newPosition: -Number(backLoss),
                  };
                }
              })
            );
            if (index == ind) {
              const temp = backBetSlipArray;
              temp?.map((item, idxx) => {
                if (index == idxx) {
                  item.profitLiability = parseInt(backProfit.toFixed(2));
                }
              });
              setBetBackSlipArray((prevState) => prevState);
            }
          }
        } else {
          let backProfit = data.activePrice;
          let backLoss = (data.rt - 1) * data.activePrice;
          if (Math.abs(selections[0]?.position) > 0) {
            setSelections(
              selections?.map((rl) => {
                if (rl.SelectionId == data.ri) {
                  return {
                    ...rl,
                    newPosition: Number(rl.position) - backLoss,
                  };
                } else {
                  return {
                    ...rl,
                    newPosition: Number(rl.position) + backProfit,
                  };
                }
              })
            );
            const temp = layBetSlipArray;
            temp?.map((item, idx) => {
              if (index == idx) {
                item.profitLiability = parseInt(backProfit.toFixed(2));
              }
            });
            setLayBackSlipArray((prevState) => prevState);
          } else {
            setSelections(
              selections?.map((rl) => {
                if (rl.SelectionId == data.ri) {
                  return {
                    ...rl,
                    newPosition: -Number(backLoss),
                  };
                } else {
                  return {
                    ...rl,
                    newPosition: Number(backProfit),
                  };
                }
              })
            );
            const temp = layBetSlipArray;
            temp?.map((item, idx) => {
              if (index == idx) {
                item.profitLiability = parseInt(backProfit.toFixed(2));
              }
            });
            setLayBackSlipArray((prevState) => prevState);
          }
        }
      }
    });
  };
  const handelBetFairPositionsMulti = (slipArr, market, index) => {
    let selectionsTemp = [...marketNew]
      ?.filter((res) => {
        return res?.market == market;
      })
      ?.map((res) => {
        return {
          ...res,
          position: res?.position || 0,
          newPosition: res?.position || 0,
        };
      });
    let newArray = selectionsTemp[0]?.jsonData;
    slipArr.map((data, ind) => {
      if (data?.activePrice >= 0) {
        if (data.ib) {
          let backProfit = (data.rt - 1) * data.activePrice;
          let backLoss = data.activePrice;
          if (Math.abs(newArray[0]?.position) > 0) {
            newArray = newArray?.map((rl) => {
              if (rl.SelectionId == data.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              }
            });
            if (index == ind) {
              const temp = backBetSlipArray;
              temp?.map((item, idxx) => {
                if (index == idxx) {
                  item.profitLiability = parseInt(backProfit.toFixed(2));
                }
              });
              setBetBackSlipArray((prevState) => prevState);
            }
          } else {
            newArray = newArray?.map((rl) => {
              if (rl.SelectionId == data.ri) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            });
            if (index == ind) {
              const temp = backBetSlipArray;
              temp?.map((item, idxx) => {
                if (index == idxx) {
                  item.profitLiability = parseInt(backProfit.toFixed(2));
                }
              });
              setBetBackSlipArray((prevState) => prevState);
            }
          }
        } else {
          let backProfit = data.activePrice;
          let backLoss = (data.rt - 1) * data.activePrice;
          if (Math.abs(newArray[0]?.position) > 0) {
            newArray = newArray?.map((rl) => {
              if (rl.SelectionId == data.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              }
            });
            const temp = layBetSlipArray;
            temp?.map((item, idx) => {
              if (index == idx) {
                item.profitLiability = parseInt(backProfit.toFixed(2));
              }
            });
            setLayBackSlipArray((prevState) => prevState);
          } else {
            newArray = newArray?.map((rl) => {
              if (rl.SelectionId == data.ri) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            });
            const temp = layBetSlipArray;
            temp?.map((item, idx) => {
              if (index == idx) {
                item.profitLiability = parseInt(backProfit.toFixed(2));
              }
            });
            setLayBackSlipArray((prevState) => prevState);
          }
        }
      }
    });
    setMarketNew((prev) => {
      return prev?.map((res) => {
        if (res?.market == market) {
          return { ...res, jsonData: newArray };
        } else {
          return res;
        }
      });
    });
  };
  const handelSetLiability = (slipArr) => {
    let liabilitySelection = betFairLiabalitySelection.map((data) => {
      data.position = 0;
      return data;
    });

    setLiabilitySelection(JSON.parse(JSON.stringify(liabilitySelection)));
    // slipArr.map((data, ind) => {
    //   if (data?.activePrice >= 0) {
    //     if (data.ib) {
    //       let currentPosition = betFairLiabalitySelection.find(
    //         (rl) => rl.SelectionId == data.ri
    //       );
    //       let otherTeamPosition;
    //       let thirdTeamPosition;
    //       if (data.teamName == "The Draw") {
    //         otherTeamPosition = betFairLiabalitySelection[0];
    //         thirdTeamPosition = betFairLiabalitySelection[1];
    //       } else {
    //         otherTeamPosition = betFairLiabalitySelection.find(
    //           (rl) => rl.SelectionId !== data.ri && rl.RunnerName != "The Draw"
    //         );
    //         thirdTeamPosition = betFairLiabalitySelection[2]
    //           ? betFairLiabalitySelection[2]
    //           : false;
    //       }

    //       let backProfit = (data.rt - 1) * data.activePrice;
    //       let backLoss = data.activePrice;
    //       currentPosition.position = currentPosition.position + backProfit;
    //       otherTeamPosition.position = otherTeamPosition.position - backLoss;
    //       if (thirdTeamPosition) {
    //         thirdTeamPosition.position = thirdTeamPosition.position - backLoss;
    //       }
    //       const result = betFairLiabalitySelection.map((data) => {
    //         if (data.SelectionId == currentPosition.SelectionId) {
    //           data.position = currentPosition.position
    //             ? parseFloat(currentPosition.position.toFixed(2))
    //             : 0;
    //         }
    //         if (data.SelectionId == otherTeamPosition.SelectionId) {
    //           data.position = otherTeamPosition.position
    //             ? parseFloat(otherTeamPosition.position.toFixed(2))
    //             : 0;
    //         }
    //         if (
    //           thirdTeamPosition &&
    //           data.SelectionId == thirdTeamPosition.SelectionId
    //         ) {
    //           data.position = thirdTeamPosition.position
    //             ? parseFloat(thirdTeamPosition.position.toFixed(2))
    //             : 0;
    //         }
    //         return data;
    //       });

    //       setLiabilitySelection(JSON.parse(JSON.stringify(result)));
    //     } else {
    //       let currentPosition = betFairLiabalitySelection.find(
    //         (rl) => rl.SelectionId == data.ri
    //       );
    //       let otherTeamPosition;
    //       let thirdTeamPosition;
    //       if (data.teamName == "The Draw") {
    //         otherTeamPosition = betFairLiabalitySelection[0];
    //         thirdTeamPosition = betFairLiabalitySelection[1];
    //       } else {
    //         otherTeamPosition = betFairLiabalitySelection.find(
    //           (rl) => rl.SelectionId !== data.ri && rl.RunnerName != "The Draw"
    //         );
    //         thirdTeamPosition = betFairLiabalitySelection[2]
    //           ? betFairLiabalitySelection[2]
    //           : false;
    //       }

    //       let backProfit = data.activePrice;
    //       let backLoss = (data.rt - 1) * data.activePrice;
    //       currentPosition.position = currentPosition.position - backLoss;
    //       otherTeamPosition.position = otherTeamPosition.position + backProfit;
    //       if (thirdTeamPosition) {
    //         thirdTeamPosition.position =
    //           thirdTeamPosition.position + backProfit;
    //       }
    //       const result = betFairLiabalitySelection.map((data) => {
    //         if (data.SelectionId == currentPosition.SelectionId) {
    //           data.position = currentPosition.position
    //             ? parseFloat(currentPosition.position.toFixed(2))
    //             : 0;
    //         }
    //         if (data.SelectionId == otherTeamPosition.SelectionId) {
    //           data.position = otherTeamPosition.position
    //             ? parseFloat(otherTeamPosition.position.toFixed(2))
    //             : 0;
    //         }
    //         if (
    //           thirdTeamPosition &&
    //           data.SelectionId == thirdTeamPosition.SelectionId
    //         ) {
    //           data.position = thirdTeamPosition.position
    //             ? parseFloat(thirdTeamPosition.position.toFixed(2))
    //             : 0;
    //         }
    //         return data;
    //       });

    //       setLiabilitySelection(JSON.parse(JSON.stringify(result)));
    //     }
    //     const temp = liabilitySelection?.filter((pl) => pl.position > 0);

    //     if (temp.length == 0) {
    //       let tempNew = 0;
    //       liabilitySelection.map((rt) => {
    //         tempNew = tempNew + rt.position;
    //       });
    //       setBetFairLiability(tempNew);
    //     } else if (temp.length == 2) {
    //       setBetFairLiability(0);
    //     } else {
    //       liabilitySelection.map((rt) => {
    //         if (rt.position < 0) {
    //           setBetFairLiability(rt.position);
    //         }
    //       });
    //     }
    //   }
    // });
  };
  const handelSetPrice = (item, data) => {
    const filteredData = {
      ...data,
      price: priceList,
      activePrice: item?.value,
      selectionId: data?.selectionId,
      teamName: data?.teamName,
      betType: data?.betType,
    };

    if (data?.ib == true) {
      const matchedRi = backBetSlipArray.find((el) => el.ri == data.ri);
      if (matchedRi) {
        const index = backBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);

        backBetSlipArray.splice(index, 1, filteredData);
        let allArray = [...backBetSlipArray, ...layBetSlipArray];
        setBetBackSlipArray((prevState) => [...prevState]);
        if (allArray[0]?.marketName == "Match Odds") {
          handelBetFairPositions(
            [...backBetSlipArray, ...layBetSlipArray],
            index
          );
        } else {
          handelBetFairPositionsMulti(
            [...backBetSlipArray, ...layBetSlipArray],
            allArray[0]?.marketName,
            index
          );
        }
      }
    } else {
      const matchedRi = layBetSlipArray.find((el) => el.ri == data.ri);
      if (matchedRi) {
        const index = layBetSlipArray.findIndex((x) => x.ri == matchedRi.ri);
        layBetSlipArray.splice(index, 1, filteredData);
        setLayBackSlipArray((prevState) => [...prevState]);
        let allArray = [...backBetSlipArray, ...layBetSlipArray];
        if (allArray[0]?.marketName == "Match Odds") {
          handelBetFairPositions(
            [...backBetSlipArray, ...layBetSlipArray],
            index
          );
        } else {
          handelBetFairPositionsMulti(
            [...backBetSlipArray, ...layBetSlipArray],
            allArray[0]?.marketName,
            index
          );
        }
      }
    }
  };
  const handelChangeBackAll = (event, index, data) => {
    setBackBetSlipValue(+event.target.value);
    const temp = backBetSlipArray;
    temp[index].activePrice = event.target.value ? +event.target.value : null;
    setBetBackSlipArray((prevState) => temp);
    handelBetFairPositions([...backBetSlipArray, ...layBetSlipArray], index);
  };
  const handelChangeLayAll = (event, index, data) => {
    setLayBetSlipValue(+event.target.value);
    const temp = layBetSlipArray;
    temp[index].activePrice = event.target.value ? +event.target.value : null;
    setLayBackSlipArray((prevState) => temp);
    handelBetFairPositions([...backBetSlipArray, ...layBetSlipArray], index);
  };
  const handelRemoveBackBetSlip = (index) => {
    const arr = [...backBetSlipArray];
    arr.splice(index, 1);
    setBetBackSlipArray(arr);
    handelBetFairPositions([...arr, ...layBetSlipArray], index);
  };
  const handelRemoveLayBetSlip = (index) => {
    const arr = [...layBetSlipArray];
    arr.splice(index, 1);
    setLayBackSlipArray(arr);
    handelBetFairPositions([...backBetSlipArray, ...arr], index);
  };

  const handelPlaceBetfair = async (filter_Odds, selections, betType) => {
    const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
    const arr = [...backBetSlipArray, ...layBetSlipArray];
    let rangeObject = arr[0]?.rangeObject;
    const Bet = arr?.map((it) => {
      return {
        selectionId: it?.selectionId,
        teamName: it?.teamName,
        bhav: it?.rt || null,
        amount: it?.activePrice,
        betType: it.betType,
      };
    });
    if (user) {
      if (activeOneClick) {
        if (!activeOneClickAmount) {
          toast.error("Please select one click bet amount");
          return false;
        }
      }
      setTimeLeft(rangeObject?.betDelay > 1 ? rangeObject?.betDelay - 1 : 1);
      setBetLoader(true);
      setLoaderPlaceBetFair(true);
      const placeFunc = async () => {
        const result = activeOneClick
          ? {
              eventId: eventId,
              marketId: arr[0]?.marketId,
              betPlaceTime: date,
              betPlaceType: "betfair",
              bet: [
                {
                  selectionId: filter_Odds?.ri,
                  teamName: selections?.RunnerName,
                  bhav: filter_Odds?.rt,
                  amount: activeOneClickAmount,
                  betType: betType,
                },
              ],
            }
          : {
              eventId: eventId,
              marketId: arr[0]?.marketId,
              betPlaceTime: date,
              betPlaceType: "betfair",
              bet: Bet,
            };
        const { data: response_users2 } = await apiPost(
          apiPath.beforeBetPlace,
          result
        );
        if (response_users2.success) {
          const { status, data: response_users } = await apiPost(
            sportType === "cricket"
              ? apiPath.betfairPlaceBet
              : sportType === "soccer"
              ? apiPath.soccerPlaceBet
              : apiPath.tennisPlaceBet,
            result
          );
          if (status === 200) {
            if (response_users.success) {
              setLoaderPlaceBetFair(false);
              setBetLoader(false);
              getCoins();
              toast.success(response_users.message);
              if (arr[0]?.marketName !== "Match Odds") {
                updatePositionAfterBetPlace("multi", arr[0]?.marketName);
              } else {
                updatePositionAfterBetPlace("betFair");
              }
              getCurrentBets();
              setBetBackSlipArray([]);
              setLayBackSlipArray([]);
              setAllBetslipData([]);
            } else {
              setBetLoader(false);
              setLoaderPlaceBetFair(false);
              toast.error(response_users.message);
            }
          }
        } else {
          setBetLoader(false);
          setLoaderPlaceBetFair(false);
          toast.error(response_users2.message);
        }
      };
      setTimeout(
        () => {
          placeFunc();
        },
        rangeObject?.betDelay > 1 ? (rangeObject?.betDelay - 1) * 1000 : 1000
      );
    } else {
      setShowLogin(true);
    }
  };

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const disablePlaceBetFair = (Arr) => {
    let result = Arr[0];
    if (!isEmpty(result)) {
      if ("activePrice" in result) {
        if (result?.activePrice == 0) {
          setMinAmountCond(true);
          setDisableBetFairPlaceBetBtn(true);
        } else if (
          result?.activePrice > 0 &&
          result?.activePrice <= result?.rangeObject?.maxAmount &&
          result?.activePrice >= result?.rangeObject?.minAmount
        ) {
          setMinAmountCond(false);
          setDisableBetFairPlaceBetBtn(false);
        } else {
          setMinAmountCond(true);
          setDisableBetFairPlaceBetBtn(true);
        }
      } else {
        setMinAmountCond(false);
        setDisableBetFairPlaceBetBtn(false);
      }
    }
  };

  const handelClearAllBet = () => {
    let newArray = [...backBetSlipArray, ...layBetSlipArray];
    if (newArray[0]?.marketName !== "Match Odds") {
      setMarketNew((prev) => {
        return prev?.map((res) => {
          if (res?.market == newArray[0]?.marketName) {
            return {
              ...res,
              jsonData: res?.jsonData?.map((res) => {
                return { ...res, newPosition: 0 };
              }),
            };
          } else {
            return res;
          }
        });
      });
    } else {
      setSelections((current) => {
        return current.map((res) => {
          return {
            Handicap: res.Handicap,
            RunnerName: res.RunnerName,
            SelectionId: res.SelectionId,
            position: res.position ? res.position : 0,
          };
        });
      });
    }
    setBetBackSlipArray([]);
    setLayBackSlipArray([]);
  };
  //***************** BOOKMAKER FUNCTIONS ***************************//
  const handelShowBookmakerBetSlip = (filter_odds, selection, betType) => {
    const filteredData = {
      ...filter_odds,
      price: priceList,
      activePrice: null,
      selectionId: selection.selectionID,
      teamName: selection.runnerName,
      betType: betType,
    };

    setBookMakerBetSlipObj(filteredData);
  };
  const handelBookmakerPositions = (slipObj) => {
    let bookmaker = [...bookmakerSelections];
    if (slipObj?.activePrice >= 0) {
      if (slipObj.ib) {
        let backProfit = Number(
          (Number(slipObj?.rt) / 100) * Number(slipObj?.activePrice)
        );
        let backLoss = Number(slipObj?.activePrice);
        if (Math.abs(bookmaker[0]?.position) > 0) {
          setBookmakerSelections(
            bookmaker?.map((rl) => {
              if (rl.selectionID == slipObj?.selectionId) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              }
            })
          );
        } else {
          setBookmakerSelections(
            bookmaker?.map((rl) => {
              if (rl.selectionID == slipObj?.selectionId) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        let backProfit = Number(slipObj?.activePrice);
        let backLoss = Number(
          (Number(slipObj?.rt) / 100) * Number(slipObj?.activePrice)
        );
        if (Math.abs(bookmaker[0]?.position) > 0) {
          setBookmakerSelections(
            bookmaker?.map((rl) => {
              if (rl.selectionID == slipObj?.selectionId) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              }
            })
          );
        } else {
          setBookmakerSelections(
            bookmaker?.map((rl) => {
              if (rl.selectionID == slipObj?.selectionId) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };
  const handelSetBookmakerPrice = (item, data) => {
    const filteredData = {
      ...data,
      price: priceList,
      activePrice: item?.value,
      selectionId: data?.selectionId,
      teamName: data?.teamName,
      betType: data?.betType,
    };

    setBookMakerBetSlipObj(filteredData);
    handelBookmakerPositions(filteredData);
  };
  const handelChangeBookmakerAmount = (event, data) => {
    const temp = bookmakerBetSlipObj;
    temp.activePrice = event.target.value ? +event.target.value : "";
    setBookMakerBetSlipObj((prevState) => temp);
    handelBookmakerPositions(temp);
  };
  const handelClearBookmakerBetSlip = () => {
    setBookMakerBetSlipObj({});
    setBookmakerSelections((current) => {
      return current.map((res) => {
        return { ...res, newPosition: 0 };
      });
    });
  };
  const handelAcceptAnyOddsCheck = (e) => {
    setAcceptAnyOddsCheckbox(e.target.checked ? 1 : 0);
    const temp = bookmakerBetSlipObj;
    temp.acceptAny = e.target.checked ? 1 : 0;
    setBookMakerBetSlipObj((prevState) => temp);
  };
  const handelPlaceBetBookmaker = async (filter_odds, selection, betType) => {
    const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
    const resultData = activeOneClick
      ? {
          eventId: eventId,
          marketId: details?.bookmakerMarketId,
          selectionId: selection.selectionID,
          betPlaceTime: date,
          teamName: selection.runnerName,
          bhav: filter_odds?.rt,
          amount: activeOneClickAmount,
          betType: betType,
          type: params.sportType,
          exceptAny: filter_odds?.exceptAny,
        }
      : {
          eventId: eventId,
          marketId: details?.bookmakerMarketId,
          selectionId: bookmakerBetSlipObj?.selectionId?.substring(1),
          betPlaceTime: date,
          teamName: bookmakerBetSlipObj?.teamName,
          bhav: bookmakerBetSlipObj?.rt,
          type: params.sportType,
          amount: bookmakerBetSlipObj?.activePrice,
          betType: bookmakerBetSlipObj?.betType,
          exceptAny: bookmakerBetSlipObj?.exceptAny,
          betPlaceType: "bookmaker",
        };
    if (user) {
      if (activeOneClick) {
        if (!activeOneClickAmount) {
          toast.error("Please select one click bet amount");
          return false;
        }
      }
      setLoaderBookmakerBet(true);
      const { data: response_users2 } = await apiPost(
        apiPath.beforeBetPlace,
        resultData
      );
      if (response_users2.success) {
        const { status, data: response_users } = await apiPost(
          apiPath.bookmakerBet,
          resultData
        );
        if (status === 200) {
          if (response_users.success) {
            getCurrentBets();
            getCoins();
            // balanceFunctionMain();
            setLoaderBookmakerBet(false);
            toast.success(response_users.message);
            updatePositionAfterBetPlace("bookmaker");
            setBookMakerBetSlipObj({});
          } else {
            toast.error(response_users.message);
            setLoaderBookmakerBet(false);
            handelClearBookmakerBetSlip();
          }
        }
      } else {
        toast.error(response_users2.message);
        setLoaderBookmakerBet(false);
        handelClearBookmakerBetSlip();
      }
    } else {
      setShowLogin(true);
    }
  };
  //******************************* FANCY BET FUNCTIONS ****************************************************//
  const toggleFancyAndPremium = (item) => {
    if (item == "premium") {
      setAcceptAnyOddsPreminumFancyCheckbox(0);
      setPreminumFancyAmount(0);
      setSelectPreminumFancy({});
      setAccordian("");
    }
    setShowFancy(item);
  };
  const handelShowFancyBetSlip = (filter_odds, selection, betType) => {
    const filteredData = {
      ...filter_odds,
      price: priceList,
      activePrice: null,
      selectionId: selection.selectionId,
      fancyName: selection.fancyName,
      betType: betType,
      centralizedId: selection?.centralizedId,
      eventId: selection?.eventId,
      marketId:
        details?.fancyType === "diamond"
          ? selection?.jsonData?.mid
          : selection?.marketId,
      runnerName:
        details?.fancyType === "diamond"
          ? selection?.jsonData?.runnerName
          : selection?.jsonData[0]?.runnerName,
    };
    setFancyBetSlipObj(filteredData);
    if (user && loginUserDetailData?.defaultStake) {
      filteredData.activePrice = loginUserDetailData?.defaultStake;
      // handelFancyPositions(filteredData);
    }
  };
  const handelFancyPositions = (slipObj) => {
    let newselection = fancyList?.map((data) => {
      data.position = 0;
      return data;
    });
    setFancyList(newselection);
    if (slipObj?.activePrice >= 0) {
      if (slipObj.ib) {
        let currentPosition = fancyList.find(
          (rl) => rl.selectionId == slipObj.selectionId
        );
        if (
          Math.abs(currentPosition.position) !== 0 &&
          !isEmpty(currentPosition.type)
        ) {
          let backLoss = slipObj?.activePrice + currentPosition.position;
          currentPosition.newPosition = backLoss;
          const result = fancyList.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition.newPosition
                ? parseFloat(currentPosition.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          setFancyCategoryTabArray(result);
          setFancyList(result);
        } else {
          let backLoss = slipObj?.activePrice;
          currentPosition.newPosition = backLoss;
          const result = fancyList.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition.newPosition
                ? parseFloat(currentPosition.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          setFancyCategoryTabArray(result);
          setFancyList(result);
        }
      } else {
        let currentPosition = fancyList.find(
          (rl) => rl.selectionId == slipObj.selectionId
        );
        if (
          !isNaN(Math.abs(currentPosition.position)) &&
          !isEmpty(currentPosition.type)
        ) {
          let backProfit = (slipObj?.activePrice * slipObj?.pt) / 100;
          currentPosition.newPosition = backProfit + currentPosition.position;
          const result = fancyList.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition.newPosition
                ? parseFloat(currentPosition.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          setFancyCategoryTabArray(result);
          setFancyList(result);
        } else {
          let backProfit = (slipObj?.activePrice * slipObj?.pt) / 100;
          currentPosition.newPosition = backProfit;
          const result = fancyList.map((data) => {
            if (data.selectionId == currentPosition.selectionId) {
              data.newPosition = currentPosition.newPosition
                ? parseFloat(currentPosition.newPosition).toFixed(2)
                : null;
            }
            return data;
          });
          setFancyCategoryTabArray(result);
          setFancyList(result);
        }
      }

      // if (slipObj.ib) {
      //   let currentPosition = fancyList.find(
      //     (rl) => rl.selectionId == slipObj.selectionId
      //   );
      //   let backProfit = (slipObj?.activePrice * slipObj?.pt) / 100;
      //   currentPosition.position = currentPosition.position + backProfit;
      //   const result = fancyList.map((data) => {
      //     if (data.selectionId == currentPosition.selectionId) {
      //       data.position = currentPosition.position
      //         ? parseFloat(currentPosition.position).toFixed(2)
      //         : null;
      //     }
      //     return data;
      //   });
      //   setFancyCategoryTabArray(result);
      //   setFancyList(result);
      // } else {
      //   let currentPosition = fancyList.find(
      //     (rl) => rl.selectionId == slipObj.selectionId
      //   );

      //   let backLoss = (slipObj?.activePrice * slipObj?.pt) / 100;
      //   currentPosition.position = currentPosition.position - backLoss;
      //   const result = fancyList.map((data) => {
      //     if (data.selectionId == currentPosition.selectionId) {
      //       data.position = currentPosition.position
      //         ? parseFloat(currentPosition.position).toFixed(2)
      //         : null;
      //     }

      //     return data;
      //   });
      //   setFancyCategoryTabArray(result);
      //   setFancyList(result);
      // }
    }
  };
  const handelSetFancyPrice = (item, data) => {
    const filteredData = {
      ...data,
      price: priceList,
      activePrice: item?.value,
      selectionId: data?.selectionId,
      fancyName: data.fancyName,
      betType: data.betType,
      centralizedId: data?.centralizedId,
      eventId: data?.eventId,
      marketId: data?.marketId,
      runnerName: data?.runnerName,
    };
    setFancyBetSlipObj(filteredData);
    // handelFancyPositions(filteredData);
  };
  const handelChangeFancyAmount = (event, data) => {
    const temp = fancyBetSlipObj;
    temp.activePrice = event.target.value ? +event.target.value : "";
    setFancyBetSlipObj((prevState) => temp);
    // handelFancyPositions(temp);
  };
  const handelClearFancyBetSlip = () => {
    setFancyBetSlipObj({});
  };
  const handelAcceptAnyOddsFancyCheck = (e) => {
    setAcceptAnyOddsFancyCheckbox(e.target.checked ? 1 : 0);
    const temp = fancyBetSlipObj;
    temp.acceptAny = e.target.checked ? 1 : 0;
    setFancyBetSlipObj((prevState) => temp);
  };
  const handelPlaceFancyBet = async (filter_odds, selection, betType) => {
    const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
    const resultData = activeOneClick
      ? {
          eventId: selection?.eventId,
          marketId: selection?.marketId,
          centralizedId: selection?.centralizedId,
          selectionId: selection.selectionId,
          betPlaceTime: date,
          fancyName: selection.fancyName,
          bhav: filter_odds?.pt,
          amount: activeOneClickAmount,
          betType: betType == "back" ? "Yes" : "No",
          exceptAny: acceptAnyOddsFancyCheckbox,
          betRun: filter_odds?.rt,
          runnerName:
            details?.fancyType === "diamond"
              ? selection?.jsonData?.runnerName
              : selection?.jsonData[0]?.runnerName,
        }
      : {
          eventId: fancyBetSlipObj?.eventId,
          marketId: fancyBetSlipObj?.marketId,
          centralizedId: fancyBetSlipObj?.centralizedId,
          selectionId: fancyBetSlipObj?.selectionId,
          betPlaceTime: date,
          fancyName: fancyBetSlipObj?.fancyName,
          bhav: fancyBetSlipObj?.pt,
          amount: fancyBetSlipObj?.activePrice,
          betType: fancyBetSlipObj?.betType == "back" ? "Yes" : "No",
          exceptAny: acceptAnyOddsFancyCheckbox,
          betRun: fancyBetSlipObj?.rt,
          runnerName: fancyBetSlipObj?.runnerName,
          betPlaceType: details?.fancyType === "diamond" ? "fancy" : "",
        };

    if (user) {
      if (activeOneClick) {
        if (!activeOneClickAmount) {
          toast.error("Please select one click bet amount");
          return false;
        }
      }
      setLoaderFancyBet(true);
      const { data: response_users2 } = await apiPost(
        apiPath.beforeBetPlace,
        resultData
      );
      if (response_users2.success) {
        let api =
          details.fancyType == "diamond"
            ? apiPath.diamondfancyBetPlace
            : apiPath.fancyBetPlace;
        const { status, data: response_users } = await apiPost(api, resultData);
        if (status === 200) {
          if (response_users.success) {
            // setRefreshAmountSocket(true);
            getCoins();
            setLoaderFancyBet(false);
            getFancyBetPosition();
            // balanceFunctionMain();
            getCurrentBets();
            toast.success(response_users.message);
            setFancyBetSlipObj({});
          } else {
            // setRefreshAmountSocket(false);
            toast.error(response_users.message);
            setLoaderFancyBet(false);
          }
        }
      } else {
        // setRefreshAmountSocket(false);
        toast.error(response_users2.message);
        setLoaderFancyBet(false);
      }
    } else {
      // setRefreshAmountSocket(false);
      setShowLogin(true);
    }
  };
  const handelPlacePreminumFancyBet = async (selectPreminumFancy) => {
    const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
    const resultData = {
      eventId: getMatchId,
      marketId: details.marketId,
      fancySelectionId: selectPreminumFancy?.newRunner?.id,
      selectionId: selectPreminumFancy.newRunner.marketId,
      betPlaceTime: date,
      runnerName: selectPreminumFancy?.newRunner?.selectionName,
      fancyName: selectPreminumFancy?.marketName,
      bhav: selectPreminumFancy?.newRunner?.odds,
      amount: activeOneClick ? activeOneClickAmount : preminumfancyAmount,
      betType: "back",
      exceptAny: acceptAnyOddsPreminumFancyCheckbox,
      apiSiteSpecifier: selectPreminumFancy?.apiSiteSpecifier,
      apiSiteSelectionId: selectPreminumFancy?.newRunner.apiSiteSelectionId,
      betPlaceType: "premiumFancy",
    };
    if (user) {
      if (activeOneClick) {
        if (!activeOneClickAmount) {
          toast.error("Please select one click bet amount");
          return false;
        }
      }
      setLoaderFancyBet(true);
      const { data: response_users2 } = await apiPost(
        apiPath.beforeBetPlace,
        resultData
      );
      if (response_users2.success) {
        const { status, data: response_users } = await apiPost(
          apiPath.preminumFancyBets,
          resultData
        );
        if (status === 200) {
          if (response_users.success) {
            getCurrentBets();
            setSelectPreminumFancy({});
            setPreminumFancyAmount(0);
            setLoaderFancyBet(false);
            toast.success(response_users.message);
            getPremiumFancyBetPosition();
            setFancyBetSlipObj({});
          } else {
            toast.error(response_users.message);
            setLoaderFancyBet(false);
          }
        }
      } else {
        setSelectPreminumFancy({});
        setPreminumFancyAmount(0);
        toast.error(response_users2.message);
        setLoaderFancyBet(false);
      }
    } else {
      setSelectPreminumFancy({});
      setPreminumFancyAmount(0);
      setShowLogin(true);
    }
  };
  //****** BET FAIR BACK DATA ******//
  const renderBackOdds = (selection, odds, marketName, marketId, betFairMs) => {
    const the_odds = odds;
    const filter_odds = the_odds?.filter(
      (todd) => todd?.ri === selection?.SelectionId
    );
    return (
      <>
        <td className="relative_for p-0">
          {betFairMs != 1 ? (
            <div class="suspend">
              <span>
                {betFairMs == 9
                  ? "Ball Running"
                  : betFairMs == 2
                  ? "In Active"
                  : betFairMs == 3
                  ? "Suspend"
                  : betFairMs == 4
                  ? "Closed"
                  : "Suspend"}
              </span>
            </div>
          ) : (
            <table>
              <tr>
                <td
                  value={filter_odds[2]?.rt}
                  id={"back_odds_" + filter_odds[2]?.rt}
                  className={`back-3 bet-cursor mobile_hide border-0 ${
                    document
                      .getElementById("back_odds_" + filter_odds[2]?.rt)
                      ?.getAttribute("value") != filter_odds[2]?.rt &&
                    filter_odds[2]?.rt &&
                    "spark"
                  }`}
                  onClick={() =>
                    filter_odds[2]?.rt == 0 ||
                    filter_odds.length === 0 ||
                    details?.matchOdds == "off" ||
                    details?.status !== "in_play" ||
                    details?.matchSetting?.find(
                      (res) => res?.type === "betFaire"
                    )?.oddsLimit <= filter_odds[2]?.rt ||
                    loginUserDetailData?.matchSetting?.find(
                      (res) =>
                        res?.type === "betFaire" &&
                        res?.sportType === details?.gameType
                    )?.oddsLimit <= filter_odds[2]?.rt
                      ? null
                      : handelShowBetSlip(
                          filter_odds[2],
                          selection,
                          "back",
                          marketName,
                          marketId
                        )
                  }
                  style={
                    filter_odds[2]?.rt == 0 ||
                    filter_odds.length === 0 ||
                    details?.matchOdds == "off" ||
                    details?.status !== "in_play" ||
                    details?.matchSetting?.find(
                      (res) => res?.type === "betFaire"
                    )?.oddsLimit <= filter_odds[2]?.rt ||
                    loginUserDetailData?.matchSetting?.find(
                      (res) =>
                        res?.type === "betFaire" &&
                        res?.sportType === details?.gameType
                    )?.oddsLimit <= filter_odds[2]?.rt
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <a>
                    {filter_odds[2]?.rt || null}
                    <span>{filter_odds[2]?.rt > 0 && filter_odds[2]?.bv}</span>
                  </a>
                </td>
                <td
                  value={filter_odds[1]?.rt}
                  id={"back_odds_" + filter_odds[1]?.rt}
                  className={`back-2 bet-cursor mobile_hide border-0 ${
                    document
                      .getElementById("back_odds_" + filter_odds[1]?.rt)
                      ?.getAttribute("value") != filter_odds[1]?.rt &&
                    filter_odds[1]?.rt &&
                    "spark"
                  }`}
                  onClick={() =>
                    filter_odds[1]?.rt == 0 ||
                    filter_odds.length === 0 ||
                    details?.matchOdds == "off" ||
                    details?.status !== "in_play" ||
                    details?.matchSetting?.find(
                      (res) => res?.type === "betFaire"
                    )?.oddsLimit <= filter_odds[1]?.rt ||
                    loginUserDetailData?.matchSetting?.find(
                      (res) =>
                        res?.type === "betFaire" &&
                        res?.sportType === details?.gameType
                    )?.oddsLimit <= filter_odds[1]?.rt
                      ? null
                      : handelShowBetSlip(
                          filter_odds[1],
                          selection,
                          "back",
                          marketName,
                          marketId
                        )
                  }
                  style={
                    filter_odds[1]?.rt == 0 ||
                    filter_odds.length === 0 ||
                    details?.matchOdds == "off" ||
                    details?.status !== "in_play" ||
                    details?.matchSetting?.find(
                      (res) => res?.type === "betFaire"
                    )?.oddsLimit <= filter_odds[1]?.rt ||
                    loginUserDetailData?.matchSetting?.find(
                      (res) =>
                        res?.type === "betFaire" &&
                        res?.sportType === details?.gameType
                    )?.oddsLimit <= filter_odds[1]?.rt
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <a>
                    {filter_odds[1]?.rt || null}
                    <span>{filter_odds[1]?.rt > 0 && filter_odds[1]?.bv}</span>
                  </a>
                </td>
                {/* {console.log("document.getElementById", document.getElementById("back_odds_" + filter_odds[0]?.bv)?.getAttribute('value'))} */}
                <td
                  value={filter_odds[0]?.rt}
                  id={"back_odds_" + filter_odds[0]?.rt}
                  className={`back-1s bet-cursor border-0 ${
                    document
                      .getElementById("back_odds_" + filter_odds[0]?.rt)
                      ?.getAttribute("value") != filter_odds[0]?.rt &&
                    filter_odds[0]?.rt &&
                    "spark"
                  }`}
                  onClick={() =>
                    filter_odds[0]?.rt == 0 ||
                    filter_odds.length === 0 ||
                    details?.matchOdds == "off" ||
                    details?.status !== "in_play" ||
                    details?.matchSetting?.find(
                      (res) => res?.type === "betFaire"
                    )?.oddsLimit <= filter_odds[0]?.rt ||
                    loginUserDetailData?.matchSetting?.find(
                      (res) =>
                        res?.type === "betFaire" &&
                        res?.sportType === details?.gameType
                    )?.oddsLimit <= filter_odds[0]?.rt
                      ? null
                      : handelShowBetSlip(
                          filter_odds[0],
                          selection,
                          "back",
                          marketName,
                          marketId
                        )
                  }
                  style={
                    filter_odds[0]?.rt == 0 ||
                    filter_odds.length === 0 ||
                    details?.matchOdds == "off" ||
                    details?.status !== "in_play" ||
                    details?.matchSetting?.find(
                      (res) => res?.type === "betFaire"
                    )?.oddsLimit <= filter_odds[0]?.rt ||
                    loginUserDetailData?.matchSetting?.find(
                      (res) =>
                        res?.type === "betFaire" &&
                        res?.sportType === details?.gameType
                    )?.oddsLimit <= filter_odds[0]?.rt
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <a>
                    {filter_odds[0]?.rt || null}
                    <span>{filter_odds[0]?.rt > 0 && filter_odds[0]?.bv}</span>
                  </a>
                </td>
              </tr>
            </table>
          )}
        </td>
      </>
    );
  };
  //****** BET FAIR LAY DATA ******//
  const renderLayOdds = (selection, odds, marketName, marketId, betFairMs) => {
    const the_odds = odds;
    const filter_odds = the_odds.filter(
      (todd) => todd.ri === selection.SelectionId
    );
    return (
      <>
        <td className="relative_for p-0">
          {betFairMs != 1 ? (
            <div class="suspend">
              <span>
                {betFairMs == 9
                  ? "Ball Running"
                  : betFairMs == 2
                  ? "In Active"
                  : betFairMs == 3
                  ? "Suspend"
                  : betFairMs == 4
                  ? "Closed"
                  : "Suspend"}
              </span>
            </div>
          ) : (
            <table>
              <tr>
                <td
                  value={filter_odds[0]?.rt}
                  id={"lay_odds_" + filter_odds[0]?.rt}
                  className={`lay-1 bet-cursor border-0 ${
                    document
                      .getElementById("lay_odds_" + filter_odds[0]?.rt)
                      ?.getAttribute("value") != filter_odds[0]?.rt &&
                    filter_odds[0]?.rt &&
                    "spark"
                  }`}
                  onClick={() =>
                    filter_odds?.length === 0 ||
                    details?.matchOdds == "off" ||
                    details?.status !== "in_play" ||
                    details?.matchSetting?.find(
                      (res) => res?.type === "betFaire"
                    )?.oddsLimit <= filter_odds[0]?.rt ||
                    loginUserDetailData?.matchSetting?.find(
                      (res) =>
                        res?.type === "betFaire" &&
                        res?.sportType === details?.gameType
                    )?.oddsLimit <= filter_odds[0]?.rt ||
                    filter_odds[0]?.rt == 0
                      ? null
                      : handelShowBetSlip(
                          filter_odds[0],
                          selection,
                          "lay",
                          marketName,
                          marketId
                        )
                  }
                  style={
                    filter_odds?.length === 0 ||
                    details?.matchOdds == "off" ||
                    details?.status !== "in_play" ||
                    details?.matchSetting?.find(
                      (res) => res?.type === "betFaire"
                    )?.oddsLimit <= filter_odds[0]?.rt ||
                    loginUserDetailData?.matchSetting?.find(
                      (res) =>
                        res?.type === "betFaire" &&
                        res?.sportType === details?.gameType
                    )?.oddsLimit <= filter_odds[0]?.rt ||
                    filter_odds[0]?.rt == 0
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <a>
                    {filter_odds[0]?.rt || null}
                    <span>{filter_odds[0]?.bv || null}</span>
                  </a>
                </td>
                <td
                  value={filter_odds[1]?.rt}
                  id={"lay_odds_" + filter_odds[1]?.rt}
                  className={`lay-2 bet-cursor mobile_hide border-0 ${
                    document
                      .getElementById("lay_odds_" + filter_odds[1]?.rt)
                      ?.getAttribute("value") != filter_odds[1]?.rt &&
                    filter_odds[1]?.rt &&
                    "spark"
                  }`}
                  onClick={() =>
                    filter_odds.length === 0 ||
                    details?.matchOdds == "off" ||
                    details?.status !== "in_play" ||
                    details?.matchSetting?.find(
                      (res) => res?.type === "betFaire"
                    )?.oddsLimit <= filter_odds[1]?.rt ||
                    loginUserDetailData?.matchSetting?.find(
                      (res) =>
                        res?.type === "betFaire" &&
                        res?.sportType === details?.gameType
                    )?.oddsLimit <= filter_odds[1]?.rt ||
                    filter_odds[1]?.rt == 0
                      ? null
                      : handelShowBetSlip(
                          filter_odds[1],
                          selection,
                          "lay",
                          marketName,
                          marketId
                        )
                  }
                  style={
                    filter_odds.length === 0 ||
                    details?.matchOdds == "off" ||
                    details?.status !== "in_play" ||
                    details?.matchSetting?.find(
                      (res) => res?.type === "betFaire"
                    )?.oddsLimit <= filter_odds[1]?.rt ||
                    loginUserDetailData?.matchSetting?.find(
                      (res) =>
                        res?.type === "betFaire" &&
                        res?.sportType === details?.gameType
                    )?.oddsLimit <= filter_odds[1]?.rt ||
                    filter_odds[1]?.rt == 0
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <a>
                    {filter_odds[1]?.rt || null}
                    <span>{filter_odds[1]?.bv || null}</span>
                  </a>
                </td>
                <td
                  value={filter_odds[2]?.rt}
                  id={"lay_odds_" + filter_odds[2]?.rt}
                  className={`lay-3 bet-cursor mobile_hide border-0 ${
                    document
                      .getElementById("lay_odds_" + filter_odds[2]?.rt)
                      ?.getAttribute("value") != filter_odds[2]?.rt &&
                    filter_odds[2]?.rt &&
                    "spark"
                  }`}
                  onClick={() =>
                    filter_odds.length === 0 ||
                    details?.matchOdds == "off" ||
                    details?.status !== "in_play" ||
                    details?.matchSetting?.find(
                      (res) => res?.type === "betFaire"
                    )?.oddsLimit <= filter_odds[2]?.rt ||
                    loginUserDetailData?.matchSetting?.find(
                      (res) =>
                        res?.type === "betFaire" &&
                        res?.sportType === details?.gameType
                    )?.oddsLimit <= filter_odds[2]?.rt ||
                    filter_odds[2]?.rt == 0
                      ? null
                      : handelShowBetSlip(
                          filter_odds[2],
                          selection,
                          "lay",
                          marketName,
                          marketId
                        )
                  }
                  style={
                    filter_odds.length === 0 ||
                    details?.matchOdds == "off" ||
                    details?.status !== "in_play" ||
                    details?.matchSetting?.find(
                      (res) => res?.type === "betFaire"
                    )?.oddsLimit <= filter_odds[2]?.rt ||
                    loginUserDetailData?.matchSetting?.find(
                      (res) =>
                        res?.type === "betFaire" &&
                        res?.sportType === details?.gameType
                    )?.oddsLimit <= filter_odds[2]?.rt ||
                    filter_odds[2]?.rt == 0
                      ? { cursor: "not-allowed" }
                      : { cursor: "pointer" }
                  }
                >
                  <a>
                    {filter_odds[2]?.rt || null}
                    <span>{filter_odds[2]?.bv || null}</span>
                  </a>
                </td>
              </tr>
            </table>
          )}
        </td>
      </>
    );
  };
  //****** BET FAIR BACK (Blue) BET SLIP CARDS ******//
  const renderBackBetSlip = (backBetSlip) => {
    return (
      <>
        {backBetSlip.length !== 0 && (
          <ul className="slip-head d-flex">
            <li className="col-bet">Back (Bet For)</li>
            <li id="oddsHeader" className="col-odd">
              Odds
            </li>
            <li id="runsHeader" className="col-odd">
              Unit:Runs
            </li>
            <li className="col-stake">Stake</li>
            <li className="col-profit text-end">Profit</li>
          </ul>
        )}
        {backBetSlip &&
          backBetSlip.length !== 0 &&
          backBetSlip.map((data, index) => {
            return (
              <div className="team_list_wrapper blue_bg_tuch" key={index + 1}>
                <h4>
                  <img
                    className="icon-in_play"
                    src={transparentGif}
                    alt="No_Image"
                  />{" "}
                  {selections[0]?.RunnerName} v {selections[1]?.RunnerName}
                </h4>
                <div className="match_oddds">
                  <span className="d-flex align-center justify-content-center">
                    <i
                      className="fa fa-window-close bet-slip-cross-icon"
                      onClick={() => {
                        handelRemoveBackBetSlip(index);
                        if (
                          layBetSlipArray?.length + backBetSlipArray?.length ==
                          1
                        ) {
                          handelClearAllBet();
                        }
                      }}
                    ></i>
                    <h5>
                      {data?.teamName}
                      <small>Match Odds</small>
                    </h5>
                  </span>
                  <div className="up_down_rate">
                    <ul>
                      <li>
                        <input
                          type="text"
                          value={data?.rt}
                          disabled
                          className="bet-slip-first-input"
                        />
                      </li>
                      <li>
                        <input
                          type="number"
                          value={data?.activePrice ? data?.activePrice : ""}
                          name="back-tik"
                          onChange={(e) => handelChangeBackAll(e, index, data)}
                          onKeyPress={(e) => {
                            isInputNumber(e);
                            if (e.target.value.length > 6) {
                              e.preventDefault();
                            }
                          }}
                          max={1500000}
                          style={
                            !data?.activePrice
                              ? {
                                  border: "1px solid red",
                                  cursor: "pointer",
                                  borderRadius: "4px",
                                }
                              : {}
                          }
                          title={!data?.activePrice ? "Enter amount" : ""}
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="odds_value">{`${
                    data?.profitLiability ? "$" + data?.profitLiability : ""
                  }`}</div>
                </div>

                <div className="preset_value">
                  <ul>
                    {data?.price.map((money) => {
                      return (
                        <li key={money?.id}>
                          <button
                            className="price-button-pay-slip"
                            onClick={() => handelSetPrice(money, data)}
                            style={
                              data.activePrice === money.value
                                ? {
                                    background: "#444444",
                                    color: "#f8d61c ",
                                    cursor: "not-allowed",
                                  }
                                : {}
                            }
                            disabled={data.activePrice === money.value}
                          >
                            {money?.value}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="min-bets">
                  Min bet:
                  {data?.rangeObject?.minAmount
                    ? data?.rangeObject?.minAmount
                    : 10}
                </div>
              </div>
            );
          })}
      </>
    );
  };
  //****** BET FAIR LAY (Pink) BET SLIP CARDS ******//
  const renderLayBetSlip = () => {
    return (
      <>
        {layBetSlipArray.length !== 0 && (
          <ul className="slip-head d-flex">
            <li className="col-bet">Lay (Bet Against)</li>
            <li id="oddsHeader" className="col-odd">
              Odds
            </li>
            <li id="runsHeader" className="col-odd">
              Stake
            </li>
            <li className="col-stake">Liability</li>
          </ul>
        )}

        {layBetSlipArray &&
          layBetSlipArray.length !== 0 &&
          layBetSlipArray.map((item, index) => {
            return (
              <div className="team_list_wrapper lay_bg_tuch" key={index + 1}>
                <h4>
                  <img
                    className="icon-in_play"
                    src={transparentGif}
                    alt="No_Image"
                  />{" "}
                  {selections[0]?.RunnerName} v {selections[1]?.RunnerName}
                </h4>
                <div className="match_oddds">
                  <span className="d-flex align-center justify-content-center">
                    <i
                      className="fa fa-window-close bet-slip-cross-icon"
                      onClick={() => {
                        handelRemoveLayBetSlip(index);
                        if (
                          layBetSlipArray?.length + backBetSlipArray?.length ==
                          1
                        ) {
                          handelClearAllBet();
                        }
                      }}
                    ></i>
                    <h5>
                      {item?.teamName}
                      <small>Match Odds</small>
                    </h5>
                  </span>
                  <div className="up_down_rate">
                    <ul>
                      <li className="bill-slip-rt">
                        <input
                          type="text"
                          value={item?.rt}
                          disabled
                          className="bet-slip-first-input"
                        />{" "}
                      </li>
                      <li>
                        <input
                          type="number"
                          value={item?.activePrice ? item?.activePrice : ""}
                          onChange={(e) => handelChangeLayAll(e, index, item)}
                          onKeyPress={(e) => {
                            isInputNumber(e);
                            if (e.target.value.length > 6) {
                              e.preventDefault();
                            }
                          }}
                          max={1500000}
                          style={
                            !item?.activePrice
                              ? { border: "1px solid red", borderRadius: "4px" }
                              : {}
                          }
                          title={!item?.activePrice ? "Enter amount" : ""}
                        />{" "}
                      </li>
                    </ul>
                  </div>
                  <div className="odds_value">
                    {`${
                      item?.activePrice && item?.rt
                        ? "$" + ((item?.rt - 1) * item?.activePrice)?.toFixed(2)
                        : ""
                    }`}{" "}
                  </div>
                </div>

                <div className="preset_value">
                  <ul>
                    {item?.price.map((money) => {
                      return (
                        <li key={money?.id}>
                          <button
                            className="price-button-pay-slip"
                            onClick={() => handelSetPrice(money, item)}
                            style={
                              item.activePrice === money.value
                                ? {
                                    background: "#444444",
                                    color: "#f8d61c ",
                                    cursor: "not-allowed",
                                  }
                                : {}
                            }
                            disabled={item.activePrice === money.value}
                          >
                            {money?.value}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="min-bets">Min bet:10</div>
              </div>
            );
          })}
      </>
    );
  };
  //******************************* BOOKMAKER  UI ****************************************************//

  //****** BOOKMAKER BACK DATA ******//
  const renderBookmarkBack = (selection) => {
    const the_odds = bookmakerBackOdds;
    const filter_odds = the_odds
      .filter((todd) => todd.name == selection.runnerName)
      .sort((a, b) => {
        return a - b;
      });

    return (
      <Fragment>
        <td className="pe-0 " colSpan="3" style={{ position: "relative" }}>
          <dl className="back-gradient">
            <dd
              id="back_3"
              className="mobile_hide"
              onClick={() =>
                !filter_odds[2]?.rt ||
                details?.bookMaker == "off" ||
                details?.matchSetting?.find((res) => res?.type === "bookmaker")
                  ?.oddsLimit <= filter_odds[2]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "bookmaker" &&
                    res?.sportType === details?.gameType
                )?.oddsLimit <= filter_odds[2]?.rt
                  ? null
                  : activeOneClick
                  ? handelPlaceBetBookmaker(filter_odds[2], selection, "back")
                  : handelShowBookmakerBetSlip(
                      filter_odds[2],
                      selection,
                      "back"
                    )
              }
              style={
                !filter_odds[2]?.rt ||
                details?.bookMaker == "off" ||
                details?.matchSetting?.find((res) => res?.type === "bookmaker")
                  ?.oddsLimit <= filter_odds[2]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "bookmaker" &&
                    res?.sportType === details?.gameType
                )?.oddsLimit <= filter_odds[2]?.rt
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <p> {filter_odds[2]?.rt || null} </p>
            </dd>
            <dd
              id="back_2"
              onClick={() =>
                !filter_odds[1]?.rt ||
                details?.bookMaker == "off" ||
                details?.matchSetting?.find((res) => res?.type === "bookmaker")
                  ?.oddsLimit <= filter_odds[1]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "bookmaker" &&
                    res?.sportType === details?.gameType
                )?.oddsLimit <= filter_odds[1]?.rt
                  ? null
                  : activeOneClick
                  ? handelPlaceBetBookmaker(filter_odds[1], selection, "back")
                  : handelShowBookmakerBetSlip(
                      filter_odds[1],
                      selection,
                      "back"
                    )
              }
              style={
                !filter_odds[1]?.rt ||
                details?.bookMaker == "off" ||
                details?.matchSetting?.find((res) => res?.type === "bookmaker")
                  ?.oddsLimit <= filter_odds[1]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "bookmaker" &&
                    res?.sportType === details?.gameType
                )?.oddsLimit <= filter_odds[1]?.rt
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <p> {filter_odds[1]?.rt || null}</p>
            </dd>
            <dd
              id="back_1"
              className=""
              onClick={() =>
                !filter_odds[0]?.rt ||
                details?.bookMaker == "off" ||
                details?.matchSetting?.find((res) => res?.type === "bookmaker")
                  ?.oddsLimit <= filter_odds[0]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "bookmaker" &&
                    res?.sportType === details?.gameType
                )?.oddsLimit <= filter_odds[0]?.rt
                  ? null
                  : activeOneClick
                  ? handelPlaceBetBookmaker(filter_odds[0], selection, "back")
                  : handelShowBookmakerBetSlip(
                      filter_odds[0],
                      selection,
                      "back"
                    )
              }
              style={
                !filter_odds[0]?.rt ||
                details?.bookMaker == "off" ||
                details?.matchSetting?.find((res) => res?.type === "bookmaker")
                  ?.oddsLimit <= filter_odds[0]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "bookmaker" &&
                    res?.sportType === details?.gameType
                )?.oddsLimit <= filter_odds[0]?.rt
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <a> {filter_odds[0]?.rt || null}</a>
            </dd>
          </dl>
        </td>
      </Fragment>
    );
  };
  //****** BOOKMAKER LAY DATA ******//
  const renderBookmarkLay = (selection) => {
    const the_odds = bookmakerLayOdds;
    const filter_odds = the_odds
      .filter((todd) => todd.name == selection.runnerName)
      .sort((a, b) => {
        return a - b;
      });

    return (
      <Fragment>
        <td className="ps-0" colSpan="3" style={{ position: "relative" }}>
          <dl className="lay-gradient">
            <dd
              id="lay_1"
              className=""
              onClick={() =>
                !filter_odds[0]?.rt ||
                details?.bookMaker == "off" ||
                details?.matchSetting?.find((res) => res?.type === "bookmaker")
                  ?.oddsLimit <= filter_odds[0]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "bookmaker" &&
                    res?.sportType === details?.gameType
                )?.oddsLimit <= filter_odds[0]?.rt
                  ? null
                  : activeOneClick
                  ? handelPlaceBetBookmaker(filter_odds[0], selection, "lay")
                  : handelShowBookmakerBetSlip(filter_odds[0], selection, "lay")
              }
              style={
                !filter_odds[0]?.rt ||
                details?.bookMaker == "off" ||
                details?.matchSetting?.find((res) => res?.type === "bookmaker")
                  ?.oddsLimit <= filter_odds[0]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "bookmaker" &&
                    res?.sportType === details?.gameType
                )?.oddsLimit <= filter_odds[0]?.rt
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <a>{filter_odds[0]?.rt || null}</a>
            </dd>
            <dd
              id="lay_2"
              onClick={() =>
                !filter_odds[1]?.rt ||
                details?.bookMaker == "off" ||
                details?.matchSetting?.find((res) => res?.type === "bookmaker")
                  ?.oddsLimit <= filter_odds[1]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "bookmaker" &&
                    res?.sportType === details?.gameType
                )?.oddsLimit <= filter_odds[1]?.rt
                  ? null
                  : activeOneClick
                  ? handelPlaceBetBookmaker(filter_odds[1], selection, "lay")
                  : handelShowBookmakerBetSlip(filter_odds[1], selection, "lay")
              }
              style={
                !filter_odds[1]?.rt ||
                details?.bookMaker == "off" ||
                details?.matchSetting?.find((res) => res?.type === "bookmaker")
                  ?.oddsLimit <= filter_odds[1]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "bookmaker" &&
                    res?.sportType === details?.gameType
                )?.oddsLimit <= filter_odds[1]?.rt
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <p>{filter_odds[1]?.rt || null}</p>
            </dd>
            <dd
              id="lay_3"
              className="mobile_hide"
              onClick={() =>
                !filter_odds[2]?.rt ||
                details?.bookMaker == "off" ||
                details?.matchSetting?.find((res) => res?.type === "bookmaker")
                  ?.oddsLimit <= filter_odds[2]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "bookmaker" &&
                    res?.sportType === details?.gameType
                )?.oddsLimit <= filter_odds[2]?.rt
                  ? null
                  : activeOneClick
                  ? handelPlaceBetBookmaker(filter_odds[2], selection, "lay")
                  : handelShowBookmakerBetSlip(filter_odds[2], selection, "lay")
              }
              style={
                !filter_odds[2]?.rt ||
                details?.bookMaker == "off" ||
                details?.matchSetting?.find((res) => res?.type === "bookmaker")
                  ?.oddsLimit <= filter_odds[2]?.rt ||
                loginUserDetailData?.matchSetting?.find(
                  (res) =>
                    res?.type === "bookmaker" &&
                    res?.sportType === details?.gameType
                )?.oddsLimit <= filter_odds[2]?.rt
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <p>{filter_odds[2]?.rt || null}</p>
            </dd>
          </dl>
        </td>
      </Fragment>
    );
  };
  //****** BOOKMAKER BET SLIP CARD ******//
  const renderBookmakerbetSlip = (data) => {
    return (
      <td colSpan="7" className="quick-batting-wrapper p-0">
        <div
          className="d-flex align-items-center batting-flex py-2 px-3"
          style={
            data?.betType == "back"
              ? { backgroundColor: "#BEDDF4" }
              : data?.betType == "lay"
              ? { backgroundColor: "#F3DCE2" }
              : {}
          }
        >
          <div className="d-flex max-batting align-items-center justify-content-start">
            {/* <d1>
              <span>
                <a href="#">Max</a>
              </span>
            </d1> */}
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckChecked"
                onChange={(e) => handelAcceptAnyOddsCheck(e)}
                checked={data?.acceptAny || loginUserDetailData?.sportsBook}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Accept Any Odds
              </label>
            </div>
          </div>

          <dd className="col-btn mx-3 ">
            {/* <a href="#">Cancel</a> */}
            <button
              className="bookmakerCancelBtn w-100 fw-5"
              onClick={() => handelClearBookmakerBetSlip()}
            >
              Cancel
            </button>
          </dd>

          <dd
            className="quick-bet-confirm d-flex justify-content-end align-item-center"
            style={{ height: "33px" }}
          >
            <strong className="d-flex align-items-center">{data?.rt}</strong>
            {/* <span>odds</span> */}
          </dd>

          <dd className="up_down_rate mx-3 quick-input">
            <input
              type="text"
              value={data?.activePrice ? data?.activePrice : ""}
              onChange={(e) => handelChangeBookmakerAmount(e, data)}
              onKeyPress={(e) => {
                isInputNumber(e);
                if (e.target.value.length > 6) {
                  e.preventDefault();
                }
              }}
              max={1500000}
            />
          </dd>

          <dd
            className="col-send"
            style={
              !data.activePrice || details?.sport_setting?.status != "active"
                ? {
                    // color: "#fff",
                    // background: "red",
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            <button
              className="bookmaker-place-bet-btn btn"
              onClick={() => handelPlaceBetBookmaker()}
              disabled={
                !data.activePrice || details?.sport_setting?.status != "active"
              }
              style={
                !data.activePrice || details?.sport_setting?.status != "active"
                  ? {
                      color: "#fff",
                      background: "gray",
                    }
                  : {}
              }
            >
              Place Bets
              {loaderPlaceBookmakerBet && (
                <span style={{ marginLeft: "10px" }}>
                  <Loader
                    Style={{
                      borderTop: "7px solid #FFB80C",
                      borderBottom: "7px solid #FFB80C",
                      width: "10px",
                      height: "10px",
                    }}
                  />
                </span>
              )}
            </button>
          </dd>
        </div>
        <div
          className="preset_value p-0 m-0"
          style={
            data?.betType == "back"
              ? { backgroundColor: "#D4E8F8" }
              : data?.betType == "lay"
              ? { backgroundColor: "#FAEFF2" }
              : {}
          }
        >
          <ul>
            {data?.price.map((money) => {
              return (
                <li key={money?.id}>
                  <button
                    className="price-button-pay-slip"
                    onClick={() => handelSetBookmakerPrice(money, data)}
                    style={
                      data.activePrice === money.value
                        ? {
                            background: "#444444",
                            color: "#f8d61c ",
                            cursor: "not-allowed",
                            padding: "6px 10px",
                            minWidth: "95px",
                            fontWeight: "300",
                          }
                        : {
                            padding: "4px 12px",
                            minWidth: "114px",
                            fontWeight: "300",
                          }
                    }
                    disabled={data.activePrice === money.value}
                  >
                    {money?.value}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </td>
    );
  };
  const renderFancyLay = (the_data) => {
    return (
      <td className="ps-0 " colSpan="" style={{ position: "relative" }}>
        <dl className="lay-gradient">
          <dd
            id="lay_1"
            className="p-0"
            onClick={() =>
              !the_data?.odds?.rt[0]?.rt || details?.fancy == "off"
                ? // ||
                  // details?.matchSetting?.find((res) => res?.type === "fancy")
                  //   ?.oddsLimit <= the_data?.odds?.rt[0]?.rt
                  null
                : activeOneClick
                ? handelPlaceFancyBet(the_data?.odds?.rt[0], the_data, "lay")
                : handelShowFancyBetSlip(the_data?.odds?.rt[0], the_data, "lay")
            }
            style={
              !the_data?.odds?.rt[0]?.rt || details?.fancy == "off"
                ? // ||
                  // details?.matchSetting?.find((res) => res?.type === "fancy")
                  //   ?.oddsLimit <= the_data?.odds?.rt[0]?.rt
                  {
                    cursor: "not-allowed",
                    backgroundColor: "#faa9ba",
                    color: "#000",
                    flexDirection: "column",
                  }
                : {
                    cursor: "pointer",
                    backgroundColor: "#faa9ba",
                    color: "#000",
                    flexDirection: "column",
                  }
            }
          >
            <p className="border-0 p-0 mt-0 rounded-0">
              {the_data?.odds?.rt[0]?.rt}
            </p>

            <p
              className="border-0 p-0 mt-0 rounded-0"
              style={{ fontSize: "11px", fontWeight: "normal" }}
            >
              {the_data?.odds?.rt[0]?.pt}
            </p>
          </dd>
        </dl>
      </td>
    );
  };
  const renderFancyBack = (the_data) => {
    return (
      <td className="ps-0" colSpan="" style={{ position: "relative" }}>
        <dl className="back-gradient">
          <dd
            id="back_1"
            className="px-0"
            onClick={() =>
              !the_data?.odds?.rt[1]?.rt || details?.fancy == "off"
                ? // ||
                  // details?.matchSetting?.find((res) => res?.type === "fancy")
                  //   ?.oddsLimit <= the_data?.odds?.rt[1]?.rt
                  null
                : activeOneClick
                ? handelPlaceFancyBet(the_data?.odds?.rt[1], the_data, "back")
                : handelShowFancyBetSlip(
                    the_data?.odds?.rt[1],
                    the_data,
                    "back"
                  )
            }
            style={
              !the_data?.odds?.rt[1]?.rt || details?.fancy == "off"
                ? // ||
                  // details?.matchSetting?.find((res) => res?.type === "fancy")
                  //   ?.oddsLimit <= the_data?.odds?.rt[1]?.rt
                  {
                    cursor: "not-allowed",
                    backgroundColor: "#72bbef",
                    color: "#000",
                    flexDirection: "column",
                  }
                : {
                    cursor: "pointer",
                    backgroundColor: "#72bbef",
                    color: "#000",
                    flexDirection: "column",
                  }
            }
          >
            <p className="border-0 p-0 mt-0 rounded-0">
              {the_data?.odds?.rt[1]?.rt}
            </p>
            <p
              className="border-0 p-0 mt-0 rounded-0"
              style={{ fontSize: "11px", fontWeight: "normal" }}
            >
              {the_data?.odds?.rt[1]?.pt}
            </p>
          </dd>
        </dl>
      </td>
    );
  };
  const renderFancySlip = (fancyData) => {
    return (
      <td colSpan="7" className="quick-batting-wrapper p-0">
        <div
          className="d-flex align-items-center batting-flex py-2 px-3"
          style={
            fancyData?.betType == "back"
              ? { backgroundColor: "#BEDDF4" }
              : fancyData?.betType == "lay"
              ? { backgroundColor: "#F3DCE2" }
              : {}
          }
        >
          <div className="d-flex max-batting align-items-center justify-content-start">
            {/* <d1>
              <span>
                <a href="#">Max</a>
              </span>
            </d1> */}
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckChecked"
                onChange={(e) => handelAcceptAnyOddsFancyCheck(e)}
                checked={fancyData?.acceptAny || loginUserDetailData?.fancyBet}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Accept Any Odds
              </label>
            </div>
          </div>

          <dd className="col-btn mx-3 ">
            {/* <a href="#">Cancel</a> */}
            <button
              className="bookmakerCancelBtn w-100 fw-5"
              onClick={() => handelClearFancyBetSlip()}
            >
              Cancel
            </button>
          </dd>

          <dd
            className="quick-bet-confirm text-center"
            style={{ height: "33px" }}
          >
            <strong className="">
              {fancyData?.rt}
              <br />
            </strong>

            <span>{fancyData?.pt}</span>
          </dd>

          <dd className="up_down_rate mx-3 quick-input">
            <input
              type="text"
              value={fancyData?.activePrice ? fancyData?.activePrice : ""}
              onChange={(e) => handelChangeFancyAmount(e, fancyData)}
              onKeyPress={(e) => {
                isInputNumber(e);
                if (e.target.value.length > 6) {
                  e.preventDefault();
                }
              }}
              max={1500000}
              style={
                !fancyData?.activePrice
                  ? { border: "1px solid red", borderRadius: "4px" }
                  : {}
              }
              title={!fancyData?.activePrice ? "Enter amount" : ""}
            />
          </dd>

          <dd
            className="col-send"
            style={
              !fancyData.activePrice || loaderFancyBet
                ? {
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            <button
              className="bookmaker-place-bet-btn btn"
              style={
                !fancyData?.activePrice ||
                loaderFancyBet ||
                details?.sport_setting?.status != "active"
                  ? {
                      cursor: "not-allowed",
                      color: "#fff",
                      background: "gray",
                    }
                  : {
                      cursor: "pointer",
                    }
              }
              onClick={() => handelPlaceFancyBet()}
              disabled={
                !fancyData?.activePrice ||
                loaderFancyBet ||
                details?.sport_setting?.status != "active"
              }
            >
              Place Bets
              {loaderFancyBet && (
                <span style={{ marginLeft: "10px" }}>
                  <Loader
                    Style={{
                      borderTop: "7px solid #FFB80C",
                      borderBottom: "7px solid #FFB80C",
                      width: "10px",
                      height: "10px",
                    }}
                  />
                </span>
              )}
            </button>
          </dd>
        </div>
        <div
          className="preset_value p-0 m-0"
          style={
            fancyData?.betType == "back"
              ? { backgroundColor: "#D4E8F8" }
              : fancyData?.betType == "lay"
              ? { backgroundColor: "#FAEFF2" }
              : {}
          }
        >
          <ul>
            {fancyData?.price.map((money) => {
              return (
                <li key={money?.id}>
                  <button
                    className="price-button-pay-slip"
                    onClick={() => handelSetFancyPrice(money, fancyData)}
                    style={
                      fancyData.activePrice === money.value
                        ? {
                            background: "#444444",
                            color: "#f8d61c ",
                            cursor: "not-allowed",
                            padding: "6px 10px",
                            minWidth: "114px",
                            fontWeight: "300",
                          }
                        : {
                            padding: "6px 10px",
                            minWidth: "114px",
                            fontWeight: "300",
                          }
                    }
                    disabled={fancyData.activePrice === money.value}
                  >
                    {money?.value}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </td>
    );
  };

  return (
    <div>
      <div className="full-wrap">
        {/* LEFT SIDEBAR  */}
        <MatchSidebar
          details={details}
          matchType={sportType}
          tournamentData={tournamentData}
          handelBackFunc={(id) => {
            setSeriesId();
            setSeriesName();
            getTournamentData();
          }}
          handelClickFunc={(id) => {
            getMatches(id);
            setAllSports();
            setTournamentData();
            setMatchName();
          }}
          matchesWithId={matchesWithId}
          setSeriesName={(seriesName) => {
            setSeriesName(seriesName);
            setMatchName();
          }}
          setMatchName={(matchName) => {
            setMatchName(matchName);
            setMatchesWithId();
          }}
          setMatchId={(matchId) => {
            setMatchId(matchId);
            setMatchesWithId();
          }}
          setMarketId={(marketId) => {
            setMarketId(marketId);
          }}
          // seriesName={getSeriesName}
          // matchName={getMatchName}
          seriesName={details?.seriesName}
          matchName={details?.eventName}
          matchId={getMatchId}
          marketId={getMarketId}
          getSeriesId={getSeriesId}
          handelClickOddDetails={(matchId, marketId) =>
            handelClickOddDetails(matchId, marketId)
          }
          handelAll={() => {
            handelAll();
            setSeriesId();
            setMatchName();
          }}
          AllSports={getAllSports}
          handelSportFunc={(id) => {
            getMatches(id);
            setAllSports();
            getTournamentData();
          }}
        />
        {/* LEFT SIDEBAR  */}
        <div className="col-center games_center_block">
          {details?.scoreId && details?.scoreId != 0 && (
            <div className="py-2 py-md-4">
              <iframe
                className="responsive-iframe w-100"
                style={{ height: "180px" }}
                src={`https://www.satsports.net/score_widget/index.html?id=${details?.scoreId}`}
              ></iframe>
            </div>
          )}
          <div className="top-wrapper-match bg-white mb-3 mt-2">
            <ul className="match-btn d-flex justify-content-center">
              <li>
                <a
                  className={isMultimarket ? "btn-pin select" : "btn-pin"}
                  onClick={() => addMarket(details?.eventId)}
                  href="#"
                >
                  <img src={pin1} alt="" />
                </a>
              </li>
              <li>
                <a
                  className="btn-refresh"
                  href="#"
                  onClick={() => refereshHandle()}
                >
                  {" "}
                  <img src={refresh} alt="" />
                </a>
              </li>
            </ul>

            <div className="match-odd-scroller">
              <div className="match-top-wrapper d-flex align-items-center">
                <div className="odd-sec">
                  <strong>Match Odds</strong>
                  <span> In-Play</span>
                </div>

                <div className="max">
                  <h6 className="mb-0">
                    Max{" "}
                    {amountRangeBetFair?.max > 0
                      ? amountRangeBetFair?.max?.toFixed(2)
                      : 1600}
                  </h6>
                </div>

                <div className="live-match">
                  <span>Matched</span>
                  <strong>USD {totalMatched}</strong>
                  {user !== null ? <h5>Live</h5> : ""}
                </div>
              </div>
            </div>
          </div>
          {!isEmpty(user) &&
          details?.channel &&
          details?.channel !== "false" &&
          isTv ? (
            <div style={{ background: "black", width: "100%" }}>
              <div className="match-odd-iframe">
                <div className="tv-control">
                  <a
                    class="close"
                    id="closeStreamingBox"
                    href="javascript:void(0)"
                    onClick={() => setIsTv(!isTv)}
                    style={{ display: "flex" }}
                  >
                    Close
                  </a>
                </div>

                <div className="tv-control" style={{ marginBottom: "20px" }}>
                  <a
                    class={mute ? "btn-volume_off" : "btn-volume_on"}
                    onClick={() => setMute(!mute)}
                    href="javascript:void(0)"
                    id="mute"
                  ></a>
                </div>
                <ReactPlayer
                  muted={details?.adsStatus ? false : mute}
                  controls={false}
                  playsinline={true}
                  playing={true}
                  width="100%"
                  height="auto"
                  url={
                    details?.adsStatus && details?.adsContent
                      ? details?.adsContent
                      : streamingUrl
                  }
                />
              </div>
            </div>
          ) : null}
          {showBetFairLoader && (
            <>
              <div className="match-odds-table game_table_main_wrapper" key="1">
                <span className="d-flex justify-content-center align-item-center mt-2 pb-3">
                  <img
                    src={LoaderGif}
                    alt="No_Image"
                    style={{ height: "200px", width: "200px" }}
                  />
                </span>
              </div>
            </>
          )}

          {details?.matchOdds == "on" &&
          selections?.length > 0 &&
          back_odds?.length > 0 &&
          lay_odds?.length > 0 ? (
            <BetFair
              selections={selections}
              marketName={"Match Odds"}
              renderBackOdds={renderBackOdds}
              renderLayOdds={renderLayOdds}
              betFairMs={betFairMs}
              BackOdds={back_odds || []}
              LayOdds={lay_odds || []}
              marketId={params?.marketId}
            />
          ) : (
            ""
          )}
          {marketNew?.length > 0 &&
            marketNew?.map((res) => {
              console.log(res,'res')
              return (
                <BetFair
                  selections={res?.jsonData}
                  marketName={res?.market}
                  renderBackOdds={renderBackOdds}
                  renderLayOdds={renderLayOdds}
                  marketId={res?.mi}
                  betFairMs={!isEmpty(res?.ms) ? res?.ms : 1}
                  BackOdds={
                    res?.back_odds?.length > 0
                      ? res?.back_odds || []
                      : []
                  }
                  LayOdds={
                  res?.lay_odds?.length > 0
                    
                      ? res?.lay_odds ||  []
                      : []
                  }
                />
              );
            })}
          {bookmakerSelections?.length > 0 && details?.jsonBookmakerData && (
            <div className="special-bet-table">
              <div className="space-bet">
                <div className="special_bet">
                  <h3 className="mb-0">
                    <a
                      className="add-pin"
                      href="/"
                      title="Add to Multi Markets"
                    ></a>
                    Bookmaker Market
                    <p>| Zero Commission</p>
                  </h3>
                  <dl className="fancy-info mobile_hide">
                    <dt>
                      <span>Min</span>
                    </dt>
                    <dd id="min" className="mb-0 text-white">
                      {" "}
                      {amountRangeBookmaker?.min > 0
                        ? amountRangeBookmaker?.min?.toFixed(2)
                        : "1.00"}
                    </dd>
                    <dt>
                      <span>Max</span>
                    </dt>
                    <dd id="max" className="mb-0 text-white">
                      {" "}
                      {amountRangeBookmaker?.max > 0
                        ? amountRangeBookmaker?.max?.toFixed(2)
                        : "1,500.00"}
                    </dd>
                  </dl>
                </div>
                <Table>
                  <tbody>
                    <tr className="bet-all">
                      <td colSpan="3" width="60%"></td>
                      <td width="8%">
                        <strong className="text-center d-block">Back</strong>
                      </td>
                      <td width="8%">
                        {" "}
                        <strong className="text-center d-block">Lay</strong>
                      </td>
                      <td colSpan="2"></td>
                    </tr>
                    {bookmakerSelections?.map((bookmarkSelection, index) => {
                      return (
                        <>
                          <tr className="bet-all new-bet" key={index}>
                            <td className="predict" width="40%">
                              {bookmarkSelection && (
                                <strong className="mb-0 pt-3 d-block">
                                  {bookmarkSelection?.runnerName}
                                  <div style={{ display: "flex" }}>
                                    <span
                                      style={
                                        bookmarkSelection.position <= 0
                                          ? { color: "red", marginRight: "5px" }
                                          : {
                                              color: "green",
                                              marginRight: "5px",
                                            }
                                      }
                                    >
                                      {Math.abs(bookmarkSelection.position) !=
                                        0 &&
                                        !isNaN(
                                          Math.abs(bookmarkSelection.position)
                                        ) && (
                                          <>
                                            {parseFloat(
                                              Math.abs(
                                                bookmarkSelection.position
                                              )
                                            ).toFixed(2)}
                                          </>
                                        )}
                                    </span>
                                    <span
                                      style={
                                        bookmarkSelection.newPosition <= 0
                                          ? { color: "red" }
                                          : { color: "green" }
                                      }
                                    >
                                      {Math.abs(
                                        bookmarkSelection.newPosition
                                      ) != 0 &&
                                        !isNaN(
                                          Math.abs(
                                            bookmarkSelection.newPosition
                                          )
                                        ) && (
                                          <>
                                            <i className="fa fa-arrow-right"></i>
                                            &nbsp;&nbsp;
                                            {parseFloat(
                                              Math.abs(
                                                bookmarkSelection.newPosition
                                              )
                                            ).toFixed(2)}
                                          </>
                                        )}
                                    </span>
                                  </div>
                                </strong>
                              )}
                            </td>
                            {renderBookmarkBack(bookmarkSelection)}
                            {renderBookmarkLay(bookmarkSelection)}
                            <>
                              {details?.sport_setting?.status == "inActive" ? (
                                <td
                                  className="hide-data-table ps-0"
                                  style={{ cursor: "not-allowed" }}
                                >
                                  <table className="w-100 text-center">
                                    <tbody>
                                      <tr>
                                        <td className="pe-xxl-5 pe-3">
                                          Suspend
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              ) : bookmakerBackOdds[0]?.ms != 1 ||
                                bookmakerLayOdds[0]?.ms != 1 ? (
                                <td
                                  className="hide-data-table ps-0"
                                  style={{ cursor: "not-allowed" }}
                                >
                                  <table className="w-100 text-center">
                                    <tbody>
                                      <tr>
                                        <td className="pe-xxl-5 pe-3">
                                          {bookmakerBackOdds[0]?.ms == 9 ||
                                          bookmakerLayOdds[0]?.ms == 9
                                            ? "Ball Running"
                                            : bookmakerBackOdds[0]?.ms == 2 ||
                                              bookmakerLayOdds[0]?.ms == 2
                                            ? "In Active"
                                            : bookmakerBackOdds[0]?.ms == 3 ||
                                              bookmakerLayOdds[0]?.ms == 3
                                            ? "Suspended"
                                            : bookmakerBackOdds[0]?.ms == 4 ||
                                              bookmakerLayOdds[0]?.ms == 4
                                            ? "Closed"
                                            : "Suspended"}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              ) : (
                                ""
                              )}
                            </>
                          </tr>
                          {bookmarkSelection.selectionID ==
                            bookmakerBetSlipObj.selectionId && (
                            <tr>
                              {renderBookmakerbetSlip(bookmakerBetSlipObj)}
                            </tr>
                          )}
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
          {/* 8/11/22 */}

          <div className={`sportsbook-head  mt-3 d-flex align-items-center `}>
            {fancyList?.length > 0 && (
              <span className="winner-sportsbook" style={{ cursor: "pointer" }}>
                <h4
                  className="in-play mb-0"
                  onClick={() => toggleFancyAndPremium("fancy")}
                >
                  <span id="sportsBookName">Fancy Bet</span>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={popoverFancy}
                  >
                    <div className="info-popover"></div>
                  </OverlayTrigger>
                </h4>
              </span>
            )}
            {!isEmpty(user)
              ? preminumFancy?.length > 0 && (
                  <h4
                    className="in-play mb-0 "
                    onClick={() => toggleFancyAndPremium("premium")}
                    style={{ cursor: "pointer" }}
                  >
                    <strong className="sportstrong"></strong>{" "}
                    <span id="sportsBookName">Sportsbook</span>
                    <OverlayTrigger
                      trigger="click"
                      placement="right"
                      overlay={popoverPremium}
                    >
                      <div className="info-popover"></div>
                    </OverlayTrigger>
                  </h4>
                )
              : ""}
          </div>

          {/* PREMIUM CRICKET START */}
          {showFancy == "fancy" && fancyList?.length > 0 ? (
            <div className="premier-tab fancy-premier-tab">
              <Tabs
                defaultActiveKey="All"
                id=""
                activeKey={key}
                onSelect={(k) => {
                  onChangeKey(k);
                }}
                className="mb-1"
              >
                {fancyCategory?.length > 0 &&
                  fancyCategory?.map((item, index) => {
                    return (
                      <Tab
                        eventKey={item.type}
                        title={item.name}
                        key={index + 1}
                      >
                        <FancyTabs
                          amountRangeFancy={amountRangeFancy}
                          key={index + 1}
                          fancyCategoryTabArray={
                            key == "All" ? fancyList : fancyCategoryTabArray
                          }
                          renderFancyLay={renderFancyLay}
                          renderFancyBack={renderFancyBack}
                          fancyBetSlipObj={fancyBetSlipObj}
                          renderFancySlip={renderFancySlip}
                          sportType={sportType}
                        />
                      </Tab>
                    );
                  })}
              </Tabs>
            </div>
          ) : null}
          {/* FANCY BET END */}

          {/* PREMIUM CRICKET START */}
          {!isEmpty(user) ? (
            showFancy == "premium" && preminumFancy?.length > 0 ? (
              <div className="premier-tab winner-super-accordian">
                <Tabs
                  defaultActiveKey="All"
                  activeKey={fancyKey}
                  onSelect={(e) => {
                    setFancyKey(e);
                  }}
                  id=""
                  className="mb-1"
                >
                  <Tab eventKey={"All"} title={"All"}>
                    <PreminumFancyTable
                      setAccordian={setAccordian}
                      accordian={accordian}
                      renderPreminumFancySlip={renderPreminumFancySlip}
                      data={preminumFancy}
                      preminumfancyAmount={preminumfancyAmount}
                      setPreminumFancyAmount={setPreminumFancyAmount}
                      setAcceptAnyOddsPreminumFancyCheckbox={
                        setAcceptAnyOddsPreminumFancyCheckbox
                      }
                      setSelectPreminumFancyFunction={
                        setSelectPreminumFancyFunction
                      }
                      setSelectPreminumFancy={setSelectPreminumFancy}
                      selectPreminumFancy={selectPreminumFancy}
                      manualFancy={details?.manualFancy}
                      matchStatus={details?.status}
                      oddsLimit={
                        details.matchSetting.length > 0
                          ? details.matchSetting.find(
                              (res) => res?.type === "sportBook"
                            )?.oddsLimit
                          : loginUserDetailData?.matchSetting?.find(
                              (res) =>
                                res?.type === "sportBook" &&
                                res?.sportType === details?.gameType
                            )?.oddsLimit
                      }
                    />
                  </Tab>
                </Tabs>
              </div>
            ) : null
          ) : (
            ""
          )}
          {/* FANCY BET END */}
          {/* 8/11/22 */}
        </div>
        {/* right_cols */}
        {/* RIGHT SECTION */}
        <button
          className="rightbar_btn d-block d-lg-none"
          onClick={() => showToggle()}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
        <div className="col-right">
          <div className="theme_accordian">
            {betLoader ? (
              <div class="loading-wrap">
                <ul class="loading">
                  <li>
                    <img src={loading40} />
                  </li>
                  <li>Place Bets for {timeLeft} secs</li>
                </ul>
              </div>
            ) : (
              <Accordion
                defaultActiveKey={["bets", "myBets"]}
                // activeKey={accordianToogle}
                onSelect={(e) => setAccordianToogle(e)}
                alwaysOpen
              >
                <Accordion.Item
                  eventKey="bets"
                  className={
                    user
                      ? currentBets?.SporBooksBet?.length > 0 ||
                        currentBets?.FancyBet?.length > 0 ||
                        currentBets?.BookmakerBet?.length > 0 ||
                        currentBets?.BetFairBet?.matched?.length > 0 ||
                        currentBets?.BetFairBet?.uMmatched?.length > 0
                        ? "login-accordion-item"
                        : ""
                      : ""
                  }
                >
                  <Accordion.Header>Bet Slip</Accordion.Header>
                  <Accordion.Body>
                    <div className="bet_slip_data">
                      <div className="col-right-inner">
                        <div className="message-bet d-none">
                          <h4 id="errorMsg" className="error">
                            Your back odds cannot bet less than or the same as
                            your lay odds for Martinez v S Kwon, Match Odds,
                            Soonwoo Kwon
                          </h4>
                          <p id="betMsg" className="warning"></p>
                        </div>
                        <p className="d-none">
                          Click on the odds to add selections to the betslip.{" "}
                        </p>
                        <div id="betSlipBoard" className="bet_slip">
                          <div
                            id="betList"
                            className="slip-list max-heightminus131"
                          >
                            {minAmountCon && (
                              <div class="message-bet">
                                <h4 id="errorMsg" class="error">
                                  The stake you have entered are below the
                                  minimum.
                                </h4>
                              </div>
                            )}
                            {/* slip_lay */}

                            <div className="bets_list_wrapper">
                              {/* BLUE PART START */}
                              {renderBackBetSlip(backBetSlipArray)}
                              {/* BLUE PART END */}

                              <div>
                                {/* PINK PART START */}
                                {renderLayBetSlip()}
                                {/* PINK PART END */}
                              </div>
                            </div>
                          </div>
                        </div>
                        {allBetSlipData.length > 0 ? (
                          <div className="full_btn">
                            <div className="liability sum">
                              Liability
                              <span className="red">
                                $
                                {parseFloat(
                                  Math.abs(
                                    layBetSlipArray?.length > 0
                                      ? layBetSlipArray?.reduce(
                                          (acc, cur) =>
                                            acc +
                                            (cur?.activePrice > 0
                                              ? (cur?.rt - 1) * cur?.activePrice
                                              : 0),
                                          0
                                        )
                                      : 0
                                  ).toFixed(2)
                                )}
                              </span>
                            </div>

                            <ul className="btn-wrap">
                              <li>
                                <button
                                  className="price-button-pay-slip btn"
                                  onClick={() => handelClearAllBet()}
                                >
                                  Cancel All
                                </button>
                              </li>
                              <li>
                                <button
                                  className="btn-send disable"
                                  style={
                                    disableBetFairPlaceBetBtn ||
                                    loaderPlaceBetfair ||
                                    details?.sport_setting?.status != "active"
                                      ? {
                                          cursor: "not-allowed",
                                        }
                                      : {
                                          cursor: "pointer",
                                        }
                                  }
                                  onClick={() => handelPlaceBetfair()}
                                  disabled={
                                    disableBetFairPlaceBetBtn ||
                                    loaderPlaceBetfair ||
                                    details?.sport_setting?.status != "active"
                                  }
                                  title={
                                    disableBetFairPlaceBetBtn
                                      ? "Please fill amount field"
                                      : ""
                                  }
                                >
                                  Place Bets{" "}
                                  {loaderPlaceBetfair && (
                                    <span style={{ marginLeft: "10px" }}>
                                      <Loader
                                        Style={{
                                          borderTop: "7px solid #FFB80C",
                                          borderBottom: "7px solid #FFB80C",
                                          width: "10px",
                                          height: "10px",
                                        }}
                                      />
                                    </span>
                                  )}
                                </button>
                              </li>
                            </ul>

                            <ul className="slip-option">
                              <li>
                                <input type="checkbox" />
                                <label>Please confirm your bets.</label>{" "}
                              </li>
                            </ul>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center align-item-center mt-2 px-3">
                            <p style={{ fontSize: "13px" }}>
                              Click on the odds to add selections to the
                              betslip.
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                {user &&
                  (currentBets?.SporBooksBet?.length > 0 ||
                  currentBets?.FancyBet?.length > 0 ||
                  currentBets?.BookmakerBet?.length > 0 ||
                  currentBets?.BetFairBet?.matched?.length > 0 ||
                  currentBets?.BetFairBet?.uMmatched?.length > 0 ? (
                    <Accordion.Item
                      eventKey="myBets"
                      className={user ? "login-accordion-item" : ""}
                    >
                      <Accordion.Header>Open Bets</Accordion.Header>
                      <Accordion.Body>
                        <OpenBets
                          setMyBetSelect={setMyBetSelect}
                          getMatchName={getMatchName}
                          myBetsSelect={myBetsSelect}
                          currentBets={currentBets}
                          handelCancelUnmatched={handelCancelUnmatched}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    ""
                  ))}
              </Accordion>
            )}
          </div>
        </div>
      </div>
      <Login show={showLogin} handleClose={() => setShowLogin(false)} />
    </div>
  );
};

export default MatchOdd;

import React from "react";

const BetFair = ({
  selections,
  renderBackOdds,
  renderLayOdds,
  marketName,
  BackOdds,
  LayOdds,
  marketId,
  betFairMs,
}) => {
  return (
    <div className="match-odds-table game_table_main_wrapper" key="2">
      <table className="bets game_table_inner">
        <tbody>
          <tr className="bet-all" style={{ background: "#F0ECE1" }}>
            <td className="text-start">
              {selections?.length} selections - {marketName}
            </td>
            <td className="px-0">
              <table>
                <tr>
                  <td id="backPercent" className="refer-bet mobile_hide">
                    100.6%
                  </td>
                  <td className="back-all_td">
                    <a id="backAll" className="back-all">
                      <i className="backall-shape"></i>
                      <span>Back all</span>
                    </a>
                  </td>
                </tr>
              </table>
            </td>
            <td className="px-0">
              <table>
                <tr>
                  <td className="lay-all_td">
                    <a id="layAll" className="lay-all">
                      <i className="layall-shape"></i>
                      <span>Lay all</span>
                    </a>
                  </td>
                  <td
                    id="layPercent"
                    className="refer-book mobile_hide"
                    colSpan="2"
                  >
                    99.9%
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          {selections?.map((selection, index) => {
            let disabelBack =
              "lockBackBets" in selection ? selection?.lockBackBets : false;
            let disabelLay =
              "lockLayBets" in selection ? selection?.lockLayBets : false;

            return (
              <tr key={index + 1} className="relative_row">
                <th className="">
                  <p>
                    <a href="#">
                      <img
                        className="icon-predict"
                        src="/images/transparent.gif"
                      />
                    </a>
                    {selection?.RunnerName}
                    <div style={{ display: "flex" }}>
                      <span
                        style={
                          selection.position <= 0
                            ? { color: "red", marginRight: "5px" }
                            : { color: "green", marginRight: "5px" }
                        }
                      >
                        {Math.abs(selection.position) != 0 &&
                          !isNaN(Math.abs(selection.position)) && (
                            <>
                              {parseFloat(Math.abs(selection.position)).toFixed(
                                2
                              )}
                            </>
                          )}
                      </span>
                      <span
                        style={
                          selection.newPosition <= 0
                            ? { color: "red" }
                            : { color: "green" }
                        }
                      >
                        {Math.abs(selection.newPosition) != 0 &&
                          !isNaN(Math.abs(selection.newPosition)) && (
                            <>
                              <i className="fa fa-arrow-right"></i>
                              &nbsp;&nbsp;
                              {parseFloat(
                                Math.abs(selection.newPosition)
                              ).toFixed(2)}
                            </>
                          )}
                      </span>
                    </div>
                  </p>
                </th>
                {renderBackOdds(
                  selection,
                  BackOdds,
                  marketName,
                  marketId,
                  disabelBack ? 3 : betFairMs
                )}
                {renderLayOdds(
                  selection,
                  LayOdds,
                  marketName,
                  marketId,
                  disabelLay ? 3 : betFairMs
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default BetFair;

import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";

const PreminumFancyTable = ({
  data,
  renderPreminumFancySlip,
  selectPreminumFancy,
  setSelectPreminumFancyFunction,
  setAccordian,
  accordian,
  setPreminumFancyAmount,
  setAcceptAnyOddsPreminumFancyCheckbox,
  manualFancy,
  matchStatus,
  oddsLimit,
  preminumfancyAmount,
}) => {
  const premiumFunction = (item, res) => {
    setSelectPreminumFancyFunction(item, res);
    setAcceptAnyOddsPreminumFancyCheckbox(0);
  };
  return (
    <div>
      {data?.length > 0 &&
        data?.map((item, index) => {
          // let result = [];
          // if (
          //   item?.selectionTs == selectPreminumFancy?.selectionTs &&
          //   item?.sportsBookSelection?.length > 0
          // ) {
          //   let backProfit =
          //     (selectPreminumFancy?.newRunner?.odds - 1) * preminumfancyAmount;
          //   let backLoss = preminumfancyAmount;
          //   result = item?.sportsBookSelection?.map((res) => {
          //     return res.id === selectPreminumFancy?.newRunner?.id
          //       ? {
          //           ...res,
          //           newPosition:
          //             res.position !== 0 && !isNaN(Math.abs(res.position))
          //               ? Number(backProfit) + Number(res.position)
          //               : Number(backProfit),
          //         }
          //       : {
          //           ...res,
          //           newPosition:
          //             res.position !== 0 && !isNaN(Math.abs(res.position))
          //               ? Number(backLoss) - Number(res.position)
          //               : -Number(backLoss),
          //         };
          //   });
          // }

          return (
            <div className="theme_accordian" key={index + 1}>
              <Accordion
                defaultActiveKey={[0, 1, 2, 3, 4]}
                onSelect={(e) => setAccordian(e)}
              >
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>{item.marketName}</span>
                      {/* <span>
                        {item?.id === selectPreminumFancy?.id &&
                        item?.runners?.length == 2 &&
                        item?.runners[0]?.position &&
                        item?.runners[1]?.position ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "15px",
                            }}
                          >
                            <span
                              style={
                                item?.runners[0]?.check == "loss"
                                  ? { color: "red" }
                                  : { color: "green" }
                              }
                            >
                              {item?.runners[0]?.selectionName}{" "}
                              &nbsp;&nbsp;
                              <i className="fa fa-arrow-right"></i>
                              &nbsp;&nbsp;
                              {item?.runners[0]?.position}
                            </span>
                            <span
                              style={
                                item?.runners[1]?.check == "loss"
                                  ? { color: "red" }
                                  : { color: "green" }
                              }
                            >
                              {item?.runners[1]?.selectionName}{" "}
                              &nbsp;&nbsp;
                              <i className="fa fa-arrow-right"></i>
                              &nbsp;&nbsp;
                              {item?.runners[1]?.position}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </span> */}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="winner-super-over">
                      <Row className="justify-content-center p-0">
                        {item?.sportsBookSelection?.length > 0
                          ? item?.sportsBookSelection?.map((res, index) => {
                              let newPosition = "";
                              if (
                                item?.id === selectPreminumFancy?.id &&
                                item?.sportsBookSelection?.length > 0 &&
                                preminumfancyAmount > 0
                              ) {
                                let backProfit =
                                  (selectPreminumFancy?.newRunner?.odds - 1) *
                                  preminumfancyAmount;
                                let backLoss = preminumfancyAmount;
                                if (
                                  res.id === selectPreminumFancy?.newRunner?.id
                                ) {
                                  newPosition =
                                    res?.position !== 0 &&
                                    !isNaN(Math.abs(res?.position))
                                      ? Number(backProfit) +
                                        Number(res.position)
                                      : Number(backProfit);
                                } else {
                                  newPosition =
                                    res.position !== 0 &&
                                    !isNaN(Math.abs(res?.position))
                                      ? Number(backLoss) - Number(res?.position)
                                      : -Number(backLoss);
                                }
                              }
                              let condition =
                                item?.sportsBookSelection?.length > 0 &&
                                Math.abs(newPosition) > 0 &&
                                item?.id === selectPreminumFancy?.id &&
                                preminumfancyAmount > 0
                                  ? true
                                  : false;
                              return (
                                <Col
                                  key={index + 1}
                                  md={3}
                                  sm={4}
                                  xs={6}
                                  onClick={() =>
                                    manualFancy == "off" ||
                                    oddsLimit <= res.odds
                                      ? null
                                      : premiumFunction(item, res)
                                  }
                                >
                                  <div className="text-center">
                                    <span>{res?.selectionName}</span>
                                    <h4>{res.odds}</h4>
                                    {Math?.abs(res?.position) > 0 && (
                                      <span
                                        style={{
                                          color:
                                            res?.position > 0 ? "green" : "red",
                                          padding: "0 5px",
                                        }}
                                      >
                                        {Math.abs(res?.position).toFixed(2)}
                                      </span>
                                    )}
                                    {condition &&
                                      Math?.abs(newPosition) > 0 && (
                                        <>
                                          {Math?.abs(res?.position) > 0 && (
                                            <i className="fa fa-arrow-right"></i>
                                          )}
                                          <span
                                            style={{
                                              color:
                                                newPosition > 0
                                                  ? "green"
                                                  : "red",
                                              padding: "0 5px",
                                            }}
                                          >
                                            ({Math.abs(newPosition).toFixed(2)})
                                          </span>
                                        </>
                                      )}
                                  </div>
                                </Col>
                              );
                            })
                          : ""}
                      </Row>
                      {/* {console.log("item ", item)}
                        {console.log("selectPreminumFancy ", selectPreminumFancy)} */}
                      {item?.id === selectPreminumFancy?.id && (
                        <>{renderPreminumFancySlip(selectPreminumFancy)}</>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          );
        })}
    </div>
  );
};

export default PreminumFancyTable;

import React, { useState, useContext, useEffect } from "react";
import { Container, Table } from "react-bootstrap";
import Sidebar from "../components/Sidebar";
import AuthContext from "../context/AuthContext";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { startCase, isEmpty } from "lodash";
import helpers from "../utils/helpers";

const TransationHistory = () => {
  let { user } = useContext(AuthContext);
  const [getLogData, setLogData] = useState("");
  const [pageCount, setPageCount] = useState(0);
  //   const [search_params, setSearchParams] = useState({
  //     page: 1,
  //     limit: 10,
  //   });
  const myStatementData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.transactionLogs
      //   search_params
    );
    if (status === 200) {
      if (response_users.success) {
        setLogData(response_users.results);
      }
    }
  };

  useEffect(() => {
    setPageCount(getLogData?.totalPages || []);
  }, [getLogData]);

  useEffect(() => {
    myStatementData();
  }, []);
  return (
    <>
      <section className="py-4 main-inner-outer">
        <Container fluid>
          <div className="main_content_row">
            <Sidebar />

            <div className="my_account_main">
              <div className="inner-wrapper">
                <h2 className="common-heading">Transaction History</h2>

                <section className="account-table">
                  <div className="profile-tab">
                    <div className="my_account_table_wrap">
                      <div className="table-main-wrapper">
                        <Table className="table_caption_style">
                          <thead>
                            <tr>
                              <th scope="col">Date/Time</th>
                              <th scope="col">Deposit From Upline</th>
                              <th scope="col">Deposit to Downline</th>
                              <th scope="col">WihtDraw By Upline</th>
                              <th scope="col">WithDraw From Downline</th>
                              <th scope="col">Balance</th>
                              <th scope="col">Remark</th>
                              <th scope="col">From/To</th>
                            </tr>
                          </thead>

                          <tbody>
                            {getLogData.data &&
                              getLogData.data.map((item, key) => (
                                <tr>
                                  <td>
                                    {helpers.dateFormat(
                                      item?.createdAt,
                                      user?.timeZone
                                    )}
                                  </td>
                                  <td>
                                    {item?.transactionType === "credit" ? (
                                      <span className="text-success">
                                        {helpers.currencyFormat(item?.amount)}
                                      </span>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td>-</td>
                                  <td>
                                    {" "}
                                    {item?.transactionType === "debit" ? (
                                      <span className="text-danger">
                                        {"(" +
                                          helpers.currencyFormat(item?.amount) +
                                          ")"}
                                      </span>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td>
                                    {helpers.currencyFormat(item?.commission)}
                                  </td>
                                  <td>
                                    {helpers.currencyFormat(item?.newBalance)}
                                  </td>
                                  <td>-</td>
                                  <td>
                                    {startCase(item?.createdByData?.userType)}{" "}
                                    <span>
                                      <i className="fas fa-arrow-right"> </i>
                                    </span>{" "}
                                    {item?.userData?.username}
                                  </td>
                                </tr>
                              ))}
                            {isEmpty(getLogData.data) ? (
                              <tr>
                                <td colSpan={9}>No records found</td>
                              </tr>
                            ) : null}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default TransationHistory;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { startCase, isEmpty, lowerCase } from "lodash";
import { NavDropdown } from "react-bootstrap";

const MatchSidebar = (props) => {
  const showToggle = () => {
    document.getElementById("body-parent").classList.toggle("sidenav");
  };
  const {
    tournamentData,
    handelBackFunc,
    handelClickFunc,
    matchesWithId,
    handelClickOddDetails,
    getSeriesId,
    setSeriesName,
    seriesName,
    matchType,
    handelAll,
    AllSports,
    setMatchName,
    matchName,
    setMatchId,
    setMarketId,
    marketId,
    matchId,
    handelSportFunc,
  } = props;

  return (
    <>
      {/* <button className="leftnav_btn d-block d-md-none" onClick={showToggle}>
        Menu
      </button> */}
      <div className="match-sidebar">
        <ul className="mb-0">
          {isEmpty(AllSports) ? (
            <div>
              <h6>Sports</h6>
              <li onClick={() => handelAll()}>
                <Link to="#">All Sports</Link>{" "}
              </li>
              <li
                className={!seriesName ? "active" : ""}
                onClick={() => handelBackFunc()}
              >
                <Link to="#">{startCase(matchType)}</Link>{" "}
              </li>
              {seriesName ? (
                matchName ? (
                  <div>
                    <li
                      onClick={() => {
                        setSeriesName(seriesName);
                        handelClickFunc(getSeriesId);
                      }}
                    >
                      <Link to="#">{seriesName}</Link>{" "}
                    </li>
                    <li className="active">
                      <Link to="#">{matchName}</Link>{" "}
                    </li>
                    <li
                      className=""
                      onClick={() => handelClickOddDetails(matchId, marketId)}
                    >
                      <Link to="#">
                        <img
                          className="icon-no_play"
                          src="../assets/images/transparent.gif"
                        />
                        Match Odds
                      </Link>{" "}
                    </li>
                  </div>
                ) : (
                  <li className="active">
                    <Link to="#">{seriesName}</Link>{" "}
                  </li>
                )
              ) : (
                <li className="menu-group-title">
                  <Link to="#">Common</Link>{" "}
                </li>
              )}

              {getSeriesId ? (
                <div>
                  {matchesWithId &&
                    matchesWithId.map((item, index) => {
                      return (
                        <li
                          key={index + 1}
                          onClick={() => {
                            setSeriesName(seriesName);
                            setMatchName(item.eventName);
                            setMatchId(item.eventId);
                            setMarketId(item.marketId);
                          }}
                        >
                          <Link to="#">{item.eventName}</Link>
                        </li>
                      );
                    })}
                </div>
              ) : (
                <div>
                  {tournamentData &&
                    tournamentData.map((item, index) => {
                      return (
                        <li
                          key={index + 1}
                          onClick={() => {
                            setSeriesName(item.seriesName);
                            handelClickFunc(item.seriesId);
                          }}
                        >
                          {" "}
                          <Link to="#">{item.seriesName}</Link>
                        </li>
                      );
                    })}
                </div>
              )}
            </div>
          ) : (
            <div>
              <h6>Sports</h6>
              {AllSports &&
                AllSports.map((item, index) => {
                  return (
                    <li
                      key={index + 1}
                      onClick={() => handelSportFunc(item.seriesId)}
                    >
                      {" "}
                      <Link
                        to={"/" + lowerCase(item.name)}
                        onClick={() => setSeriesName(item.seriesName)}
                      >
                        {item.name}
                      </Link>
                    </li>
                  );
                })}
            </div>
          )}
        </ul>
      </div>
    </>
  );
};

export default MatchSidebar;

import React, { useState, useEffect } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { apiGet } from "../../utils/apiFetch";
import Exchange from "./Exchange";
import apiPath from "../../utils/apiPath";
const MyBet = () => {
  const [key, setKey] = useState("Exchange");
  const [exchangeData, setExchangeData] = useState([]);
  const [fancyData, setFancyData] = useState([]);
  const location = useLocation()
  const [sportsData, setSportsBookData] = useState([]);
  const [bookmakerData, setBookmaker] = useState([]);
  const [tieData, setTieData] = useState([]);
  const [tossData, setTossData] = useState([]);
  const [parlayData,setParlayData] = useState([])
  const [isLoader, setLoader] = useState(false);
  const getExchangeData = async () => {
    setLoader(true);
    const { status, data: response_users } = await apiGet(
      apiPath.getCurrentBetsExchanges,
      ""
    );
    if (status === 200) {
      if (response_users.success) {
        setLoader(false);
        setExchangeData(response_users.results);
      }
    }
  };
  const getFancyBetData = async () => {
    setLoader(true);
    const { status, data: response_users } = await apiGet(
      apiPath.getFancyBets,
      ""
    );
    if (status === 200) {
      if (response_users.success) {
        setLoader(false);
        setFancyData(response_users.results);
      }
    }
  };
  const getSportsBook = async () => {
    setLoader(true);
    const { status, data: response_users } = await apiGet(
      apiPath.getSportsBook,
      ""
    );
    if (status === 200) {
      if (response_users.success) {
        setSportsBookData(response_users.results);
        setLoader(false);
      }
    }
  };
  const getBookmaker = async () => {
    setLoader(true);
    const { status, data: response_users } = await apiGet(
      apiPath.getBookmakerBets,
      ""
    );
    if (status === 200) {
      if (response_users.success) {
        setLoader(false);
        setBookmaker(response_users.results);
      }
    }
  };
  const getTieData = async () => {
    setLoader(true);
    const { status, data: response_users } = await apiGet(
      apiPath.getMyTieBets,
      ""
    );
    if (status === 200) {
      if (response_users.success) {
        setLoader(false);
        setTieData(response_users.results);
      }
    }
  };
  const getTossData = async () => {
    setLoader(true);
    const { status, data: response_users } = await apiGet(
      apiPath.getMyTossBets,
      ""
    );
    if (status === 200) {
      if (response_users.success) {
        setLoader(false);
        setTossData(response_users.results);
      }
    }
  };
  const getParlayData = async () => {
    setLoader(true);
    const { status, data: response_users } = await apiGet(
      apiPath.currentParlay
    );
    if (status === 200) {
      if (response_users.success) {
        setLoader(false);
        setParlayData(response_users.results);
      }
    }
  };
  useEffect(() => {
    if (key === "Exchange") {
      getExchangeData();
    }
    else if (key === "FancyBet") {
      getFancyBetData();
    }
    else if (key === "Fancy1Bet") {
      getSportsBook();
    }
    else if (key === "BookMaker") {
      getBookmaker();
    }
    else if (key === "Tie") {
      getTieData();
    }
    else if (key === "Toss") {
      getTossData();
    } else if (key == 'parlay'){
      getParlayData()
    }
  }, [key]);
  return (
    <section className="py-4 main-inner-outer">
      <Container fluid>
        <div className="main_content_row">
          <Sidebar />
          <div className="my_account_main">
            <div className="inner-wrapper">
              <h2 className="common-heading">My Bets</h2>
              <div className="bet-history-menu">
                <ul className="list-unstyled">
                  <li>
                    <Link
                      to={
                        location?.pathname?.split("/")[2] == "mobile"
                          ? "/mybets/mobile"
                          : "/mybets"
                      }
                      className="active"
                    >
                      Current Bets
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        location?.pathname?.split("/")[2] == "mobile"
                          ? "/betshistory/mobile"
                          : "/betshistory"
                      }
                    >
                      Bets History
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        location?.pathname?.split("/")[2] == "mobile"
                          ? "/profit_loss/mobile"
                          : "/profit_loss"
                      }
                    >
                      Profit & Loss
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="common-tab mb-3">
                <Tabs
                  id="controlled-tab-example"
                  defaultActiveKey="Exchange"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                >
                  <Tab eventKey="Exchange" title="Exchange">
                    {key === "Exchange" && (
                      <Exchange title={key} data={exchangeData}  isLoader={isLoader}/>
                    )}
                  </Tab>
                  <Tab eventKey="FancyBet" title="FancyBet">
                    {key === "FancyBet" && (
                      <Exchange title={key} data={fancyData}  isLoader={isLoader}/>
                    )}
                  </Tab>
                  <Tab eventKey="Fancy1Bet" title="Sportsbook">
                    {key === "Fancy1Bet" && (
                      <Exchange title={key} data={sportsData}  isLoader={isLoader}/>
                    )}
                  </Tab>
                  <Tab eventKey="BookMaker" title="BookMaker">
                    {key === "BookMaker" && (
                      <Exchange title={key} data={bookmakerData}  isLoader={isLoader}/>
                    )}
                  </Tab>
                  <Tab eventKey="Tie" title="Tie">
                    {key === "Tie" && (
                      <Exchange title={key} data={tieData}  isLoader={isLoader}/>
                    )}
                  </Tab>
                  <Tab eventKey="Toss" title="Toss">
                    {key === "Toss" && (
                      <Exchange title={key} data={tossData}  isLoader={isLoader}/>
                    )}
                  </Tab>
                  <Tab eventKey="parlay" title="Parlay">
                    {key === "parlay" && (
                      <Exchange title={key} data={parlayData}  isLoader={isLoader}/>
                    )}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default MyBet;

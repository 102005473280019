import React, { useState } from "react";
import { Row, Col, Form, Table, Button } from "react-bootstrap";
import moment from "moment";
import helpers from "../../utils/helpers";
import obj, { headerData } from "../../utils/constants";
import { toast } from "wc-toast";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import ReactPaginate from "react-paginate";
import Loader from "../../assets/gif/loader.gif";
import { startCase } from "lodash";
const ExchangeHistory = ({
  title,
  filter,
  setFilter,
  data,
  getData,
  viewpage,
  handlePageClick,
  pageCount,
  isLoader,
}) => {
  const [getProperty, setProperty] = useState("none");
  const showDetail = (event, id) => {
    const detailDiv = document.getElementById(id);

    if (detailDiv.style.display === "none") {
      detailDiv.style.setProperty("display", "contents");
      event.target.className = "fas fa-minus-square pe-2";
    } else {
      detailDiv.style.setProperty("display", "none");
      event.target.className = "fas fa-plus-square pe-2";
    }
  };
  const redirectCasino = async (userId, platform, platformTxId, type) => {
    const { status, data: response_users } = await apiGet(
      apiPath.casinoWalletHistory +
        "?userId=" +
        userId +
        "&platform=" +
        platform +
        "&platformTxId=" +
        platformTxId
    );
    //console.log(response_users, status);
    if (status === 200) {
      if (response_users.status) {
        if (response_users.data?.status === "0000") {
          if (type === 1) {
            javascript: window.open(
              response_users.data?.txnUrl,
              "_blank",
              "height=900,width=1200"
            );
          } else {
            javascript: window.open(
              response_users.data?.url,
              "_blank",
              "height=900,width=1200"
            );
          }
        } else {
          toast.error(response_users.data.desc);
        }
      } else {
        toast.error(response_users.data.desc);
      }
    } else {
      toast.error(response_users.data.desc);
    }
  };

  return (
    <div className="common-container">
      <Form className="bet_status mb-3">
        <Row>
          <Col xl={11} md={12}>
            <Row>
              <Col xl={11} md={12}>
                <Row className="align-items-center">
                  {/* {title == "Exchange" && (
                    <Col xxl={3} lg={3} md={8} className="mb-lg-0 mb-3">
                      <div className="bet-sec d-flex align-items-center">
                        <Form.Label className="mb-0">Bet Status:</Form.Label>
                        <Form.Select
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              status: e.target.value,
                            })
                          }
                          value={filter.status}
                          aria-label="Default select example"
                        >
                          <option value="">All</option>
                          <option value="unMatched">UnMatched</option>
                          <option value="Matched">Matched</option>
                        </Form.Select>
                      </div>
                    </Col>
                  )} */}
                  <Col xxl={3} lg={4} md={6} sm={6}>
                    <div className="d-flex align-items-center betting-time-zone">
                      <Form.Label className="mb-0 pe-3">Period</Form.Label>
                      <div className="d-flex align-items-center">
                        <Form.Control
                          type="date"
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              fromPeriod: e.target.value,
                              filterByDay: "",
                            })
                          }
                          max={new Date().toISOString().split("T")[0]}
                          value={filter.fromPeriod}
                        />
                        <Form.Control
                          className="small_form_control"
                          type="text"
                          placeholder="09:00"
                          disabled
                        />
                      </div>
                    </div>
                  </Col>

                  <Col xxl={3} lg={4} md={6} sm={6}>
                    <div className="d-flex align-items-center betting-time-zone">
                      <Form.Label className="mb-0 pe-3">to</Form.Label>

                      <div className="d-flex align-items-center">
                        <Form.Control
                          type="date"
                          onChange={(e) =>
                            setFilter({
                              ...filter,
                              toPeriod: e.target.value,
                              filterByDay: "",
                            })
                          }
                          min={
                            filter?.fromPeriod
                              ? new Date(filter?.fromPeriod)
                                  .toISOString()
                                  .split("T")[0]
                              : new Date()
                          }
                          max={new Date().toISOString().split("T")[0]}
                          value={filter.toPeriod}
                        />
                        <Form.Control
                          className="small_form_control"
                          type="text"
                          placeholder="08:59"
                          disabled
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="input-list-data">
              <ul className="input-list">
                <li>
                  <a
                    onClick={(e) =>
                      setFilter({
                        ...filter,
                        filterByDay: "today",
                      })
                    }
                    style={{
                      background: filter.filterByDay === "today" ? "green" : "",
                      cursor: "pointer",
                    }}
                  >
                    Just For Today
                  </a>
                </li>
                <li>
                  <a
                    onClick={(e) =>
                      setFilter({
                        ...filter,
                        filterByDay: "yesterday",
                      })
                    }
                    style={{
                      background:
                        filter.filterByDay === "yesterday" ? "green" : "",
                      cursor: "pointer",
                    }}
                  >
                    From Yesterday
                  </a>
                </li>
                <li>
                  <a
                    onClick={getData}
                    style={{ cursor: "pointer" }}
                    className="btn-send"
                  >
                    Get History
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      setFilter({
                        betType:
                          title === "Exchange"
                            ? "betfair"
                            : title === "FancyBet"
                            ? "fancy"
                            : title === "Fancy1Bet"
                            ? "sportBook"
                            : title === "BookMaker"
                            ? "bookmaker"
                            : "casino",
                        status: "",
                        toPeriod: "",
                        fromPeriod: "",
                        filterByDay: "",
                      });
                      getData("reset");
                    }}
                    className="btn-send"
                    style={{ cursor: "pointer" }}
                  >
                    Reset
                  </a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Form>
      {isLoader ? (
        <span className="d-flex justify-content-center align-item-center mt-2 pb-3">
          <img src={Loader} alt="No_Image" />
        </span>
      ) : (
        <>
          <section className="account-table my-bet-sec">
            <div className="profile-tab">
              <div className="my_account_table_wrap">
                <h5>Matched</h5>
                <div className="table-main-wrapper">
                  {title === "casino" ? (
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col">Bet ID</th>
                          <th scope="col"> PL ID</th>
                          <th scope="col">Market</th>
                          {/* <th scope="col">Selection</th> */}
                          {/* <th scope="col">Type</th> */}
                          <th scope="col">Bet Placed</th>
                          {/* <th scope="col">Odds Req.</th> */}
                          <th scope="col">Stake</th>
                          {/* <th scope="col">Avg. odds Matched</th> */}
                          <th scope="col">Profit / Loss</th>
                        </tr>
                      </thead>
                      {data && data?.length > 0 ? (
                        data?.map((item, index) => {
                          // const resultTotalStake = item?.bets_list.reduce((a, v) => {
                          //   a = parseInt(a) + parseInt(v.amount);
                          //   return a;
                          // }, 0);

                          return (
                            <>
                              <tr
                                id="summary0"
                                style={{ display: "table-row" }}
                                key={index + 1}
                              >
                                <td>
                                  {" "}
                                  <i
                                    id={"icon_" + item?.platformTxId}
                                    class="fas fa-plus-square"
                                    onClick={(e) =>
                                      showDetail(e, item?.platformTxId)
                                    }
                                  ></i>{" "}
                                  {item?.platformTxId}
                                </td>
                                <td>{item?.clientName}</td>
                                <td id="title" className="align-L">
                                  Casino
                                  <span className="angle_unicode">▸</span>
                                  <strong> {item?.casinoName}</strong>
                                  <span className="angle_unicode">▸</span>
                                  {item?.gameCode}
                                </td>
                                {/* <td>null</td> */}
                                {/* <td>back</td> */}
                                <td id="settledDate">
                                  {" "}
                                  {helpers.dateFormat(item.timeInserted)}
                                </td>
                                {/* <td>0.00</td> */}
                                <td>{item?.betAmount}</td>
                                {/* <td>0.00</td> */}
                                <td>
                                  {Math.sign(item?.playerPL) === -1 ? (
                                    <span className="text-danger">
                                      ({helpers.currencyFormat(item?.playerPL)})
                                    </span>
                                  ) : (
                                    <span className="text-success">
                                      (
                                      {helpers.currencyFormat(
                                        Math.abs(item?.playerPL)
                                      )}
                                      )
                                    </span>
                                  )}
                                </td>
                              </tr>

                              <tr
                                className="expand"
                                id={item?.platformTxId}
                                style={{ display: getProperty }}
                              >
                                <td colSpan="7" className="expand_wrap">
                                  <table className="table-commission">
                                    <tbody>
                                      <tr>
                                        <th>Bet Taken</th>
                                        <th>Odds Req.</th>
                                        <th>Stake</th>
                                        <th>Liability</th>
                                        <th>Odds Matched</th>
                                        <th>Action</th>
                                      </tr>
                                      <tr
                                        id="txRow0"
                                        style={{ display: "table-row" }}
                                        className="even"
                                      >
                                        <td id="betID">
                                          {" "}
                                          {helpers.dateFormat(
                                            item.timeInserted
                                          )}
                                        </td>
                                        <td>0.00</td>
                                        <td>{item?.betAmount}</td>
                                        <td>
                                          {item?.loseAmount
                                            ? item?.loseAmount
                                            : "-"}
                                        </td>
                                        <td>0.00</td>
                                        <td>
                                          <Button
                                            className="theme_dark_btn"
                                            onClick={() =>
                                              redirectCasino(
                                                item?.clientName,
                                                item?.platform,
                                                item?.platformTxId,
                                                2
                                              )
                                            }
                                          >
                                            Get Result
                                          </Button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={12}>
                            <span>You have no bets in this time period.</span>
                          </td>
                        </tr>
                      )}
                    </Table>
                  ) : title == "parlay" ? (
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col" className="text-start">
                            Market
                          </th>
                          <th scope="col" className="text-end">
                            {" "}
                            Settled Date
                          </th>
                          <th scope="col" className="text-end">
                            {" "}
                            Bet ID
                          </th>
                          <th scope="col" className="text-end">
                            Stake
                          </th>
                          <th scope="col" className="text-end">
                            Profit / Loss
                          </th>
                        </tr>
                      </thead>
                      {data?.length > 0 ? (
                        data?.map((item, index) => {
                          return (
                            <>
                              <tr
                                id="summary0"
                                style={{ display: "table-row" }}
                                key={index + 1}
                              >
                                <td className="text-start">
                                  {item?.isDeclared ? (
                                    <>
                                      {item?.result == "loss" ? (
                                        <strong
                                          // className="d-block"
                                          style={{ color: "red" }}
                                        >
                                          {startCase(item?.result)}
                                        </strong>
                                      ) : (
                                        <strong
                                          // className="d-block"
                                          style={{ color: "green" }}
                                        >
                                          {startCase(item?.result)}
                                        </strong>
                                      )}
                                    </>
                                  ) : (
                                    "..."
                                  )}
                                  <span className="angle_unicode">▸</span>
                                  <strong>
                                    {item?.bets?.length == 0
                                      ? ""
                                      : item?.bets?.length == 1
                                      ? "SINGLE"
                                      : item?.bets?.length == 2
                                      ? "DOUBLE"
                                      : item?.bets?.length == 3
                                      ? "TREBLE"
                                      : "ACCMULATOR"}
                                  </strong>
                                </td>
                                <td id="settledDate" className="text-end">
                                  {" "}
                                  {helpers.dateFormat(item.timeInserted)}
                                </td>

                                <td id="settledDate" className="text-end">
                                  {" "}
                                  {item?.matchBetId}
                                </td>
                                <td id="settledDate" className="text-end">
                                  {" "}
                                  {item?.amount}
                                </td>
                                <td className="text-end">
                                  <a id="pl0" className="expand-open" href="#">
                                    {item?.isDeclared ? (
                                      <>
                                        {item?.result == "loss" ? (
                                          <strong style={{ color: "red" }}>
                                            {Number(item?.loseAmount)?.toFixed(
                                              2
                                            )}
                                          </strong>
                                        ) : (
                                          <strong style={{ color: "green" }}>
                                            {Number(
                                              item?.profitAmount
                                            )?.toFixed(2)}
                                          </strong>
                                        )}
                                      </>
                                    ) : (
                                      "..."
                                    )}
                                  </a>
                                  <i
                                    id={"icon_" + item?._id}
                                    className="fas fa-plus-square"
                                    onClick={(e) => showDetail(e, item?._id)}
                                  ></i>
                                </td>
                              </tr>
                              {item?.bets?.length > 0 && (
                                <tr
                                  className="expand"
                                  id={item?._id}
                                  style={{ display: getProperty }}
                                  key={item?._id}
                                >
                                  <td colSpan="5" className="expand_wrap">
                                    <table className="table-commission">
                                      <tbody>
                                        <tr>
                                          {/* <th>User Name</th> */}
                                          <th className="text-start">
                                            Fancy Name
                                          </th>
                                          <th className="text-end">
                                            Selection
                                          </th>
                                          <th className="text-end">Odds</th>
                                          {/* <th>Stake</th> */}
                                          {/* <th>Type</th> */}
                                          <th className="text-end">
                                            Profit/Loss
                                          </th>
                                        </tr>
                                        {item?.bets?.map((betData, i) => {
                                          return (
                                            <tr
                                              id="txRow0"
                                              style={{
                                                display: "table-row",
                                              }}
                                              className="even"
                                              key={i + 1}
                                            >
                                              {/* <td id="betID">
                                              {betData?.clientName}
                                            </td> */}
                                              <td id="betID">
                                                {betData?.fancyName}
                                              </td>
                                              <td
                                                id="matchSelection"
                                                className="text-end"
                                              >
                                                {betData?.runnerName}
                                              </td>
                                              <td
                                                id="txOddsMatched"
                                                className="text-end"
                                              >
                                                {betData?.bhav}
                                              </td>
                                              {/* <td id="txStake">
                                              {" "}
                                              {betData?.amount}
                                            </td>
                                            <td>
                                              <span
                                                id="matchType"
                                                className={
                                                  betData?.betType ===
                                                  "lay"
                                                    ? "lay"
                                                    : "back"
                                                }
                                              >
                                                {betData?.betType}
                                              </span>
                                            </td> */}
                                              <td
                                                id="placed"
                                                className="text-end"
                                              >
                                                {" "}
                                                {/* {Number(
                                                betData?.result == "win"
                                                  ? betData?.profitAmount
                                                  : betData?.loseAmount
                                              )?.toFixed(2)} */}
                                                {betData?.isDeclared ? (
                                                  <>
                                                    {betData?.result ==
                                                    "loss" ? (
                                                      <strong
                                                        style={{
                                                          color: "red",
                                                        }}
                                                      >
                                                        {startCase(
                                                          betData?.result
                                                        )}
                                                      </strong>
                                                    ) : (
                                                      <strong
                                                        style={{
                                                          color: "green",
                                                        }}
                                                      >
                                                        {startCase(
                                                          betData?.result
                                                        )}
                                                      </strong>
                                                    )}
                                                  </>
                                                ) : (
                                                  "..."
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={10}>
                            <span>You have no bets in this time period.</span>
                          </td>
                        </tr>
                      )}
                    </Table>
                  ) : (
                    <Table className="table_caption_style">
                      <thead>
                        <tr>
                          {headerData.map((res) => {
                            return (
                              <th
                                scope="col"
                                className={res.class}
                                width={res.widht}
                              >
                                {res.title}
                              </th>
                            );
                          })}
                        </tr>
                      </thead>

                      <tbody>
                        {data && data?.length > 0 ? (
                          data?.map((item) => {
                            // console.log(item, "item");
                            return (
                              <tr>
                                <td className="text-start" width="30%">
                                  {item?.matchName}
                                  {filter.betType === "sportsbook" ||
                                  filter.betType === "fancy"
                                    ? " > " + item?.fancyName
                                    : ""}
                                </td>
                                <td className="text-end">
                                  {obj?.betCheckObj[item?.eventType]}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {filter.betType === "fancy"
                                    ? item?.betRun + "/" + item?.bhav
                                    : filter.betType === "sportsbook"
                                    ? item?.runnerName
                                    : item?.teamName}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  <span
                                    className={
                                      item?.betType == "lay" ||
                                      item?.type == "No"
                                        ? "lay-bg"
                                        : item?.betType == "back" ||
                                          item?.type == "Yes"
                                        ? "back-bg"
                                        : ""
                                    }
                                  >
                                    {item?.betType || item?.type}
                                  </span>
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {item?.matchBetId || item?.sessionBetId}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {item?.amount || "N/A"}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {item?.bhav || "N/A"}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {item?.bhav || "N/A"}
                                </td>
                                <td className="text-end">
                                  {" "}
                                  {item?.bhav || "N/A"}{" "}
                                </td>

                                {filter.betType == "betfair" ||
                                filter.betType == "Bookmaker" ||
                                filter.betType == "toss" ||
                                filter.betType == "tie" ? (
                                  <td className="text-end">
                                    {(item?.teamSelectionWin ==
                                      item?.selectionId &&
                                      item?.betType == "back") ||
                                    (item?.teamSelectionWin !=
                                      item?.selectionId &&
                                      item?.betType == "lay") ? (
                                      <span className="text-success">
                                        {item?.profitAmount}
                                      </span>
                                    ) : (
                                      <span className="text-danger">
                                        -({item?.loseAmount})
                                      </span>
                                    )}
                                  </td>
                                ) : filter.betType === "sportsbook" ? (
                                  <td>
                                    {item?.teamSelectionWin &&
                                    item?.teamSelectionWin
                                      .split(",")
                                      .includes(item?.fancySelectionId) ? (
                                      <span className="text-success">
                                        {item?.profitAmount}
                                      </span>
                                    ) : (
                                      <span className="text-danger">
                                        -({item?.loseAmount})
                                      </span>
                                    )}
                                  </td>
                                ) : (
                                  <td className="text-end">
                                    {item?.type == "No" ? (
                                      item?.decisionRun < item?.betRun ? (
                                        <span className="text-success">
                                          {item?.profitAmount}
                                        </span>
                                      ) : (
                                        <span className="text-danger">
                                          -({item?.loseAmount})
                                        </span>
                                      )
                                    ) : item?.decisionRun >= item?.betRun ? (
                                      <span className="text-success">
                                        {item?.profitAmount}
                                      </span>
                                    ) : (
                                      <span className="text-danger">
                                        -({item?.loseAmount})
                                      </span>
                                    )}
                                  </td>
                                )}

                                <td>
                                  {" "}
                                  {moment(item?.timeInserted).format(
                                    "YYYY-MM-DD, h:mm:ss"
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="12" className="text-start">
                              <span>You have no bets in this time period.</span>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  )}
                  <div className="bottom-pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=" >"
                      forcePage={viewpage}
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={10}
                      pageCount={pageCount}
                      previousLabel="< "
                      renderOnZeroPageCount={null}
                      activeClassName="p-1"
                      activeLinkClassName="pagintion-li"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </div>
  );
};
export default ExchangeHistory;

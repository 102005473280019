import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { apiPost, apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { toast } from "wc-toast";
const OneClickBet = (props) => {
  const [okShown, setOkShown] = useState(true);

  const [editShown, setEditShown] = useState(false);
  const [stakedata, setStakeData] = useState([]);
  useEffect(() => {
    setStakeData(props?.stakedata);
  }, [props?.stakedata]);

  const setStakeShow = async (value) => {
    if (value) {
      try {
        const { status, data: response_users } = await apiPost(
          apiPath.userProfileUpdate,
          { isOneClickBetStake: true, defaultOneClickBetStake: value }
        );
        if (status === 200) {
          if (response_users.success) {
            props.setDefaultOneClickStake(
              response_users?.results?.defaultOneClickBetStake
            );

            // toast.success(response_users.message);
          } else {
            // toast.error(response_users.message);
          }
        }
      } catch (err) {
        // toast.error(err.response.data.message);
      }
    } else {
      toast.error("please select stake");
    }
  };
  const handleEditClick = (event) => {
    setEditShown(true);
    setOkShown(false);
  };
  const onlyNumbers = (array) => {
    let filtered = array.filter((elm) => elm);
    return filtered;
  };

  const handleStake = (e, i) => {
    let values = [...stakedata];
    values[i] = parseInt(e.target.value);
    setStakeData(values);
  };

  const handleOkClick = async (event) => {
    try {
      if (onlyNumbers(stakedata).length === 4) {
        const { status, data: response_users } = await apiPost(
          apiPath.userEditOneClickStake,
          { editOneClickBetStake: stakedata }
        );
        if (status === 200) {
          if (response_users.success) {
            stakedata.sort((a, b) => a - b);
            setOkShown(true);
            setEditShown(false);
          } else {
          }
        }
      } else {
        toast.error("[] is illegal");
      }
    } catch (err) {}
  };

  return (
    <div className="stake-set oneclickbet-footer">
      <div className="edit_wrap">
        {okShown && (
          <>
            <label htmlFor="defult_stake" className="me-2">
              <strong>One Click Bet Stake</strong>
            </label>
            <div className="d-flex justify-content-between" id="stakeSet">
              <div className="wrapper-p1 d-flex justify-content-center">
                {stakedata &&
                  stakedata.map((item, index) => {
                    return (
                      <div key={index + 1}>
                        <a
                          id={"coin_" + index + 1}
                          className={
                            props.defaultOneClickStake === item
                              ? "btn select"
                              : "btn"
                          }
                          onClick={(event) => setStakeShow(item)}
                        >
                          {item}
                        </a>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-edit border-0 d-block">
              <a
                href="#"
                id="edit"
                className="btn-edit"
                onClick={handleEditClick}
              >
                Edit
                <i className="ms-1 fas fa-pencil"></i>
              </a>
            </div>
          </>
        )}

        {editShown && (
          <>
            <label htmlFor="defult_stake" className="me-2">
              <strong>Edit One Click Bet Stake</strong>
            </label>
            <div className="d-flex justify-content-center" id="stakeSet">
              <div className="wrapper-p1 d-flex input-wrapper-footer">
                {stakedata &&
                  stakedata.map((item, index) => {
                    return (
                      <div key={index + 1}>
                        <Form.Control
                          type="text"
                          name="stake[]"
                          value={item}
                          className="stake_edit-input"
                          onChange={(e) => handleStake(e, index)}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-edit border-0 d-block">
              <a
                href="#"
                id="edit"
                className="btn-edit ok-edit"
                onClick={handleOkClick}
              >
                Save
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OneClickBet;

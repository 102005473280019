import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { isEmpty, pick } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "wc-toast";
import { apiGet, apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";

import secureLocalStorage from "react-secure-storage";
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [user, setUser] = useState(() =>
    localStorage.getItem("token")
      ? jwt_decode(localStorage.getItem("token"))
      : null
  );
  const [homeLoader, setLoader] = useState(true);
  const [inplaySocket, setInplaySocket] = useState({});
  const [preminumFancy, setPremiumFancy] = useState("");
  let [loading, setLoading] = useState(false);
  let [user_coins, setUserCoins] = useState(0);
  const [inPlayScore, setInPlayScore] = useState([]);
  let [loginUserDetailData, setloginUserDetail] = useState(0);
  let [stakeData, setStakeData] = useState([]);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const location = useLocation();
  // casino-modal
  const [gameType, setGameType] = useState(false);
  const [platForm, setPlatForm] = useState(false);
  const [casinoType, setCasinoType] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [soccerSocket, setSoccerSocket] = useState({});
  const [tennisSocket, setTennisSocket] = useState({});
  const [sokcetId, setSocketId] = useState({});
  const casinoShow = (platForm, gameType, type) => {
    setGameType(gameType);
    setPlatForm(platForm);
    setCasinoType(type);
    setModalShow(!modalShow);
    setCasinoAmount(0);
  };
  const [getCasinoAmount, setCasinoAmount] = useState(0);

  let loginUser = async (body) => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    body.website = hostname || "SABAEXCH";
    const { status, data } = await apiPost(
      apiPath.loginUser,
      pick(body, ["username", "password", "uniqueId", "website"])
    );

    if (status === 200) {
      if (data.success) {
        const token = data?.results?.token || null;
        const refresh_token = data?.results?.refresh_token || null;
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);
        setUser(jwt_decode(token));
        toast.success(data.message);
        setUserCoins({
          totalCoins: data?.results?.totalCoins,
          casinoCoins: data?.results?.casinoCoins,
          exposure: data?.results?.exposure,
        });
      } else {
        toast.error(data.message);
      }
    } else {
      toast.error("API Error");
    }
  };

  let logoutUser = () => {
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    navigate("/");
  };
  const getCoins = async () => {
    if (!isEmpty(user)) {
      const { status, data } = await apiGet(apiPath.refreshAmount);
      if (status === 200) {
        if (data.success) {
          setUserCoins({
            exposure: data?.results?.exposure,
            totalCoins: data?.results?.totalCoins,
          });
        }
      }
    }
  };
  const loginUserDetail = async () => {
    if (!isEmpty(user)) {
      const { status, data } = await apiGet(apiPath.userProfile);
      if (status === 200) {
        if (data.success) {
          setloginUserDetail(data?.results);
          const d = data?.results?.stake.map((i, index) => {
            return { id: index, value: i };
          });
          d.sort((a, b) => a.value - b.value);
          setStakeData(d);
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error("API Error");
      }
    }
  };
  useEffect(() => {
    if (location?.pathname?.split("/")[1] !== "match-details") {
      secureLocalStorage.removeItem("betFair");
      secureLocalStorage.removeItem("bookmaker");
      secureLocalStorage.removeItem("fancy");
      secureLocalStorage.removeItem("tie");
      secureLocalStorage.removeItem("premiumFancy");
    }
    if (location?.pathname?.split("/")[1] !== "markets" && !isEmpty(sokcetId)) {
      sokcetId.disconnect();
      setSocketId({});
    }
    if (
      location?.pathname?.split("/")[1] !== "tennis" &&
      !isEmpty(tennisSocket)
    ) {
      tennisSocket.disconnect();
      setTennisSocket({});
    }
    if (
      location?.pathname?.split("/")[1] !== "soccer" &&
      !isEmpty(soccerSocket)
    ) {
      soccerSocket.disconnect();
      setSoccerSocket({});
    }
    if (
      location?.pathname?.split("/")[1] !== "inplay" &&
      !isEmpty(inplaySocket)
    ) {
      inplaySocket.disconnect();
      setInplaySocket({});
    }
  }, [location]);
  // useEffect(() => {
  //   const newSocket = io(
  //     `${process.env.REACT_APP_API_BASE_URL1}?token=${user?.user?._id ? user?.user?._id : 112233}&userType=front`,
  //     {
  //       transports: ["websocket"],
  //     }
  //   );
  //   const coinListener = (message) => {
  //     setUserCoins(message.results);
  //   };
  //   const InPlayScore = (message) => {
  //     setInPlayScore(JSON.parse(message));
  //   };
  //   const forceLogout = (message) => {
  //     const uniqueId = localStorage.getItem("uniqueId");
  //     if (uniqueId !== message.results.uniqueId) {
  //       logoutUser();
  //     }
  //   };
  //   newSocket.emit("getCoins", { user_id: user?.user?._id });
  //   newSocket.on("listenGetCoin", coinListener);
  //   newSocket.on("scoreAll", InPlayScore);
  //   newSocket.on("listenForceLogout", forceLogout);
  //   newSocket.on("refreshPage", refreshPage);
  //   return () => newSocket.close();
  // }, []);
  const casinoRangeAmount = async (e) => {
    if (e.target.value) {
      if (
        user_coins &&
        user_coins?.totalCoins &&
        parseInt(user_coins?.totalCoins) >= parseInt(e.target.value)
      ) {
        const numStr = String(e.target.value);
        if (numStr.includes(".")) {
          if (numStr.split(".")[1].length < 3) {
            setCasinoAmount(e.target.value);
          }
        } else {
          setCasinoAmount(e.target.value);
        }
      } else {
        setCasinoAmount(user_coins?.totalCoins);
      }
    } else {
      setCasinoAmount(0);
    }
  };
  const launchCasino = async (request) => {
    setLoader(true);
    const { status, data: response_users1 } = await apiPost(
      apiPath.casinoAmountAdd,
      { amount: 0 }
    );
    if (status === 200) {
      if (response_users1.success) {
        const { status, data: response_users } = await apiPost(
          request?.platForm && request?.gameType
            ? apiPath.doLoginAndLaunchGame
            : apiPath.casinoWalletLogin,
          request?.platForm && request?.gameType
            ? {
                userId: response_users1?.results?.aeCasinoUserId,
                platForm: request?.platForm,
                gameType: request?.gameType,
                gameCode: request?.casinoType,
              }
            : {
                userId: response_users1?.results?.aeCasinoUserId,
                platForm: request?.platForm,
              }
        );
        if (status === 200) {
          if (response_users.status) {
            setLoader(false);
            if (response_users.data.status === "0000") {
              javascript: window.open(
                response_users.data.url,
                "_blank",
                "height=900,width=1200"
              );
            } else {
              setLoader(false);
            }
          } else {
            setLoader(false);
          }
        } else {
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };
  const doLoginAndLaunchJetxCasino = async (request) => {
    if (request?.GameCategory && request?.game_name && request?.gameId) {
      const { status, data: response_users1 } = await apiPost(
        apiPath.doLoginAndLaunchJetxCasino,
        {
          GameCategory: request?.GameCategory,
          game_name: request?.game_name || "",
          gameId: request?.gameId || "",
        }
      );
      if (status === 200) {
        if (response_users1.status) {
          window.location.href = response_users1.data;
        } else {
          toast.error(
            response_users1?.message == "success"
              ? "Casino is in maintenance mode if you have any query please contact upline"
              : response_users1?.message ||
                  "Casino is in maintenance mode if you have any query please contact upline"
          );
        }
      }
    }
  };
  const refreshPage = (e) => {
    if (!isEmpty(e)) {
      window.location.reload();
    }
  };
  let contextData = {
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    getCoins: getCoins,
    user_coins,
    setUserCoins: setUserCoins,
    setInPlayScore: setInPlayScore,
    inPlayScore,
    setPremiumFancy: setPremiumFancy,
    preminumFancy,
    loginUserDetail: loginUserDetail,
    loginUserDetailData,
    stakeData,
    handleShow: handleShow,
    handleClose: handleClose,
    setShow: setShow,
    show: show,
    casinoRangeAmount: casinoRangeAmount,
    casinoShow: casinoShow,
    modalShow: modalShow,
    getCasinoAmount: getCasinoAmount,
    gameType: gameType,
    platForm: platForm,
    casinoType: casinoType,
    setCasinoType: setCasinoType,
    launchCasino,
    homeLoader,
    inplaySocket,
    setInplaySocket,
    sokcetId,
    setSocketId,
    soccerSocket,
    setSoccerSocket,
    tennisSocket,
    setTennisSocket,
    doLoginAndLaunchJetxCasino
  };
  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Inplay from "./pages/Inplay";
import Home from "./pages/Home";
import Cricket from "./pages/Cricket";
import Soccer from "./pages/Soccer";
import Tennis from "./pages/Tennis";
import Casino from "./pages/Casino";
import MatchOdd from "./pages/MatchOdd";
import Myprofile from "./pages/Myprofile";
import Accountstatement from "./pages/Accountstatement";
import InternationalCasionBets from "./pages/InternationalCasionBets";
import MyBet from "./pages/CurrentBets/MyBet";
import Balance from "./pages/Balance";
import BetHistory from "./pages/BetHistory/BetHistory";
import ProfitLoss from "./pages/ProfitLoss/ProfitLoss";
import Activitylog from "./pages/Activitylog";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./utils/PrivateRoute";
import { BetSlipProvider } from "./context/BetSlipContext";
import MultiMarkets from "./pages/MultiMarkets";
import Signup from "./pages/Signup";
import Login from "./components/Login";
import AuthContext from "./context/AuthContext";
import CasinoModal from "./components/CasinoModal";
import FancyBetDialog from "./pages/MatchOdds/FancyBetDialog";
import LiveCasino from "./pages/LiveCasino";
import TransationHistory from "./pages/TransationHistory";
import { isEmpty } from "lodash";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
function App() {
  let { handleClose, show, user } = useContext(AuthContext);
  const location = useLocation();
  const parmas = useParams();

  let path = window?.location?.href?.split("/");
  const [loading, setLoading] = useState(false);
 
  return (
    <div className="App">
      <wc-toast position="top-right"></wc-toast>
      <Routes>
        <Route exact path="/signup" element={<Signup />}></Route>
        <Route element={<PrivateRoute />}>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/live-casino" element={<LiveCasino />}></Route>
          <Route exact path="/multi-markets" element={<MultiMarkets />}></Route>
          <Route exact path="/casino-callback" element={<Casino />}></Route>
          <Route exact path="/inplay" element={<Inplay />}></Route>
          <Route exact path="/cricket" element={<Cricket />}></Route>
          <Route exact path="/soccer" element={<Soccer />}></Route>
          <Route exact path="/tennis" element={<Tennis />}></Route>
          <Route exact path="/markets" element={<MatchOdd />}></Route>
          <Route
            exact
            path="/markets/:sportType/:eventId/:marketId"
            element={<MatchOdd />}
          ></Route>
          {!isEmpty(user) || (path && path[4] == "mobile") ? (
            <>
              <Route exact path="/my-profile" element={<Myprofile />}></Route>
              <Route
                exact
                path="/my-profile/mobile"
                element={<Myprofile />}
              ></Route>
              <Route
                exact
                path="/my-profile/mobile/:token"
                element={<Myprofile />}
              ></Route>
              <Route exact path="/balance" element={<Balance />}></Route>
              <Route exact path="/balance/mobile" element={<Balance />}></Route>
              <Route
                exact
                path="/balance/mobile/:token"
                element={<Balance />}
              ></Route>
              <Route exact path="/betshistory" element={<BetHistory />}></Route>
              <Route
                exact
                path="/betshistory/mobile"
                element={<BetHistory />}
              ></Route>
              <Route
                exact
                path="/betshistory/mobile/:token"
                element={<BetHistory />}
              ></Route>
              <Route exact path="/profit_loss" element={<ProfitLoss />}></Route>
              <Route
                exact
                path="/profit_loss/mobile/:token"
                element={<ProfitLoss />}
              ></Route>
              <Route
                exact
                path={"/transactionHistory"}
                element={<TransationHistory />}
              ></Route>
              <Route
                exact
                path={"/transactionHistory/mobile"}
                element={<TransationHistory />}
              ></Route>
              <Route
                exact
                path="/profit_loss/mobile"
                element={<ProfitLoss />}
              ></Route>
              <Route
                exact
                path="/activity-logs"
                element={<Activitylog />}
              ></Route>
              <Route
                exact
                path="/activity-logs/mobile/:token"
                element={<Activitylog />}
              ></Route>
              <Route
                exact
                path="/activity-logs/mobile"
                element={<Activitylog />}
              ></Route>
              <Route
                exact
                path="/account-statement"
                element={<Accountstatement />}
              ></Route>
              <Route
                exact
                path="/account-statement/mobile"
                element={<Accountstatement />}
              ></Route>
              <Route
                exact
                path="/account-statement/mobile/:token"
                element={<Accountstatement />}
              ></Route>
              <Route
                exact
                path="/InternationalCasionBets"
                element={<InternationalCasionBets />}
              ></Route>
              <Route
                exact
                path="/InternationalCasionBets/mobile"
                element={<InternationalCasionBets />}
              ></Route>
              <Route
                exact
                path="/InternationalCasionBets/mobile/:token"
                element={<InternationalCasionBets />}
              ></Route>


              <Route exact path="/mybets" element={<MyBet />}></Route>
              <Route exact path="/mybets/mobile" element={<MyBet />}></Route>
              <Route
                exact
                path="/mybets/mobile/:token"
                element={<MyBet />}
              ></Route>
            </>
          ) : (
            ""
          )}
          <Route
            exact
            path="/DownlinePnl-Fancy/:name/:eventid/:marketId/:selectionId"
            element={<FancyBetDialog />}
          ></Route>
          <Route path="*" element={<Navigate replace to="/" />} />
        </Route>
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
      {/* Login Modal Popup Start*/}
      <Login show={show} handleClose={handleClose} />
      {/* Login Modal Popup End*/}
      {/* Casino Modal Start*/}
      <CasinoModal />
      {/* Casino Modal ENd*/}
    </div>
  );
}

export default App;

import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { apiPost, apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import { toast } from "wc-toast";
const OneClickBetPopup = (props) => {
  const handleOkClick = () => {
    localStorage.setItem("pop_status", 1);
    props.handleClose(false);
  };

  useEffect(() => {
    let pop_status = localStorage.getItem("pop_status");
    if (pop_status == 1) {
      props.handleClose(false);
      localStorage.setItem("pop_status", 1);
    }
  }, []);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        centered
        className="onclick-bet-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>One Click Bet ON</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="stake-set m-0">
            Stake selected will be placed immediately once you click on the
            market odds.
            <span className="d-block">
              Attention: Back/Lay at your own risk
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleOkClick}>
            {" "}
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OneClickBetPopup;

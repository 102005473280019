import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useContext } from 'react'
import { isEmpty } from "lodash";
import Header from '../components/Header'
import Footer from '../components/Footer'
const PrivateRoute = ({ children, ...rest }) => {

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return (
        <>
            <Header />
            <Outlet />
            {/* <Footer /> */}
        </>
    )
}

export default PrivateRoute;